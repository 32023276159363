import "../../App.css";
import React, { Suspense } from "react";
import Sidebar from "../../Module/sidebar";
import { Route, Routes } from "react-router-dom";
import Loading from "../../Component/Loading";
import {
  ManagerEmpSidebarData,
  ManagerSidebarData,
} from "../../Module/ManagerSidebarData";
import { useSelector } from "react-redux";
import CommonRoutes from "../Common/commonRoute";

const RegistrationDetails = React.lazy(() => import("./registrationDetails"));
const MovementsOfMeeting = React.lazy(() =>
  import("../CategoryPanel/MovementsOfMeeting/movementsOfMeeting")
);
const EditMovementsOfMeeting = React.lazy(() =>
  import("../CategoryPanel/MovementsOfMeeting/editMovementsOfMeeting")
);
const MultiDeviceTokenClear = React.lazy(() =>
  import("../CounsellorPanel/MultiDeviceTokenClear")
);

function ManagerPanelDashboard() {
  const { loginType } = useSelector((state) => ({
    designation: state.Auth?.userInfo?.designation,
    loginType: state.Auth?.loginType,
  }));
  console.log("loginType", loginType);
  return (
    <div className="AppBox">
      <Sidebar
        data={loginType === 2 ? ManagerEmpSidebarData : ManagerSidebarData}
      />
      <div className="dashboard">
        {
          <CommonRoutes
            defaultPage={<RegistrationDetails />}
            loginType={loginType}
          >
            <Route path="/" element={<RegistrationDetails />} />
            <Route
              path="registrationDetails"
              element={<RegistrationDetails />}
            />
            <Route
              path="MultiDeviceTokenClear"
              element={<MultiDeviceTokenClear />}
            />
            <Route path="movementsOfMeeting" element={<MovementsOfMeeting />} />
            <Route
              path="editMovementsOfMeeting"
              element={<EditMovementsOfMeeting />}
            />
          </CommonRoutes>
        }
      </div>
    </div>
  );
}

export default ManagerPanelDashboard;
