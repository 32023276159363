import "../../App.css";
import React, { lazy, Suspense } from "react";
import Sidebar from "../../Module/sidebar";
import { Route, Routes } from "react-router-dom";
import Loading from "../../Component/Loading";
import {
  AdminPanelEmpSidebarData,
  AdminPanelSidebarData,
} from "../../Module/adminPanelSidebarData";
import QuestionDoubt from "./questionDoubt";
import CreateUpcomingBatches from "./Upcoming_batches/CreateUpcomingBatches";
import EditUpcomingBatches from "./Upcoming_batches/EditUpcomingBatches";
import CreateBasePrice from "./createBasePrice";
import BatchaAnalisis from "../CXOPanel/BatchaAnalisis";
import EditDeleteBasePrice from "./editDeleteBasePrice";
import { useSelector } from "react-redux";
import CommonRoutes from "../Common/commonRoute";

const AddRecordedLectureInCourse = React.lazy(() =>
  import("./addRecordedLectureInCourse")
);
const CreateParentCourse = React.lazy(() => import("./createParentCourse"));
const EditDeleteParentCourse = React.lazy(() =>
  import("./editDeleteParentCourse")
);
const CreateExam = React.lazy(() => import("./createExam"));
const CreateExtendGlobal = React.lazy(() => import("./createExtendGlobal"));
const CreateUpgradeGlobal = React.lazy(() => import("./createUpgradeGlobal"));
const EditDeleteUpgradeGlobal = React.lazy(() =>
  import("./editDeleteUpgradeGlobal")
);
const EditDeleteExtendGlobal = React.lazy(() =>
  import("./editDeleteExtendGlobal")
);
const EditDeleteExam = React.lazy(() => import("./editDeleteExam"));
const CreateSubExam = React.lazy(() => import("./createSubExam"));
const EditDeleteSubExam = React.lazy(() => import("./editDeleteSubExam"));
const CreateLanguage = React.lazy(() => import("./createLanguage"));
const EditDeleteLanguage = React.lazy(() => import("./editDeleteLanguage"));

const CreateAssignExam = React.lazy(() => import("./createAssignExam"));
const CreateAssignBooks = React.lazy(() =>
  import("../Publication/createAssignBooks")
);
const EditDeleteAssignBooks = React.lazy(() =>
  import("../Publication/editDeleteAssignBooks")
);
const CreatePackage = React.lazy(() =>
  import("../CategoryPanel/createPackage")
);
const EditDeletePackage = React.lazy(() =>
  import("../CategoryPanel/editDeletePackage")
);
const CreateAssignExamStream = React.lazy(() =>
  import("./createAssignExamStream")
);

const BatchAnalysis = React.lazy(() => import("./batchAnalysis"));

const CreateSubCategory = React.lazy(() => import("./createSubCategory"));
const EditDeleteSubCategory = React.lazy(() =>
  import("./editDeleteSubCategory")
);
const CreateSubject = React.lazy(() => import("./createSubject"));
const EditDeleteSubject = React.lazy(() => import("./editDeleteSubject"));

const CreateMainCategory = React.lazy(() => import("./createMainCategory"));
const EditDeleteMainCategory = React.lazy(() =>
  import("./editDeleteMainCategory")
);

const CreateSection = React.lazy(() => import("./createSection"));
const BatchClone = React.lazy(() => import("./batchClone"));
const EditDeleteSection = React.lazy(() => import("./editDeleteSection"));

const CreatePackageExtend = React.lazy(() => import("./createPackageExtend"));
const EditDeletePackageExtend = React.lazy(() =>
  import("./editDeletePackageExtend")
);

const CreateTeacher = React.lazy(() => import("./createTeacher"));
const EditDeleteTeacher = React.lazy(() => import("./editDeleteTeacher"));

const CreateAssignUnits = React.lazy(() =>
  import("../CategoryPanel/createAssignUnits")
);
const EditDeleteAssignUnit = React.lazy(() =>
  import("../CategoryPanel/editDeleteAssignUnit")
);

const CreateAdBanerType = React.lazy(() => import("./createAdBanerType"));
const EditDeleteAdBanerType = React.lazy(() =>
  import("./editDeleteAdBanerType")
);

const CreatePattern = React.lazy(() => import("./createPattern"));
const EditDeletePattern = React.lazy(() => import("./editDeletePattern"));
const BindPatterns = React.lazy(() => import("./bindPatterns"));

const CreateAdmitCard = React.lazy(() => import("./createAdmitCard"));
const EditDeleteAdmitCard = React.lazy(() => import("./editDeleteAdmitCard"));

const CreateCouponCode = React.lazy(() => import("./createCouponCode"));
const CreateCouponCodeMultipleExam = React.lazy(() =>
  import("./createCouponCodeMultipleExam")
);
const EditDeleteCouponCode = React.lazy(() => import("./editDeleteCouponCode"));

const CreateCouponCodeExam = React.lazy(() => import("./createCouponCodeExam"));
const CreateCouponCodeNew = React.lazy(() => import("./createCouponCodeNew"));
const EditDeleteCouponCodeEdutech = React.lazy(() =>
  import("./editDeleteCouponCodeEdutech")
);

const CreateCounsellorCouponNew = React.lazy(() =>
  import("./createCounsellorCouponNew")
);
const EditDeleteCounsellorCouponNew = React.lazy(() =>
  import("./editDeleteCounsellorCouponNew")
);
const CopyCounsellorCoupon = React.lazy(() => import("./copyCounsellorCoupon"));

const CreateBbMeetingId = React.lazy(() => import("./createBbMeetingId"));
const EditDeleteBbMeetingId = React.lazy(() =>
  import("./editDeleteBbMeetingId")
);

const BindBBBMeeting = React.lazy(() => import("./bindBBBMeeting"));

const CreateBranch = React.lazy(() => import("./createBranch"));
const EditDeleteBranch = React.lazy(() => import("./editDeleteBranch"));

const CreateBatchType = React.lazy(() => import("./createbatchtype"));
const EditDeleteBatchType = React.lazy(() => import("./editdeletebatchtype"));

const CreateCourseType = React.lazy(() => import("./createcoursetype"));
const EditDeleteCourseType = React.lazy(() => import("./editdeletecoursetype"));

const CreateSopPpt = React.lazy(() => import("./createSopPpt"));
const EditDeletSopPpt = React.lazy(() => import("./editDeleteSopPpt"));

const RegistrationDetails = React.lazy(() =>
  import("../CounsellorPanel/registrationDetails")
);

const DoubtSolve = React.lazy(() => import("./doubtSolveReview"));

const BaseOfferDiscount = React.lazy(() =>
  import("./BaseOffer/baseOfferDiscount")
);
const EditBaseOfferDiscount = React.lazy(() =>
  import("./BaseOffer/editBaseOfferDiscount")
);
const EditBaseOfferDemo = React.lazy(() => import("./BaseOffer/demo"));

//Free YT Lecture Web
const AddFreeYTLectures = React.lazy(() =>
  import("./FreeYTLectures/addFreeYTLectures")
);
const EditFreeYTLectures = React.lazy(() =>
  import("./FreeYTLectures/editFreeYTLectures")
);
//CATEGORY INCLUDE
const AddNewCategoryDesc = React.lazy(() =>
  import("./CategoryIncudeDesc/addNewCatDesc")
);
const EditNewCategoryDesc = React.lazy(() =>
  import("./CategoryIncudeDesc/editCatDesc")
);
const AddExamMapping = React.lazy(() => import("./ExamMapping/addExamMapping"));
const EditExamMapping = React.lazy(() =>
  import("./ExamMapping/editExamMapping")
);
const AddDepartmentMapping = React.lazy(() =>
  import("./DepartmentMapping/addDepartmentMapping")
);
const EditDepartmentMapping = React.lazy(() =>
  import("./DepartmentMapping/editDepartmentMapping")
);
const AddOnMapping = React.lazy(() => import("./addOnMapping"));
const CopyCategoryInclude = React.lazy(() =>
  import("./CopyCategoryInclude/copyCategoryInclude")
);
const EditDeleteCategoryInclude = React.lazy(() =>
  import("./CopyCategoryInclude/editDeleteCategoryInclude")
);
const StudentCouponAdd = React.lazy(() =>
  import("../CXOPanel/studentCouponCode/studentCouponAdd")
);
const StudentCouponEdit = React.lazy(() =>
  import("../CXOPanel/studentCouponCode/studentCouponEdit")
);
const AddFixedDiscount = lazy(() => import("./FixedDiscount/addFixedDiscount"));
const EditFixedDiscount = lazy(() =>
  import("./FixedDiscount/editFixedDiscount")
);
const MovementsOfMeeting = React.lazy(() =>
  import("../CategoryPanel/MovementsOfMeeting/movementsOfMeeting")
);
const EditMovementsOfMeeting = React.lazy(() =>
  import("../CategoryPanel/MovementsOfMeeting/editMovementsOfMeeting")
);

function DateEntryPanelDashboard() {
  const { loginType } = useSelector((state) => ({
    designation: state.Auth?.userInfo?.designation,
    loginType: state.Auth?.loginType,
  }));
  console.log("loginType", loginType);

  return (
    <div className="AppBox">
      <Sidebar
        data={
          loginType === 2 ? AdminPanelEmpSidebarData : AdminPanelSidebarData
        }
      />
      <div className="dashboard">
        <CommonRoutes loginType={loginType} defaultPage={<CreateExam />}>
          <Route path="/" element={<CreateExam />} />
          <Route path="createParentCourse" element={<CreateParentCourse />} />
          <Route
            path="editDeleteParentCourse"
            element={<EditDeleteParentCourse />}
          />
          <Route path="createExam" element={<CreateExam />} />
          <Route path="editDeleteExam" element={<EditDeleteExam />} />
          <Route path="createSubExam" element={<CreateSubExam />} />
          <Route path="editDeleteSubExam" element={<EditDeleteSubExam />} />
          <Route path="createLanguage" element={<CreateLanguage />} />
          <Route path="editDeleteLanguage" element={<EditDeleteLanguage />} />
          <Route path="createAssignExam" element={<CreateAssignExam />} />
          <Route path="createPackage" element={<CreatePackage />} />
          <Route
            path="createAssignExamStream"
            element={<CreateAssignExamStream />}
          />
          <Route path="createSubCategory" element={<CreateSubCategory />} />
          <Route
            path="editDeleteSubCategory"
            element={<EditDeleteSubCategory />}
          />
          <Route path="createAssignBooks" element={<CreateAssignBooks />} />
          <Route
            path="EditDeleteAssignBooks"
            element={<EditDeleteAssignBooks />}
          />
          <Route path="createSubject" element={<CreateSubject />} />
          <Route path="editDeleteSubject" element={<EditDeleteSubject />} />
          <Route path="createMainCategory" element={<CreateMainCategory />} />
          <Route
            path="editDeleteMainCategory"
            element={<EditDeleteMainCategory />}
          />
          <Route path="createSection" element={<CreateSection />} />
          <Route path="editDeleteSection" element={<EditDeleteSection />} />
          <Route path="createPackageExtend" element={<CreatePackageExtend />} />
          <Route
            path="editDeletePackageExtend"
            element={<EditDeletePackageExtend />}
          />
          <Route path="editDeletePackage" element={<EditDeletePackage />} />
          <Route path="createTeacher" element={<CreateTeacher />} />
          <Route path="editDeleteTeacher" element={<EditDeleteTeacher />} />
          <Route path="createAssignUnits" element={<CreateAssignUnits />} />
          <Route path="batchAnalysis" element={<BatchAnalysis />} />
          <Route path="createBasePrice" element={<CreateBasePrice />} />
          <Route path="editDeleteBasePrice" element={<EditDeleteBasePrice />} />
          <Route
            path="editDeleteAssignUnit"
            element={<EditDeleteAssignUnit />}
          />
          <Route path="createAdBanerType" element={<CreateAdBanerType />} />
          <Route
            path="editDeleteAdBanerType"
            element={<EditDeleteAdBanerType />}
          />
          <Route path="createPattern" element={<CreatePattern />} />
          <Route path="editDeletePattern" element={<EditDeletePattern />} />
          <Route path="bindPatterns" element={<BindPatterns />} />
          <Route path="createAdmitCard" element={<CreateAdmitCard />} />
          <Route path="editDeleteAdmitCard" element={<EditDeleteAdmitCard />} />
          <Route path="createCouponCode" element={<CreateCouponCode />} />
          <Route
            path="createCouponCodeMultipleExam"
            element={<CreateCouponCodeMultipleExam />}
          />
          <Route
            path="editDeleteCouponCode"
            element={<EditDeleteCouponCode />}
          />
          <Route
            path="createCouponCodeExam"
            element={<CreateCouponCodeExam />}
          />
          <Route path="createCouponCodeNew" element={<CreateCouponCodeNew />} />
          <Route
            path="editDeleteCouponCodeEdutech"
            element={<EditDeleteCouponCodeEdutech />}
          />
          <Route
            path="createCounsellorCouponNew"
            element={<CreateCounsellorCouponNew />}
          />
          <Route
            path="editDeleteCounsellorCouponNew"
            element={<EditDeleteCounsellorCouponNew />}
          />
          <Route
            path="copyCounsellorCoupon"
            element={<CopyCounsellorCoupon />}
          />

          <Route path="createBbMeetingId" element={<CreateBbMeetingId />} />
          <Route
            path="editDeleteBbMeetingId"
            element={<EditDeleteBbMeetingId />}
          />
          <Route path="bindBBBMeeting" element={<BindBBBMeeting />} />
          <Route path="createBranch" element={<CreateBranch />} />
          <Route path="editDeleteBranch" element={<EditDeleteBranch />} />
          <Route path="createBatchType" element={<CreateBatchType />} />
          <Route path="batchClone" element={<BatchClone />} />
          <Route path="editDeleteBatchType" element={<EditDeleteBatchType />} />
          <Route path="createCourseType" element={<CreateCourseType />} />
          <Route
            path="editDeleteCourseType"
            element={<EditDeleteCourseType />}
          />
          <Route path="createSopPpt" element={<CreateSopPpt />} />
          <Route path="editDeletSopPpt" element={<EditDeletSopPpt />} />
          <Route path="registrationDetails" element={<RegistrationDetails />} />
          <Route path="doubtsolvereview" element={<DoubtSolve />} />
          <Route path="baseOfferDiscount" element={<BaseOfferDiscount />} />
          <Route
            path="editBaseOfferDiscount"
            element={<EditBaseOfferDiscount />}
          />
          <Route path="demo" element={<EditBaseOfferDemo />} />

          <Route path="addFreeYTLectures" element={<AddFreeYTLectures />} />
          <Route path="editFreeYTLectures" element={<EditFreeYTLectures />} />
          <Route path="addNewCatDesc" element={<AddNewCategoryDesc />} />
          <Route path="editCategoryDesc" element={<EditNewCategoryDesc />} />
          <Route
            path="/addRecordedLectureInCourse"
            element={<AddRecordedLectureInCourse />}
          />
          <Route path="/questionDoubt" element={<QuestionDoubt />} />
          <Route path="/createExtendGlobal" element={<CreateExtendGlobal />} />
          <Route
            path="/createUpgradeGlobal"
            element={<CreateUpgradeGlobal />}
          />
          <Route
            path="/editDeleteUpgradeGlobal"
            element={<EditDeleteUpgradeGlobal />}
          />
          <Route
            path="/editDeleteExtendGlobal"
            element={<EditDeleteExtendGlobal />}
          />
          <Route path="/addExamMapping" element={<AddExamMapping />} />
          <Route path="/editExamMapping" element={<EditExamMapping />} />
          <Route
            path="/addDepartmentMapping"
            element={<AddDepartmentMapping />}
          />
          <Route
            path="/editDepartmentMapping"
            element={<EditDepartmentMapping />}
          />
          <Route path="/addOnMapping" element={<AddOnMapping />} />
          <Route
            path="/copyCategoryInclude"
            element={<CopyCategoryInclude />}
          />
          <Route
            path="/editDeleteCategoryInclude"
            element={<EditDeleteCategoryInclude />}
          />
          <Route path="studentCouponAdd" element={<StudentCouponAdd />} />
          <Route path="studentCouponEdit" element={<StudentCouponEdit />} />
          <Route path="studentCouponEdit" element={<StudentCouponEdit />} />
          <Route path="UpcomingBatchAnalysis" element={<BatchaAnalisis />} />
          <Route
            path="UpcomingBatchesAdd"
            element={<CreateUpcomingBatches />}
          />
          <Route path="UpcomingBatchesEdit" element={<EditUpcomingBatches />} />
          <Route path="addFixedDiscount" element={<AddFixedDiscount />} />
          <Route path="editFixedDiscount" element={<EditFixedDiscount />} />
          <Route path="movementsOfMeeting" element={<MovementsOfMeeting />} />
          <Route
            path="editMovementsOfMeeting"
            element={<EditMovementsOfMeeting />}
          />
        </CommonRoutes>
      </div>
    </div>
  );
}

export default DateEntryPanelDashboard;
