import React, { Suspense } from "react";
import Sidebar from "../../Module/sidebar";
import {
  BdeSideBarData,
  BdeSideBarEmpData,
} from "../../Module/BdePanel/bdeSidebarData";
import Loading from "../../Component/Loading";
import { Route, Routes } from "react-router";
import { useSelector } from "react-redux";
import CommonRoutes from "../Common/commonRoute";

const Webinar = React.lazy(() => import("./webinar"));

const BdeDashboard = () => {
  const { loginType } = useSelector((state) => ({
    loginType: state.Auth?.loginType,
  }));
  console.log("loginType", loginType);
  return (
    <div className="AppBox">
      {/* <Header /> */}
      <Sidebar data={loginType === 2 ? BdeSideBarEmpData : BdeSideBarData} />
      <div className="dashboard">
        {
          <CommonRoutes loginType={loginType} defaultPage={<Webinar />}>
            <Route path="/" element={<Webinar />} />
            <Route path="webinar" element={<Webinar />} />
          </CommonRoutes>
        }
      </div>
    </div>
  );
};

export default BdeDashboard;
