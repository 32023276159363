/* eslint-disable no-useless-escape */
// Validation functions start
export const containsSpecialChars = (str) => {
  const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  return specialChars.test(str);
};
export const containSpaceBetween = (str) => {
  const regex = /\s{2,}/;
  return regex.test(str);
};
export const containSpace = (str) => {
  const regex = /\s{1,}/;
  return regex.test(str);
};
export function containsNumber(str) {
  return /^[0-9]+$/.test(str);
}
export function containsNumber1to100(str) {
  return /^0*(?:[0-9][0-9]?|100)$/.test(str);
}
export function containsNumberDecimal(str) {
  return /^[0-9]\d*(\.[0-9]\d)?/.test(str);
}
export function hasWhiteSpace(str) {
  return /\s+/gi(str);
}

export function startsWithSpace(str) {
  return /^\s/.test(str);
}

export function startsWithNumber(str) {
  return /^\d/.test(str);
}

export const startsWithSpecialChars = (str) => {
  const specialChars = /^[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  return specialChars.test(str);
};

export function singlespace(str) {
  var input = document.getElementById(str);
  input.addEventListener(str, function (e) {
    var input = e.target;
    var val = input.value;
    var end = input.selectionEnd;
    if (e.keyCode === 32 && (val[end - 1] === " " || val[end] === " ")) {
      e.preventDefault();
      return false;
    }
  });
}

export function containsStartsWithSpecialCharsOrStartsWithNumberOrWhiteSpace(
  str
) {
  if (
    startsWithSpecialChars(str) ||
    startsWithNumber(str) ||
    startsWithSpace(str)
  ) {
    return true;
  }
  return false;
}

export function containsSpecialCharsOrNumberOrWhiteSpace(str) {
  if (
    containsSpecialChars(str) ||
    containsNumber(str) ||
    startsWithSpace(str)
  ) {
    return true;
  }
  return false;
}

export function containsSpecialCharsOrStartsWithNumberOrWhiteSpace(str) {
  if (
    containsSpecialChars(str) ||
    startsWithNumber(str) ||
    startsWithSpace(str)
  ) {
    return true;
  }
  return false;
}

export function containsAllowedSpecialCharsOrNumberOrWhiteSpace(str) {
  const AllowedSpecialChar = ["&", ":", ","];
  str = str.charAt(str.length - 1);
  if (containsSpecialChars(str)) {
    if (!AllowedSpecialChar.includes(str)) {
      return true;
    }
  }
}
export const validateNameWithoutSpace = (str) => {
  if (containSpaceBetween(str)) {
    return false;
  }
  return true;
};

export const validateValueWithoutSpace = (str) => {
  if (containSpace(str)) {
    return false;
  }
  return true;
};

export function validateFullName(str) {
  // return /^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/.test(str)
  // let regName = /^[a-zA-Z]+$/;
  // let regFullName= /^[a-zA-Z]+ [a-zA-Z]+$/;
  if (
    typeof str !== "string" ||
    /[0-9]+/g.test(str) ||
    containsSpecialChars(str) ||
    startsWithSpace(str)
  ) {
    return false;
  }
  return true;
  // if (regName.test(str) || regFullName.test(str)) {
  //   return true;
  // } else {
  //   return false;
  // }
}

export function validateCouponCodeName(str) {
  if (
    startsWithNumber(str) ||
    startsWithSpace(str) ||
    // hasWhiteSpace(str) ||
    containsSpecialChars(str)
  ) {
    return false;
  }
  return true;
}

export function validateIsFileImageType(e) {
  if (e?.target?.files[0]?.type.substring(0, 5) === "image") {
    return true;
  }
  return false;
}

export function validateIsFileDocsType(e) {
  if (
    e?.target?.files[0]?.type ===
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  ) {
    return true;
  }
  return false;
}

export function validateIsFileVideoType(e) {
  if (e?.target?.files[0]?.type.substring(0, 5) === "video") {
    return true;
  }
  return false;
}

export function validateIsFilePDFType(e) {
  if (e?.target?.files[0]?.type === "application/pdf") {
    return true;
  }
  return false;
}

export function validateIsFilePDFImageType(e) {
  if (
    e?.target?.files[0]?.type === "application/pdf" ||
    e?.target?.files[0]?.type.substring(0, 5) === "image"
  ) {
    return true;
  }
  return false;
}

export function validateIsFileExcelType(e) {
  if (
    e?.target?.files[0]?.type ===
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  ) {
    return true;
  }
  return false;
}

export function validateisValidPrice(value) {
  return /^\d{0,5}(\.\d{1,2})?$/.test(value);
}

export const validateIsUrlPattern = (urlString) => {
  if (urlString)
    return urlString.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
  else return;
};

export const isValidDiscount = (value) => {
  let r = /^[0-9]\d*(\.[0-9]\d)?/;
  // let r = /(\d+(?:\.\d+)?)/;
  let isValidate = false;
  isValidate = r.test(value) || value === "";

  return isValidate;
};

export const validateDecimal = (str) => {
  // check if its in range from 0 to 100
  return str >= 0 && str <= 100;
};

export const validateDecimalNumber = (str) => {
  // check if its in range from 0 to 100
  return str >= 0 && str <= 500;
};

export const validateLoginId = (str) => {
  if (
    startsWithNumber(str) ||
    startsWithSpace(str) ||
    startsWithSpecialChars(str)
    // || hasWhiteSpace(str)
  ) {
    return false;
  }
  return true;
};

export const validateMeetingId = (str) => {
  if (
    // startsWithNumber(str) ||
    startsWithSpace(str) ||
    startsWithSpecialChars(str)
    // || hasWhiteSpace(str)
  ) {
    return false;
  }
  return true;
};

export const validatePassword = (str) => {
  if (startsWithSpace(str)) {
    return false;
  }
  return true;
};

export const validateEmailAddress = (str) => {
  return str?.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

export const validateIsNumberOnly = (value) => {
  return /\D/.test(value);
};
export const checkValidName = (name) => {
  let regName = /^[a-zA-Z]+$/;
  let regFullName = /^[a-zA-Z]+ [a-zA-Z]+$/;
  if (regName.test(name) || regFullName.test(name)) {
    return true;
  } else {
    return false;
  }
};
export const validateEmptyString = (value) => {
  return /^\s*$/.test(value);
};

// Validation functions ends
export const length_One = 1;
export const length_Two = 2;
export const length_Three = 3;
export const length_Four = 4;
export const length_Five = 5;
export const length_Six = 7;
export const length_Eight = 8;
export const length_Ten = 10;
export const length_Sixteen = 16;
export const length_Seventeen = 17;
export const length_Twenty = 20;
export const length_Thirty = 31;
export const length_Fifty = 51;
export const length_Hundred = 101;
export const length_OneHundredFifty = 151;
export const length_TwoHundred = 201;
export const length_ThreeHundred = 300;
export const length_FiveHundred = 500;
export const length_OneThousandFiveHundred = 1500;
export const length_FiveThousand = 5000;

// Image size in kb
export const image_size_200 = 204800;
export const image_size_150 = 153600;
export const image_size_5MB = 5120000;
export const image_size_1MB = 1048576;
export const pdf_size_1MB = 10240000;
export const pdf_size_5MB = 5120000;
export const pdf_size_50MB = 52428800;
export const pdf_size_100MB = 104857600;
export const pdf_size_150MB = 157286400;
export const book_price = 10001;
export const video_size_25MB = 25600000;
export const docs_size_20MB = 21105760;
