import React, { lazy } from "react";
import Sidebar from "../../Module/sidebar";
import { Route } from "react-router";
import {
  SMTEmpSideBarData,
  SMTSideBarData,
} from "../../Module/SMTeamPanel/smtSidebarData";
import ResultCompilation from "../../Module/SMTeamPanel/resultCompilation";
import { useSelector } from "react-redux";
import CommonRoutes from "../Common/commonRoute";

const AddYoutubeLive = lazy(() => import("../CategoryPanel/addYoutubeLive"));
const EditDeleteYoutubeLive = lazy(() =>
  import("../CategoryPanel/editDeleteYoutubeLive")
);

const AddYoutubeRecordedVideo = lazy(() =>
  import("../CategoryPanel/addYoutubeRecordedVideo")
);
const EditDeleteYoutubeRecordedVideo = lazy(() =>
  import("../CategoryPanel/editDeleteYoutubeRecordedVideo")
);
const AddFreeUserVideo = lazy(() =>
  import("../DataEntryPanel/addFreeUserVideo")
);
const EditDeleteFreeUserVideo = lazy(() =>
  import("../DataEntryPanel/editDeleteFreeUserVideo")
);
const YoutubeSession = lazy(() => import("./youtubeSession"));
//EXAM SOURCE VIDEO
const AddExamSrcVideo = lazy(() =>
  import("./ExamSourceVideo/createExamSourceVideo")
);
const EditExamSrcVideo = lazy(() =>
  import("./ExamSourceVideo/editExamSrcVideo")
);
const MovementsOfMeeting = lazy(() =>
  import("../CategoryPanel/MovementsOfMeeting/movementsOfMeeting")
);
const EditMovementsOfMeeting = lazy(() =>
  import("../CategoryPanel/MovementsOfMeeting/editMovementsOfMeeting")
);
const YoutubeSeoTracking = lazy(() =>
  import("../SMTeamPanel/YoutubeSeoTracking/youtubeSeoTracking")
);
const SMTDashboard = () => {
  const { loginType } = useSelector((state) => ({
    designation: state.Auth?.userInfo?.designation,
    loginType: state.Auth?.loginType,
  }));

  return (
    <div className="AppBox">
      <Sidebar data={loginType === 2 ? SMTEmpSideBarData : SMTSideBarData} />
      <div className="dashboard">
        <CommonRoutes loginType={loginType} defaultPage={<AddYoutubeLive />}>
          <Route path="/" element={<AddYoutubeLive />} />
          <Route path="addYoutubeLive" element={<AddYoutubeLive />} />
          <Route
            path="editDeleteYoutubeLive"
            element={<EditDeleteYoutubeLive />}
          />
          <Route
            path="addYoutubeRecordedVideo"
            element={<AddYoutubeRecordedVideo />}
          />
          <Route
            path="editDeleteYoutubeRecordedVideo"
            element={<EditDeleteYoutubeRecordedVideo />}
          />
          <Route path="addFreeVideo" element={<AddFreeUserVideo />} />
          <Route path="editFreeVideo" element={<EditDeleteFreeUserVideo />} />
          <Route path="youtubeSession" element={<YoutubeSession />} />
          <Route path="addExamSrcVideo" element={<AddExamSrcVideo />} />
          <Route path="editExamSrcVideo" element={<EditExamSrcVideo />} />
          <Route path="resultCompilation" element={<ResultCompilation />} />
          <Route path="movementsOfMeeting" element={<MovementsOfMeeting />} />
          <Route
            path="editMovementsOfMeeting"
            element={<EditMovementsOfMeeting />}
          />
          <Route path="youtubeSeoTracking" element={<YoutubeSeoTracking />} />
        </CommonRoutes>
      </div>
    </div>
  );
};

export default SMTDashboard;
