import React from "react";
import { useSelector } from "react-redux";
import { io } from "socket.io-client";
import ChatRTMP from "./ChatRTMP";

const ChatPage = () => {
  // const { streamKey } = useSelector((state) => ({
  //   streamKey: state?.Auth?.streamKey,
  // }));

  // const socket = io.connect('https://stagingbackends.ifasonline.com', {
  //   secure: true,
  //   query: {
  //     room: streamKey
  //   },
  //   withCredentials: true
  // });
  // console.log('socket', socket);

  return (
      <ChatRTMP/>
  );
};

export default ChatPage;
