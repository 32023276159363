export const SalesDirectorPanelSidebarData = [
  {
    title: "TRANSACTION DETAILS",
    subNav: [
      {
        title: "TRANSACTION EDUTECH",
        path: "transactionedutech",
        cName: "sub-nav",
      },
      {
        title: "TRANSACTION EDUTECH STUDY MATERIAL",
        path: "transactionEdutechStudyMaterial",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "REVENUE ANALYSIS",
    subNav: [
      {
        title: "SOURCE WISE ANALYSIS",
        path: "sourceWiseAnalysis",
        cName: "sub-nav",
      },
      {
        title: "STATE WISE ANALYSIS",
        path: "stateWiseAnalysis",
        cName: "sub-nav",
      },
      {
        title: "ALL EXAM WISE REVENUE NUMBER",
        path: "allExamWiseRevenue",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "ADMISSION ANALYSIS",
    subNav: [
      {
        title: "BATCHWISE COUNT",
        path: "cxoBatchwiseCount",
        cName: "sub-nav",
      },
      {
        title: "DATEWISE COUNT",
        path: "datewisecount",
        cName: "sub-nav",
      },
      {
        title: "SECOND INSTALLMENT AMOUNT",
        subNav: [
          {
            title: "ASSURED REVENUE",
            path: "assuredRevenue",
            cName: "sub-nav",
          },
          {
            title: "SECOND INSTALLMENT DUE",
            path: "secondInstallmentDue",
            cName: "sub-nav",
          },
          {
            title: "SECOND INSTALLMENT PAID",
            path: "secondInstallmentPaid",
            cName: "sub-nav",
          },
        ],
      },
    ],
  },
  {
    title: "LEADS DETAILS",
    subNav: [
      {
        title: "ALL SOURCE DETAILS LEADS",
        path: "registereduser",
        cName: "sub-nav",
      },
      {
        title: "ALL SOURCE LEAD COUNTS",
        path: "allSourceLead",
        cName: "sub-nav",
      },
      {
        title: "WEBSITE LEADS",
        path: "websiteLeadCount",
        cName: "sub-nav",
      },

      {
        title: "CLASSROOM LEADS",
        path: "classroomstudents",
        cName: "sub-nav",
      },
      {
        title: "HOME PAGE LEADS",
        path: "expertsupport",
        cName: "sub-nav",
      },
      {
        title: "SOURCE ANALYSIS",
        path: "leadAnalysis",
        cName: "sub-nav",
      },
      {
        title: "REFERENCE GENERATED",
        path: "referenceGenerated",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "CRM Data",
    subNav: [
      {
        title: "REFERENCE LEADS",
        path: "referenceManagerCount",
        cName: "sub-nav",
      },
      {
        title: "STATUS MEETING",
        path: "dailyMeeting",
        cName: "sub-nav",
      },
      {
        title: "Leads Allotted Date Analysis",
        path: "teamLeadAssignedDateAnalysis",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "CRM Leads Analysis",
    subNav: [
      {
        title: "LEAD GENERATION DASHBOARD",
        path: "leadGenerationOverview",
        cName: "sub-nav",
      },
      {
        title: "LEADS ALLOCATIONS",
        path: "teamLeadsOverview",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "PERFORMANCE",
    subNav: [
      {
        title: "Sales Performance",
        path: "salesPerformance",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "RESULT COMPILATION",
    subNav: [
      {
        title: "ADD RESULT",
        path: "resultCompilation",
        cName: "sub-nav",
      },
      {
        title: "STUDENT RESULT",
        path: "studentResult",
        cName: "sub-nav",
      },
    ],
  },
];

export const SalesDirectorEmpPanelSidebarData = [
  {
    title: "TRANSACTION DETAILS",
    subNav: [
      {
        title: "TRANSACTION EDUTECH",
        path: "transactionedutech",
        cName: "sub-nav",
      },
      {
        title: "TRANSACTION EDUTECH STUDY MATERIAL",
        path: "transactionEdutechStudyMaterial",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "REVENUE ANALYSIS",
    subNav: [
      {
        title: "SOURCE WISE ANALYSIS",
        path: "sourceWiseAnalysis",
        cName: "sub-nav",
      },
      {
        title: "STATE WISE ANALYSIS",
        path: "stateWiseAnalysis",
        cName: "sub-nav",
      },
      {
        title: "ALL EXAM WISE REVENUE NUMBER",
        path: "allExamWiseRevenue",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "ADMISSION ANALYSIS",
    subNav: [
      {
        title: "BATCHWISE COUNT",
        path: "cxoBatchwiseCount",
        cName: "sub-nav",
      },
      {
        title: "DATEWISE COUNT",
        path: "datewisecount",
        cName: "sub-nav",
      },
      {
        title: "SECOND INSTALLMENT AMOUNT",
        subNav: [
          {
            title: "ASSURED REVENUE",
            path: "assuredRevenue",
            cName: "sub-nav",
          },
          {
            title: "SECOND INSTALLMENT DUE",
            path: "secondInstallmentDue",
            cName: "sub-nav",
          },
          {
            title: "SECOND INSTALLMENT PAID",
            path: "secondInstallmentPaid",
            cName: "sub-nav",
          },
        ],
      },
    ],
  },
  {
    title: "LEADS DETAILS",
    subNav: [
      {
        title: "ALL SOURCE DETAILS LEADS",
        path: "registereduser",
        cName: "sub-nav",
      },
      {
        title: "ALL SOURCE LEAD COUNTS",
        path: "allSourceLead",
        cName: "sub-nav",
      },
      {
        title: "WEBSITE LEADS",
        path: "websiteLeadCount",
        cName: "sub-nav",
      },

      {
        title: "CLASSROOM LEADS",
        path: "classroomstudents",
        cName: "sub-nav",
      },
      {
        title: "HOME PAGE LEADS",
        path: "expertsupport",
        cName: "sub-nav",
      },
      {
        title: "SOURCE ANALYSIS",
        path: "leadAnalysis",
        cName: "sub-nav",
      },
      {
        title: "REFERENCE GENERATED",
        path: "referenceGenerated",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "CRM Data",
    subNav: [
      {
        title: "REFERENCE LEADS",
        path: "referenceManagerCount",
        cName: "sub-nav",
      },
      {
        title: "STATUS MEETING",
        path: "dailyMeeting",
        cName: "sub-nav",
      },
      {
        title: "Leads Allotted Date Analysis",
        path: "teamLeadAssignedDateAnalysis",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "CRM Leads Analysis",
    subNav: [
      {
        title: "LEAD GENERATION DASHBOARD",
        path: "leadGenerationOverview",
        cName: "sub-nav",
      },
      {
        title: "LEADS ALLOCATIONS",
        path: "teamLeadsOverview",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "PERFORMANCE",
    subNav: [
      {
        title: "Sales Performance",
        path: "salesPerformance",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "RESULT COMPILATION",
    subNav: [
      {
        title: "ADD RESULT",
        path: "resultCompilation",
        cName: "sub-nav",
      },
      {
        title: "STUDENT RESULT",
        path: "studentResult",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "MOVEMENTS OF MEETING",
    subNav: [
      {
        title: "MOVEMENTS OF MEETING",
        path: "movementsOfMeeting",
        cName: "sub-nav",
      },
      {
        title: "EDIT/DELETE",
        path: "editMovementsOfMeeting",
        cName: "sub-nav",
      },
    ],
  },
];

