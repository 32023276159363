import "../../App.css";
import React, { lazy, Suspense } from "react";
import Sidebar from "../../Module/sidebar";
import { Route, Routes } from "react-router-dom";
import Loading from "../../Component/Loading";
import { SEOEmpSidebarData, SEOSidebarData } from "../../Module/seoSidebarData";
import AddCourseFAQ from "./addCourseFAQ";
import AddExamFAQ from "./addExamFAQ";
import AddExamStreamFAQ from "./addExamStreamFAQ";
import AddAuthorDetails from "./addAuthorDetails";
import AddResourcesPages from "./addResourcesPages";
import AddFaqResourcesPages from "./addFaqResourcesPages";
import EditDeleteCourseFAQ from "./editDeleteCourseFAQ";
import EditDeleteExamFAQ from "./editDeleteExamFAQ";
import EditDeleteExamStreamFAQ from "./editDeleteExamStreamFAQ";
import AddProductSchema from "./addProductSchema";
import EditDeleteProductSchema from "./editDeleteProductSchema";
import AddExamDetails from "./addExamDetails";
import EditDeleteExamDetails from "./editDeleteExamDetails";
import EditDeleteAuthorDetails from "./editDeleteAuthorDetails";
import Addresourcesseo from "./addresourcesseo";
import CreateOnlineCoaching from "./createOnlineCoaching";

import CreateResourceDetail from "./createResourceDetail";
import AddResourcesFAQ from "./addResourcesFAQ";
import EditDeleteResourcesFAQ from "./editDeleteResourcesFAQ";
import { useSelector } from "react-redux";
import CommonRoutes from "../Common/commonRoute";
const AddSEOCourse = React.lazy(() => import("./addSEOCourse"));
const EditDeleteSEOCourse = React.lazy(() => import("./editDeleteSEOCourse"));
const ResourcePageProduct = React.lazy(() => import("./resourcePageProduct"));
const AddSEOBlog = React.lazy(() => import("./addSEOBlogs"));
const EditDeleteSEOBlog = React.lazy(() => import("./editDeleteSEOBlogs"));
const AddEditSEOExam = React.lazy(() => import("./addEditSEOExam"));
const AddEditSEOSubExam = React.lazy(() => import("./addEditSEOSubExam"));
const AddEditSEOExamStream = React.lazy(() => import("./addEditSEOExamStream"));
const ResourcesPageDetails = React.lazy(() => import("./resourcesPageDetails"));
const CreateExamSyllabus = React.lazy(() =>
  import("../CategoryPanel/createExamSyllabus")
);
const EditDeleteExamSyllabus = React.lazy(() =>
  import("../CategoryPanel/editDeleteExamSyllabus")
);
const AllAboutExam = React.lazy(() =>
  import("../CategoryPanel/createAllAboutExam")
);
const ExamDates = React.lazy(() => import("../CategoryPanel/createExamDates"));
const CreatePyqPdf = React.lazy(() => import("../CategoryPanel/createPyqPdf"));
const EditDeletePyqPdf = React.lazy(() =>
  import("../CategoryPanel/editDeletePyqPdf")
);

const PaperAnalysis = React.lazy(() =>
  import("../CategoryPanel/createPaperAnalysis")
);

const CutOff = React.lazy(() => import("../CategoryPanel/createCutOff"));
const CreatePattern = React.lazy(() =>
  import("../CategoryPanel/createPattern")
);
const EditDeletePattern = React.lazy(() =>
  import("../CategoryPanel/editDeletePattern")
);
const MovementsOfMeeting = React.lazy(() =>
  import("../CategoryPanel/MovementsOfMeeting/movementsOfMeeting")
);
const EditMovementsOfMeeting = React.lazy(() =>
  import("../CategoryPanel/MovementsOfMeeting/editMovementsOfMeeting")
);
const AddGetContactUs = lazy(() => import("./addGetContactUs"));
const AddOldUrl = lazy(() => import("./addOldUrl"));
function SEODashboard() {
  const { loginType } = useSelector((state) => ({
    designation: state.Auth?.userInfo?.designation,
    loginType: state.Auth?.loginType,
  }));
  console.log("loginType", loginType);

  return (
    <div className="AppBox">
      <Sidebar data={loginType === 2 ? SEOEmpSidebarData : SEOSidebarData} />
      <div className="dashboard">
        {
          <CommonRoutes loginType={loginType} defaultPage={<AddEditSEOExam />}>
            <Route path="/" element={<AddEditSEOExam />} />
            <Route path="/addEditSEOExam" element={<AddEditSEOExam />} />
            <Route path="/addEditSEOSubExam" element={<AddEditSEOSubExam />} />
            <Route
              path="/addEditSEOExamStream"
              element={<AddEditSEOExamStream />}
            />
            <Route path="/addSEOCourse" element={<AddSEOCourse />} />
            <Route
              path="/editDeleteSEOCourse"
              element={<EditDeleteSEOCourse />}
            />
            <Route path="/addCourseFAQ" element={<AddCourseFAQ />} />
            <Route
              path="/editDeleteCourseFAQ"
              element={<EditDeleteCourseFAQ />}
            />
            <Route path="/addExamFAQ" element={<AddExamFAQ />} />
            <Route path="/editDeleteExamFAQ" element={<EditDeleteExamFAQ />} />
            <Route path="/addAuthorDetails" element={<AddAuthorDetails />} />

            <Route path="/addResourcesPages" element={<AddResourcesPages />} />
            <Route
              path="/addFaqResourcesPages"
              element={<AddFaqResourcesPages />}
            />
            <Route path="/addExamStreamFAQ" element={<AddExamStreamFAQ />} />
            <Route
              path="/editDeleteExamStreamFAQ"
              element={<EditDeleteExamStreamFAQ />}
            />

            <Route path="/addProductSchema" element={<AddProductSchema />} />
            <Route
              path="/editDeleteProductSchema"
              element={<EditDeleteProductSchema />}
            />
            <Route path="/AddExamDetails" element={<AddExamDetails />} />
            <Route
              path="/EditDeleteExamDetails"
              element={<EditDeleteExamDetails />}
            />
            <Route path="/addAuthorDetails" element={<AddAuthorDetails />} />
            <Route
              path="/editDeleteAuthorDetails"
              element={<EditDeleteAuthorDetails />}
            />
            <Route path="/addresourcesseo" element={<Addresourcesseo />} />
            <Route
              path="/createOnlineCoaching"
              element={<CreateOnlineCoaching />}
            />
            <Route path="/resourceDetail" element={<CreateResourceDetail />} />
            <Route path="/addResourcesFAQ" element={<AddResourcesFAQ />} />
            <Route
              path="/resourcePageProduct"
              element={<ResourcePageProduct />}
            />
            <Route path="/addSEOBlogs" element={<AddSEOBlog />} />
            <Route path="/editDeleteSEOBlogs" element={<EditDeleteSEOBlog />} />
            <Route
              path="/editDeleteResourcesFAQ"
              element={<EditDeleteResourcesFAQ />}
            />
            <Route
              path="/resourcePageDetails"
              element={<ResourcesPageDetails />}
            />
            <Route
              path="/createExamSyllabus"
              element={<CreateExamSyllabus />}
            />
            <Route
              path="/editDeleteExamSyllabus"
              element={<EditDeleteExamSyllabus />}
            />
            <Route path="allAboutExam" element={<AllAboutExam />} />

            <Route path="createExamDates" element={<ExamDates />} />

            <Route path="createPyqPdf" element={<CreatePyqPdf />} />
            <Route path="editDeletePyqPdf" element={<EditDeletePyqPdf />} />

            <Route path="createExamPaperAnalysis" element={<PaperAnalysis />} />
            <Route path="cutOff" element={<CutOff />} />
            <Route path="createPattern" element={<CreatePattern />} />
            <Route path="editDeletePattern" element={<EditDeletePattern />} />

            {/* <Route path="/createAdmitCard" element={<CreateAdmitCard />} />
              <Route path="/createEligibility" element={<CreateEligibility />} />
              <Route path="/createApplicationFees" element={<CreateApplicationFees />} /> */}
            <Route path="movementsOfMeeting" element={<MovementsOfMeeting />} />
            <Route
              path="editMovementsOfMeeting"
              element={<EditMovementsOfMeeting />}
            />
            <Route path="contact-us" element={<AddGetContactUs />} />
            <Route path="old-url-redirection" element={<AddOldUrl />} />
          </CommonRoutes>
        }
      </div>
    </div>
  );
}

export default SEODashboard;
