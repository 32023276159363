export const SEOSidebarData = [
  // {
  //   title: "SEO EXAM NAME",
  //   subNav: [
  //     {
  //       title: "ADD SEO EXAM NAME",
  //       path: "addSEOExamName",
  //       cName: "sub-nav",
  //     },
  //     {
  //       title: "EDIT DELETE SEO EXAM",
  //       path: "editDeleteSEOExam",
  //       cName: "sub-nav",
  //     },
  //   ],
  // },
  // {
  //   title: "SEO SUB EXAM NAME",
  //   subNav: [
  //     {
  //       title: "ADD SEO SUB EXAM NAME",
  //       path: "addSEOSubExamName",
  //       cName: "sub-nav",
  //     },
  //     {
  //       title: "EDIT DELETE SEO SUB EXAM",
  //       path: "editDeleteSEOSubExam",
  //       cName: "sub-nav",
  //     },
  //   ],
  // },
  // {
  //   title: "SEO EXAM STREAM",
  //   subNav: [
  //     {
  //       title: "ADD SEO EXAM STREAM",
  //       path: "addSEOExamStream",
  //       cName: "sub-nav",
  //     },
  //     {
  //       title: "EDIT DELETE SEO EXAM STREAM",
  //       path: "editDeleteSEOExamStream",
  //       cName: "sub-nav",
  //     },
  //   ],
  // },
  {
    title: "SEO EXAM",
    link: "addEditSEOExam",
  },
  {
    title: "SEO SUB EXAM",
    link: "addEditSEOSubExam",
  },
  {
    title: "SEO EXAM STREAM",
    link: "addEditSEOExamStream",
  },
  // {
  //   title: "SEO EXAM NAME",
  //   link: "addSEOExamName",
  // },
  // {
  //   title: "SEO SUB EXAM NAME",
  //   link: "addSEOSubExamName",
  // },
  // {
  //   title: "SEO EXAM STREAM",
  //   link: "addSEOExamStream",
  // },
  {
    title: "SEO COURSE",
    subNav: [
      {
        title: "ADD SEO COURSE",
        path: "addSEOCourse",
        cName: "sub-nav",
      },
      {
        title: "EDIT DELETE SEO COURSE",
        path: "editDeleteSEOCourse",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "COURSE FAQ",
    subNav: [
      {
        title: "ADD FAQ",
        path: "addCourseFAQ",
        cName: "sub-nav",
      },
      {
        title: "EDIT DELETE FAQ",
        path: "editDeleteCourseFAQ",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "EXAM FAQ",
    subNav: [
      {
        title: "ADD EXAM FAQ",
        path: "addExamFAQ",
        cName: "sub-nav",
      },
      {
        title: "EDIT DELETE EXAM FAQ",
        path: "editDeleteExamFAQ",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "EXAM STREAM FAQ",
    subNav: [
      {
        title: "ADD EXAM STREAM FAQ",
        path: "addExamStreamFAQ",
        cName: "sub-nav",
      },
      {
        title: "EDIT DELETE EXAM STREAM FAQ",
        path: "editDeleteExamStreamFAQ",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "PRODUCT SCHEMA",
    subNav: [
      {
        title: "ADD PRODUCT SCHEMA",
        path: "addProductSchema",
        cName: "sub-nav",
      },
      {
        title: "EDIT PRODUCT SCHEMA",
        path: "editDeleteProductSchema",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "EXAM DETAILS",
    subNav: [
      {
        title: "ADD EXAM DETAILS",
        path: "AddExamDetails",
        cName: "sub-nav",
      },
      {
        title: "EDIT EXAM DETAILS",
        path: "EditDeleteExamDetails",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "AUTHOR DETAILS",
    subNav: [
      {
        title: "ADD AUTHOR DETAILS",
        path: "addAuthorDetails",
        cName: "sub-nav",
      },
      {
        title: "EDIT AUTHOR DETAILS",
        path: "editDeleteAuthorDetails",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "RESOURCE PAGE DETAILS",
    link: "resourcePageDetails",
  },
  // {
  //   title: "SEO RESOURSES",
  //   link: "addresourcesseo",
  // },
  // {
  //   title: "RESOURCE DETAILS",
  //   link: "resourceDetail",
  // },
  {
    title: "RESOURCE PAGE PRODUCT",
    link: "resourcePageProduct",
  },
  // {
  //   title: "FAQ OF RESOURCE PAGE",
  //   subNav: [
  //     {
  //       title: "ADD FAQ OF RESOURCE PAGE",
  //       path: "addResourcesFAQ",
  //       cName: "sub-nav",
  //     },
  //     {
  //       title: "EDIT DELETE ",
  //       path: "editDeleteResourcesFAQ",
  //       cName: "sub-nav",
  //     },
  //   ],
  // },
  {
    title: "BLOGS",
    subNav: [
      {
        title: "ADD",
        path: "addSEOBlogs",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editDeleteSEOBlogs",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "EXAM DETAILS DASHBOARD",
    subNav: [
      {
        title: "EXAM SYLLABUS",
        subNav: [
          {
            title: "CREATE",
            path: "createExamSyllabus",
            cName: "sub-nav",
          },
          {
            title: "EDIT / DELETE",
            path: "editDeleteExamSyllabus",
            cName: "sub-nav",
          },
        ],
      },
      {
        title: "ALL ABOUT EXAM",
        path: "allAboutExam",
        cName: "sub-nav",
      },

      {
        title: "EXAM DATES",
        path: "createExamDates",
        cName: "sub-nav",
      },
      {
        title: "PYQ PDF",
        subNav: [
          {
            title: "CREATE",
            path: "createpyqpdf",
            cName: "sub-nav",
          },
          {
            title: "EDIT / DELETE",
            path: "editdeletepyqpdf",
            cName: "sub-nav",
          },
        ],
      },
      {
        title: "EXAM PAPER ANALYSIS",
        path: "createExamPaperAnalysis",
        cName: "sub-nav",
      },
      {
        title: "CUT-OFF",
        path: "cutOff",
        cName: "sub-nav",
      },
      {
        title: "PATTERN",
        subNav: [
          {
            title: "CREATE",
            path: "createpattern",
            cName: "sub-nav",
          },
          {
            title: "EDIT / DELETE",
            path: "editdeletepattern",
            cName: "sub-nav",
          },
        ],
      },
    ],
  },
  {
    title: "CONTACT US",
    link: "contact-us",
  },
  {
    title: "OLD URL REDIRECTION",
    link: "old-url-redirection",
  },
  // {
  //   title: "ONLINE COACHING",
  //   link: "createOnlineCoaching",
  // },

  // {
  //   title: "ELIGIBILITY",
  //   link: "createEligibility",
  // },

  // {
  //   title: "APPLICATION FEES",
  //   link: "createApplicationFees",
  // },

  // {
  //   title: "ADMIT CARD",
  //   link: "createAdmitCard",
  // },
];

export const SEOEmpSidebarData = [
  {
    title: "EMPLOYEE DETAILS",
    subNav: [
      {
        title: "Dashboard",
        path: "dashboard",
        cName: "sub-nav",
      },
      {
        title: "My Profile",
        path: "userProfile",
        cName: "sub-nav",
      },
      {
        title: "Policies",
        path: "policy",
        cName: "sub-nav",
      },
      {
        title: "KRA/KPI",
        path: "kraKpi",
        cName: "sub-nav",
      },
    ],
  },
  // {
  //   title: "SEO EXAM NAME",
  //   subNav: [
  //     {
  //       title: "ADD SEO EXAM NAME",
  //       path: "addSEOExamName",
  //       cName: "sub-nav",
  //     },
  //     {
  //       title: "EDIT DELETE SEO EXAM",
  //       path: "editDeleteSEOExam",
  //       cName: "sub-nav",
  //     },
  //   ],
  // },
  // {
  //   title: "SEO SUB EXAM NAME",
  //   subNav: [
  //     {
  //       title: "ADD SEO SUB EXAM NAME",
  //       path: "addSEOSubExamName",
  //       cName: "sub-nav",
  //     },
  //     {
  //       title: "EDIT DELETE SEO SUB EXAM",
  //       path: "editDeleteSEOSubExam",
  //       cName: "sub-nav",
  //     },
  //   ],
  // },
  // {
  //   title: "SEO EXAM STREAM",
  //   subNav: [
  //     {
  //       title: "ADD SEO EXAM STREAM",
  //       path: "addSEOExamStream",
  //       cName: "sub-nav",
  //     },
  //     {
  //       title: "EDIT DELETE SEO EXAM STREAM",
  //       path: "editDeleteSEOExamStream",
  //       cName: "sub-nav",
  //     },
  //   ],
  // },
  {
    title: "SEO EXAM",
    link: "addEditSEOExam",
  },
  {
    title: "SEO SUB EXAM",
    link: "addEditSEOSubExam",
  },
  {
    title: "SEO EXAM STREAM",
    link: "addEditSEOExamStream",
  },
  // {
  //   title: "SEO EXAM NAME",
  //   link: "addSEOExamName",
  // },
  // {
  //   title: "SEO SUB EXAM NAME",
  //   link: "addSEOSubExamName",
  // },
  // {
  //   title: "SEO EXAM STREAM",
  //   link: "addSEOExamStream",
  // },
  {
    title: "SEO COURSE",
    subNav: [
      {
        title: "ADD SEO COURSE",
        path: "addSEOCourse",
        cName: "sub-nav",
      },
      {
        title: "EDIT DELETE SEO COURSE",
        path: "editDeleteSEOCourse",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "COURSE FAQ",
    subNav: [
      {
        title: "ADD FAQ",
        path: "addCourseFAQ",
        cName: "sub-nav",
      },
      {
        title: "EDIT DELETE FAQ",
        path: "editDeleteCourseFAQ",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "EXAM FAQ",
    subNav: [
      {
        title: "ADD EXAM FAQ",
        path: "addExamFAQ",
        cName: "sub-nav",
      },
      {
        title: "EDIT DELETE EXAM FAQ",
        path: "editDeleteExamFAQ",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "EXAM STREAM FAQ",
    subNav: [
      {
        title: "ADD EXAM STREAM FAQ",
        path: "addExamStreamFAQ",
        cName: "sub-nav",
      },
      {
        title: "EDIT DELETE EXAM STREAM FAQ",
        path: "editDeleteExamStreamFAQ",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "PRODUCT SCHEMA",
    subNav: [
      {
        title: "ADD PRODUCT SCHEMA",
        path: "addProductSchema",
        cName: "sub-nav",
      },
      {
        title: "EDIT PRODUCT SCHEMA",
        path: "editDeleteProductSchema",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "EXAM DETAILS",
    subNav: [
      {
        title: "ADD EXAM DETAILS",
        path: "AddExamDetails",
        cName: "sub-nav",
      },
      {
        title: "EDIT EXAM DETAILS",
        path: "EditDeleteExamDetails",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "AUTHOR DETAILS",
    subNav: [
      {
        title: "ADD AUTHOR DETAILS",
        path: "addAuthorDetails",
        cName: "sub-nav",
      },
      {
        title: "EDIT AUTHOR DETAILS",
        path: "editDeleteAuthorDetails",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "RESOURCE PAGE DETAILS",
    link: "resourcePageDetails",
  },
  // {
  //   title: "SEO RESOURSES",
  //   link: "addresourcesseo",
  // },
  // {
  //   title: "RESOURCE DETAILS",
  //   link: "resourceDetail",
  // },
  {
    title: "RESOURCE PAGE PRODUCT",
    link: "resourcePageProduct",
  },
  // {
  //   title: "FAQ OF RESOURCE PAGE",
  //   subNav: [
  //     {
  //       title: "ADD FAQ OF RESOURCE PAGE",
  //       path: "addResourcesFAQ",
  //       cName: "sub-nav",
  //     },
  //     {
  //       title: "EDIT DELETE ",
  //       path: "editDeleteResourcesFAQ",
  //       cName: "sub-nav",
  //     },
  //   ],
  // },
  {
    title: "BLOGS",
    subNav: [
      {
        title: "ADD",
        path: "addSEOBlogs",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editDeleteSEOBlogs",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "EXAM DETAILS DASHBOARD",
    subNav: [
      {
        title: "EXAM SYLLABUS",
        subNav: [
          {
            title: "CREATE",
            path: "createExamSyllabus",
            cName: "sub-nav",
          },
          {
            title: "EDIT / DELETE",
            path: "editDeleteExamSyllabus",
            cName: "sub-nav",
          },
        ],
      },
      {
        title: "ALL ABOUT EXAM",
        path: "allAboutExam",
        cName: "sub-nav",
      },

      {
        title: "EXAM DATES",
        path: "createExamDates",
        cName: "sub-nav",
      },
      {
        title: "PYQ PDF",
        subNav: [
          {
            title: "CREATE",
            path: "createpyqpdf",
            cName: "sub-nav",
          },
          {
            title: "EDIT / DELETE",
            path: "editdeletepyqpdf",
            cName: "sub-nav",
          },
        ],
      },
      {
        title: "EXAM PAPER ANALYSIS",
        path: "createExamPaperAnalysis",
        cName: "sub-nav",
      },
      {
        title: "CUT-OFF",
        path: "cutOff",
        cName: "sub-nav",
      },
      {
        title: "PATTERN",
        subNav: [
          {
            title: "CREATE",
            path: "createpattern",
            cName: "sub-nav",
          },
          {
            title: "EDIT / DELETE",
            path: "editdeletepattern",
            cName: "sub-nav",
          },
        ],
      },
    ],
  },
  // {
  //   title: "ONLINE COACHING",
  //   link: "createOnlineCoaching",
  // },

  // {
  //   title: "ELIGIBILITY",
  //   link: "createEligibility",
  // },

  // {
  //   title: "APPLICATION FEES",
  //   link: "createApplicationFees",
  // },

  // {
  //   title: "ADMIT CARD",
  //   link: "createAdmitCard",
  // },
  {
    title: "MOVEMENTS OF MEETING",
    subNav: [
      {
        title: "MOVEMENTS OF MEETING",
        path: "movementsOfMeeting",
        cName: "sub-nav",
      },
      {
        title: "EDIT/DELETE",
        path: "editMovementsOfMeeting",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "CONTACT US",
    link: "contact-us",
  },
  {
    title: "OLD URL REDIRECTION",
    link: "old-url-redirection",
  },
];
