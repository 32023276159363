export const DigitalMarketingManagerData = [
  {
    title: "PAID ADS",
    link: "paidAds",
  },
  {
    title: "SOCIAL MEDIA ADS",
    link: "socialAds",
  },
  {
    title: "LEAD COUNT",
    link: "leadCountDigital",
  },
  {
    title: "YOUTUBE",
    subNav: [
      {
        title: "YOUTUBE PERFORMANCE",
        path: "youtubePerformance",
        cName: "sub-nav",
      },
      {
        title: "OFF TOPIC PERFORMANCE",
        path: "offTopicPerformance",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "TARGET CREATION",
    subNav: [
      {
        title: "CREATE TARGET",
        path: "createDigitalTarget",
        cName: "sub-nav",
      },
      {
        title: "TARGET STATUS",
        path: "targetStatusDigital",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "PERFORMANCE",
    subNav: [
      {
        title: "Social Media Team",
        path: "socialMediaTeam",
        cName: "sub-nav",
      },
      {
        title: "Youtube Target",
        path: "youtubeTarget",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "LEAD ANALYSIS SECTION",
    link: "digitalLeadAnalysis",
  },
  {
    title: "FACEBOOK GROUP",
    link: "facebookGroup",
  },
  {
    title: "SHOPIFY PAYMENT",
    link: "shopifyPayment",
  },
];

export const DigitalMarketingEmpData = [
  {
    title: "EMPLOYEE DETAILS",
    subNav: [
      {
        title: "Dashboard",
        path: "dashboard",
        cName: "sub-nav",
      },
      {
        title: "My Profile",
        path: "userProfile",
        cName: "sub-nav",
      },
      {
        title: "Policies",
        path: "policy",
        cName: "sub-nav",
      },
      {
        title: "KRA/KPI",
        path: "kraKpi",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "PAID ADS",
    link: "paidAds",
  },
  {
    title: "SOCIAL MEDIA ADS",
    link: "socialAds",
  },
  {
    title: "LEAD COUNT",
    link: "leadCountDigital",
  },
  {
    title: "YOUTUBE",
    subNav: [
      {
        title: "YOUTUBE PERFORMANCE",
        path: "youtubePerformance",
        cName: "sub-nav",
      },
      {
        title: "OFF TOPIC PERFORMANCE",
        path: "offTopicPerformance",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "TARGET CREATION",
    subNav: [
      {
        title: "CREATE TARGET",
        path: "createDigitalTarget",
        cName: "sub-nav",
      },
      {
        title: "TARGET STATUS",
        path: "targetStatusDigital",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "PERFORMANCE",
    subNav: [
      {
        title: "Social Media Team",
        path: "socialMediaTeam",
        cName: "sub-nav",
      },
      {
        title: "Youtube Target",
        path: "youtubeTarget",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "LEAD ANALYSIS SECTION",
    link: "digitalLeadAnalysis",
  },
  {
    title: "MOVEMENTS OF MEETING",
    subNav: [
      {
        title: "MOVEMENTS OF MEETING",
        path: "movementsOfMeeting",
        cName: "sub-nav",
      },
      {
        title: "EDIT/DELETE",
        path: "editMovementsOfMeeting",
        cName: "sub-nav",
      },
    ],
  },
];
