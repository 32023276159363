import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PageTitle from "../../../Module/pageTitle";
import LabelField from "../../../Component/labelField";
import DatePicker from "../../../Component/datePicker";
import AdminTable from "../../../Component/adminTable";
import moment from "moment";
import {
  getDamagedBook,
  getExamListNew,
  getParentCourseNew,
} from "../../../Action/courseAction";
import Dropdown from "../../../Component/dropdown";
import { getSubExam } from "../../../Action/adminPanelAction";

function DamageStock() {
  const dispatch = useDispatch();
  const [parentCourseVal, setparentCourseVal] = useState("");
  const [parentCourseList, setParentCourseList] = useState([]);
  const [examList, setExamList] = useState([]);
  const [subExamList, setsubExamList] = useState([]);
  const [examName, setExamName] = useState("");
  const [subExamName, setSubExamName] = useState("");
  const [startDate, setStartDate] = useState(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [damageStockData, setDamageStockData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    getStock();
    dispatch(
      getExamListNew((res) => {
        setExamList(res);
      })
    );
  }, []);
  const getStock = () => {
    const PostData = {
      startDate: startDate,
      endDate: endDate,
    };
    if (parentCourseVal) {
      PostData["baseCourseId"] = parentCourseVal._id;
    }
    if (examName) {
      PostData["examTypeId"] = examName._id;
    }
    if (subExamName) {
      PostData["subExamTypeId"] = subExamName._id;
    }
    dispatch(
      getDamagedBook(PostData, (res) => {
        setDamageStockData(res);
        let count = 0;
        res?.map((item) => {
          count = count + item?.quantity;
        });
        setTotalCount(count);
      })
    );
  };
  const changeParentCourseVal = (value) => {
    setparentCourseVal(value);
  };

  const onListChange = (value) => {
    setExamName(value);
    dispatch(
      getSubExam(
        {
          examTypeId: value._id,
        },
        (res) => {
          if (res.data.length > 0) setsubExamList(res.data);
          else
            dispatch(
              getParentCourseNew(`${value._id}`, (res) => {
                setParentCourseList(res);
              })
            );
        }
      )
    );
  };
  const onChangeSubExam = (value) => {
    setSubExamName(value);
    dispatch(
      getParentCourseNew(`${examName._id}/${value._id}`, (res) => {
        setParentCourseList(res);
      })
    );
  };

  const HandleSubmit = (e) => {
    e.preventDefault();
    getStock();
  };

  return (
    <div>
      <div className="contentBox">
        <PageTitle name="DAMAGE STOCK" />
        <div className="formDiv">
          <div className="col-12 formWidth">
            <form onSubmit={HandleSubmit}>
              <div className="row mx-0">
                <div className="col-md-4 d-flex align-items-center">
                  <div className="col-md-3">
                    <LabelField labelName={"Start Date"} />
                  </div>
                  <DatePicker
                    setInputText={(value) => {
                      setStartDate(value);
                    }}
                    value={moment.utc(startDate).format("yyyy-MM-DD")}
                    defaultValue={moment.utc(startDate).format("yyyy-MM-DD")}
                  />
                </div>
                <div className="col-md-4 d-flex align-items-center">
                  <div className="col-md-3">
                    <LabelField labelName={"End Date"} />
                  </div>
                  <DatePicker
                    setInputText={(value) => {
                      setEndDate(value);
                    }}
                    value={moment.utc(endDate).format("yyyy-MM-DD")}
                    defaultValue={moment.utc(endDate).format("yyyy-MM-DD")}
                  />
                </div>
                <div className="col-md-4 d-flex align-items-center">
                  <div className="col-md-3">
                    <LabelField labelName={"Exam Name"} />
                  </div>
                  <Dropdown
                    labelName={"select Exam Name"}
                    setInputText={(value) => onListChange(value)}
                    value={examName}
                    options={examList}
                  />
                </div>
                {subExamList?.length > 0 ? (
                  <div className="col-md-4 d-flex align-items-center">
                    <div className="col-md-3">
                      <LabelField labelName={"Sub Exam Name"} />
                    </div>
                    <Dropdown
                      labelName="Select Sub Exam Name"
                      setInputText={(value) => onChangeSubExam(value)}
                      value={subExamName}
                      options={subExamList}
                    />
                  </div>
                ) : null}
                <div className="col-md-4 d-flex align-items-center">
                  <div className="col-md-3">
                    <LabelField labelName={"Exam Stream"} />
                  </div>
                  <Dropdown
                    labelName={"Select Exam Stream"}
                    setInputText={(value) => changeParentCourseVal(value)}
                    value={parentCourseVal}
                    options={parentCourseList}
                    Isrequired={true}
                  />
                </div>
                <div className=" col-md-4">
                  <div className=" d-flex align-items-center">
                    <button
                      type="submit"
                      className={"btn mb-3 submitbtn  btn-primary"}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <div className="d-flex justify-content-end me-2">
              <h5 className="fw-bold">Total Damaged Books: {totalCount}</h5>
            </div>
            <AdminTable
              tableData={damageStockData.length > 0 ? damageStockData : []}
              pagename={"damageStock"}
              tableHead={[
                "S.N",
                "BOOK NAME",
                "DATE & TIME",
                "LAST BOOK COUNT",
                "CURRENT STOCK",
                "DAMAGED STOCK",
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DamageStock;
