import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router";
import Loading from "../../Component/Loading";

// Lazy-loaded components
const Dashboard = lazy(() => import("./dashboard"));
const UserProfile = lazy(() => import("./userProfile"));
const Policy = lazy(() => import("./policy"));
const KraKpi = lazy(() => import("./krakpi"));
const Assets = lazy(() => import("./assets")); 

const CommonRoutes = ({ loginType, defaultPage, children }) => (
  <Suspense
    fallback={
      <div className="border p-1">
        <Loading />
      </div>
    }
  >
    <Routes>
      {loginType === 2 ? (
        <Route path="/" element={<Dashboard />} />
      ) : (
        <Route path="/" element={defaultPage} />
      )}
      <Route path="dashboard" element={<Dashboard />} />
      <Route path="userProfile" element={<UserProfile />} />
      <Route path="policy" element={<Policy />} />
      <Route path="kraKpi" element={<KraKpi />} />
      <Route path="assets" element={<Assets/>}/>
      {children}
    </Routes>
  </Suspense>
);

export default CommonRoutes;
