import React, { Suspense, lazy } from "react";
import Sidebar from "../../Module/sidebar";
import {
  ResultPanelEmpSidebarData,
  ResultPanelSidebarData,
} from "../../Module/resultPanelSidebarData";
import Loading from "../../Component/Loading";
import { Route, Routes } from "react-router";
import { useSelector } from "react-redux";
import CommonRoutes from "../Common/commonRoute";

const StudentResult = lazy(() => import("../CategoryPanel/studentResult"));
const ResultCompilation = lazy(() =>
  import("../CategoryPanel/resultCompilation")
);
const ImportResult = lazy(() => import("./importResult"));

const ResultPanelDashboard = () => {
  const { loginType } = useSelector((state) => ({
    designation: state.Auth?.userInfo?.designation,
    loginType: state.Auth?.loginType,
  }));
  console.log("loginType", loginType);
  return (
    <div className="AppBox">
      <Sidebar
        data={
          loginType === 2 ? ResultPanelEmpSidebarData : ResultPanelSidebarData
        }
      />
      <div className="dashboard">
        {
          <CommonRoutes
            loginType={loginType}
            defaultPage={<ResultCompilation />}
          >
            <Route path="resultCompilation" element={<ResultCompilation />} />
            <Route path="studentResult" element={<StudentResult />} />
            <Route path="importResult" element={<ImportResult />} />
          </CommonRoutes>
        }
      </div>
    </div>
  );
};
export default ResultPanelDashboard;
