import "../../App.css";
import React, { lazy } from "react";
import Sidebar from "../../Module/sidebar";
import { Route } from "react-router-dom";
import Chat from "../TeacherPanel/chat";
import {
  VpOperationsEmpSidebarData,
  VpOperationsSidebarData,
} from "../../Module/vpOperationsSidebarData";
import { useSelector } from "react-redux";
import CommonRoutes from "../Common/commonRoute";

const TechnicalSupport = lazy(() => import("./technicalSupport"));
const CxoBatchwiseCount = lazy(() => import("../CXOPanel/cxoBatchWiseCount"));
const DatewiseCount = lazy(() => import("../Finance/datewiseCount"));
const RevenueDetails = lazy(() => import("../Finance/revenueDetails"));
const DoubtSoleve = lazy(() => import("./doubtSolveReview"));
const TransactionEdutech = lazy(() => import("../CXOPanel/transactionEdutech"));
const TransactionEdutechStudyMaterial = lazy(() =>
  import("../CXOPanel/transactionEdutechStudyMaterial")
);

const RegistrationDetails = lazy(() =>
  import("../TechnicalSupportPanel/registrationDetails")
);
const OldPackageExist = lazy(() => import("../CXOPanel/oldPackageExists"));
const YoutubeDuplicateUrl = lazy(() => import("./youtubeDuplicateUrl"));
const MovementsOfMeeting = lazy(() =>
  import("../CategoryPanel/MovementsOfMeeting/movementsOfMeeting")
);
const EditMovementsOfMeeting = lazy(() =>
  import("../CategoryPanel/MovementsOfMeeting/editMovementsOfMeeting")
);
const InfluencerPayment = lazy(() =>
  import("../InfluencerPanel/Payment/InfluencerPaymentCommon")
);

function VpOperationsPanelDashboard() {
  const { loginType } = useSelector((state) => ({
    designation: state.Auth?.userInfo?.designation,
    loginType: state.Auth?.loginType,
  }));
  console.log("loginType", loginType);
  return (
    <div className="AppBox">
      <Sidebar
        data={
          loginType === 2 ? VpOperationsEmpSidebarData : VpOperationsSidebarData
        }
      />
      <div className="dashboard">
        {
          <CommonRoutes
            loginType={loginType}
            defaultPage={<TechnicalSupport />}
          >
            <Route path="technicalSupport" element={<TechnicalSupport />} />
            <Route path="/Chat" element={<Chat />} />
            <Route path="cxobatchwiseCount" element={<CxoBatchwiseCount />} />
            <Route path="datewiseCount" element={<DatewiseCount />} />
            <Route path="revenueDetails" element={<RevenueDetails />} />
            <Route path="doubtsolvereview" element={<DoubtSoleve />} />
            <Route path="transactionEdutech" element={<TransactionEdutech />} />
            <Route
              path="transactionEdutechStudyMaterial"
              element={<TransactionEdutechStudyMaterial />}
            />
            <Route
              path="registrationDetails"
              element={<RegistrationDetails />}
            />
            <Route path="oldPackageExist" element={<OldPackageExist />} />
            <Route
              path="youtubeDuplicateUrl"
              element={<YoutubeDuplicateUrl />}
            />
            <Route path="movementsOfMeeting" element={<MovementsOfMeeting />} />
            <Route
              path="editMovementsOfMeeting"
              element={<EditMovementsOfMeeting />}
            />
            <Route
              path="influencerPayment"
              element={<InfluencerPayment type={2} />}
            />
          </CommonRoutes>
        }
      </div>
    </div>
  );
}

export default VpOperationsPanelDashboard;
