import "../../App.css";
import React, { Suspense } from "react";
import Sidebar from "../../Module/sidebar";
import { Route, Routes } from "react-router-dom";
import Loading from "../../Component/Loading";
import {
  HrOperationEmpPanelSidebarData,
  HrOperationPanelSidebarData,
} from "../../Module/hrOperationPanelSidebarData";
import { useSelector } from "react-redux";
import CommonRoutes from "../Common/commonRoute";

function HrOperationDashboard() {
  const { loginType } = useSelector((state) => ({
    designation: state.Auth?.userInfo?.designation,
    loginType: state.Auth?.loginType,
  }));
  return (
    <div className="AppBox">
      <Sidebar
        data={
          loginType === 2
            ? HrOperationEmpPanelSidebarData
            : HrOperationPanelSidebarData
        }
      />
      <div className="dashboard">
        {
          <CommonRoutes loginType={loginType} defaultPage={""}>
            <Route path="/" />
          </CommonRoutes>
        }
      </div>
    </div>
  );
}

export default HrOperationDashboard;
