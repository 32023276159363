export const TechSupportSidebarData = [
  {
    title: "TECHNICAL SUPPORT",
    link: "technicalsupport",
  },
  {
    title: "REGISTRATION DETAILS",
    link: "registrationdetails",
  },
  {
    title: "DOUBT SOLVE REVIEW",
    link: "doubtsolvereview",
  },
  // {
  //   title: "TEACHER SUPPORT",
  //   link: "teachersupport",
  // },
  {
    title: "MULTI-DEVICE TOKEN CLEAR",
    link: "MultiDeviceTokenClear",
  },
  {
    title: "LIVE CLASS SCHEDULE",
    link: "liveclassschedule",
  },
  {
    title: "USER DETAILS",
    link: "userDetails",
  },
  {
    title: "CALL TO SUPPORT REQUEST LEADS",
    link: "expertsupport",
  },
  {
    title: "TRANSACTION DETAILS",
    subNav: [
      {
        title: "TRANSACTION EDUTECH",
        path: "transactionedutech",
        cName: "sub-nav",
      },
      {
        title: "TRANSACTION EDUTECH STUDYMATERIAL",
        path: "transactionEdutechStudyMaterial",
        cName: "sub-nav",
      },
    ],
  },
];

export const TechSupportEmpSidebarData = [
  {
    title: "EMPLOYEE DETAILS",
    subNav: [
      {
        title: "Dashboard",
        path: "dashboard",
        cName: "sub-nav",
      },
      {
        title: "My Profile",
        path: "userProfile",
        cName: "sub-nav",
      },
      {
        title: "Policies",
        path: "policy",
        cName: "sub-nav",
      },
      {
        title: "KRA/KPI",
        path: "kraKpi",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "TECHNICAL SUPPORT",
    link: "technicalsupport",
  },
  {
    title: "REGISTRATION DETAILS",
    link: "registrationdetails",
  },
  {
    title: "DOUBT SOLVE REVIEW",
    link: "doubtsolvereview",
  },
  // {
  //   title: "TEACHER SUPPORT",
  //   link: "teachersupport",
  // },
  {
    title: "MULTI-DEVICE TOKEN CLEAR",
    link: "MultiDeviceTokenClear",
  },
  {
    title: "LIVE CLASS SCHEDULE",
    link: "liveclassschedule",
  },
  {
    title: "USER DETAILS",
    link: "userDetails",
  },
  {
    title: "CALL TO SUPPORT REQUEST LEADS",
    link: "expertsupport",
  },
  {
    title: "TRANSACTION DETAILS",
    subNav: [
      {
        title: "TRANSACTION EDUTECH",
        path: "transactionedutech",
        cName: "sub-nav",
      },
      {
        title: "TRANSACTION EDUTECH STUDYMATERIAL",
        path: "transactionEdutechStudyMaterial",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "MOVEMENTS OF MEETING",
    subNav: [
      {
        title: "MOVEMENTS OF MEETING",
        path: "movementsOfMeeting",
        cName: "sub-nav",
      },
      {
        title: "EDIT/DELETE",
        path: "editMovementsOfMeeting",
        cName: "sub-nav",
      },
    ],
  },
];
