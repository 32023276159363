/* eslint-disable react/prop-types */
import React from "react";
import "./sidebar.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setParentCourse } from "../Action/courseAction";
import SidebarModule from "./sidebarModule";
import PersonIcon from "@mui/icons-material/Person";
import { Logout, setInitialState } from "../Action/authAction";
import { showSuccessSnackbar } from "../Action/snackbarAction";
import logout from "../Images/logout.png";
function Sidebar(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentURLarr = useLocation().pathname.split("/");
  let link = currentURLarr[currentURLarr.length - 1];

  const { globalEditMode, isFirstTimeLoggedIn, sidebarTitle, userInfo } =
    useSelector((state) => ({
      globalEditMode: state.courseReducer?.isEditMode,
      isFirstTimeLoggedIn: state.courseReducer?.isFirstTimeLoggedIn,
      sidebarTitle: state.courseReducer?.sidebarTitle,
      userInfo: state.Auth?.userInfo, 
    }));
  if (link === "Course-dashboard") link = "createParentCourse";

  const handleResetEditMode = (value) => {
    if (globalEditMode) {
      dispatch(setParentCourse("isEditMode", false));
    }
    dispatch(setParentCourse("isFirstTimeLoggedIn", false));
    dispatch(setParentCourse("sidebarTitle", value));
  };
  const signOut = () => {
    dispatch(
      Logout(
        () => {
          dispatch(setInitialState());
          navigate("/");
          dispatch(showSuccessSnackbar("Logout Succesfully"));
        },
        () => {}
      )
    );
  };
  return (
    <div className="sidebar">
      <div className="userInfo d-flex align-items-center">
        <span className="me-3">
          <PersonIcon />
        </span>
        {userInfo?.name}
      </div>
      <div className="overview">Overview</div>
      <ul className="sidebarList">
        <div className="accordion" id="accordionExample">
          {props.data.map((val, key) => {
            if (val.subNav === undefined) {
              return (
                <li
                  key={key}
                  id={key}
                  className={
                    val.link === link || (isFirstTimeLoggedIn && key === 0)
                      ? "sideListRow active text-capitalize"
                      : "sideListRow text-capitalize"
                  }
                >
                  <Link to={val.link}>
                    {val.title.includes("CRM")
                      ? val.title
                      : val.title.toLowerCase()}
                  </Link>
                  <span></span>
                </li>
              );
            } else {
              return (
                <div className="accordion-item rounded-0 border-0" key={key}>
                  <h2 className="accordion-header " id={"heading" + key}>
                    <button
                      className={`accordion-button text-capitalize ${
                        (isFirstTimeLoggedIn && key === 0) ||
                        val.title === sidebarTitle
                          ? ""
                          : "collapsed"
                      }`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={"#collapse" + key}
                      aria-expanded="false"
                      aria-controls={"collapse" + key}
                    >
                      {val.title.includes("CRM")
                        ? val.title
                        : val.title.toLowerCase()}
                    </button>
                  </h2>
                  <div
                    id={"collapse" + key}
                    className={`accordion-collapse collapse ${
                      (isFirstTimeLoggedIn && key === 0) ||
                      val.title === sidebarTitle
                        ? "show"
                        : ""
                    }`}
                    aria-labelledby={"heading" + key}
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body p-0 border-bottom">
                      <SidebarModule
                        data={val?.subNav}
                        title={val.title.toLowerCase()}
                        keyval={key}
                        handleResetEditMode={() =>
                          handleResetEditMode(val.title)
                        }
                      />
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </div>
      </ul>
      <div
        className="logout border-top cursor d-flex align-items-center"
        onClick={signOut}
      >
        <span className="me-3">
          <img src={logout} alt="logout" width={"25"} />
        </span>
        Logout
      </div>
    </div>
  );
}

export default Sidebar;
