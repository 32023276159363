import React from 'react'
import { useSelector } from 'react-redux';
import CommonRoutes from '../Common/commonRoute';
import { EmployeePanelEmpSidebarData } from "../../Module/EmployeePanelSidebarData";
import Sidebar from '../../Module/sidebar';
import { Route } from 'react-router';
import MovementsOfMeeting from './Movements of Meeting/movementsOfMeeting';
import EditMovementsOfMeeting from './Movements of Meeting/editMovementsOfMeeting';

function EmployeeDashboard() {
      const { loginType } = useSelector((state) => ({
        designation: state.Auth?.userInfo?.designation,
        loginType: state.Auth?.loginType,
      }));
    
  return (
    <div className="AppBox">
      <Sidebar
        data={
          loginType === 2
            ? EmployeePanelEmpSidebarData
            : null
        }
      />
        <div className="dashboard">
        {
          <CommonRoutes loginType={loginType}>
            <Route path="movementsOfMeeting" element={<MovementsOfMeeting />} />
            <Route
              path="editMovementsOfMeeting"
              element={<EditMovementsOfMeeting />}
            />            
          </CommonRoutes>
        }
        </div>
      
    </div>
  )
}

export default EmployeeDashboard;
