export const upSellingPanelSidebarData = [
  {
    title: "UPCOMING EXPIRY",
    link: "upcomingexpiry",
  },
  {
    title: "EXPIRED ACCOUNT",
    link: "expiredaccount",
  },
];
export const upSellingEmpPanelSidebarData = [
  {
    title: "EMPLOYEE DETAILS",
    subNav: [
      {
        title: "Dashboard",
        path: "dashboard",
        cName: "sub-nav",
      },
      {
        title: "My Profile",
        path: "userProfile",
        cName: "sub-nav",
      },
      {
        title: "Policies",
        path: "policy",
        cName: "sub-nav",
      },
      {
        title: "KRA/KPI",
        path: "kraKpi",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "UPCOMING EXPIRY",
    link: "upcomingexpiry",
  },
  {
    title: "EXPIRED ACCOUNT",
    link: "expiredaccount",
  },
  {
    title: "MOVEMENTS OF MEETING",
    subNav: [
      {
        title: "MOVEMENTS OF MEETING",
        path: "movementsOfMeeting",
        cName: "sub-nav",
      },
      {
        title: "EDIT/DELETE",
        path: "editMovementsOfMeeting",
        cName: "sub-nav",
      },
    ],
  },
];
