export const InfluencerPanelSidebarData = [
  {
    title: "DASHBOARD",
    link: "dashboard",
  },
  {
    title: "REVENUE ANALYSIS",
    link: "revenueAnalysis",
  },
  {
    title: "TOTAL REVENUE",
    link: "totalRevenue",
  },
  {
    title: "LEAD ORDERS",
    link: "leadOrder",
  },
  {
    title: "INFLUENCER RANK",
    link: "influencerRank",
  },
  {
    title: "PAYMENT",
    link: "influencerPayment",
  },
];

export const InfluencerEmpPanelSidebarData = [
  {
    title: "DASHBOARD",
    link: "dashboard",
  },
  {
    title: "REVENUE ANALYSIS",
    link: "revenueAnalysis",
  },
  {
    title: "TOTAL REVENUE",
    link: "totalRevenue",
  },
  {
    title: "LEAD ORDERS",
    link: "leadOrder",
  },
  {
    title: "INFLUENCER RANK",
    link: "influencerRank",
  },
  {
    title: "MOVEMENTS OF MEETING",
    subNav: [
      {
        title: "MOVEMENTS OF MEETING",
        path: "movementsOfMeeting",
        cName: "sub-nav",
      },
      {
        title: "EDIT/DELETE",
        path: "editMovementsOfMeeting",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "GUIDELINES",
    link: "guidelines",
  },
  {
    title: "AGREEMENT",
    link: "agreement",
  },
  {
    title: "PAYMENT",
    link: "influencerPayment",
  },
];
