export const complexLevel = [
  {
    _id: 1,
    name: "High",
  },
  {
    _id: 2,
    name: "Medium",
  },
  {
    _id: 3,
    name: "Low",
  },
  {
    _id: 4,
    name: "Complex",
  },
];

export const inputTypeText = {
  descType: "text",
  description: "",
};

export const inputTypeImage = {
  descType: "img",
  description: "",
};

export const inputTypeVideo = {
  descType: "video",
  description: "",
};

export const optionTypeText = {
  descType: "text",
  description: "",
  isCorrect: false,
};
export const optionTypeImage = {
  descType: "img",
  description: "",
  isCorrect: false,
};

export const examTypesList = [
  {
    _id: 1,
    name: "Unit Test",
  },
  {
    _id: 2,
    name: "Mock Test",
  },
  {
    _id: 3,
    name: "TOPIC TEST",
  },
  {
    _id: 4,
    name: "PYQ",
  },
  {
    _id: 5,
    name: "Scholarship Test",
  },
  {
    _id: 6,
    name: "Sunday Batch Test",
  },
];

export const examTypes = [
  {
    _id: "1",
    name: "Unit Test",
  },
  {
    _id: "2",
    name: "Mock Test",
  },
  {
    _id: "3",
    name: "TOPIC TEST",
  },
  {
    _id: "4",
    name: "PYQ",
  },
];

export const examTypesFree = [
  {
    _id: "1",
    name: "Unit Test",
  },
  {
    _id: "2",
    name: "Mock Test",
  },
  {
    _id: "3",
    name: "TOPIC TEST",
  },
  {
    _id: "4",
    name: "PYQ",
  },
  {
    _id: "5",
    name: "Scholarship Test",
  },
];

// For Dropdown Default State
export const defaultVal = {
  _id: "",
  name: "Please Select",
};

export const DecimalList = [
  {
    name: "N/A",
    _id: 0,
  },
  {
    name: "One Decimal",
    _id: 1,
  },
  {
    name: "Two Decimal",
    _id: 2,
  },
  {
    name: "Three Decimal",
    _id: 3,
  },
];

export const initialVal = {
  subCategoryId: "",
  booksPackageId: "",
  bookList: [],
  subscriptionPlan: [
    {
      durationId: "",
      months: 0,
      price: 0,
      isRecommended: false,
    },
  ],
  isOffer: false,
  offer: "",
};

export const initialValSM = {
  subCategoryId: "",
  booksPackageId: "",
  bookListSM: [],
  packagebooksSellType: 0,
  books: [
    {
      bannerImage: "",
      price: 0,
      sellPrice: 0,
      name: "",
      booksList: [""],
      bookId: "",
    },
  ],
  comboArray: [
    {
      bannerImage: null,
      price: 0,
      sellPrice: 0,
      name: "",
      booksList: [],
    },
  ],
  isOffer: false,
  offer: "",
};

export const porderData = [
  {
    _id: "1",
    name: 1,
  },
  {
    _id: "2",
    name: 2,
  },
  {
    _id: "3",
    name: 3,
  },
  {
    _id: "4",
    name: 4,
  },
  {
    _id: "5",
    name: 5,
  },
  {
    _id: "6",
    name: 6,
  },
  {
    _id: "7",
    name: 7,
  },
  {
    _id: "8",
    name: 8,
  },
  {
    _id: "9",
    name: 9,
  },
  {
    _id: "10",
    name: 10,
  },
  {
    _id: "11",
    name: 11,
  },
  {
    _id: "12",
    name: 12,
  },
  {
    _id: "13",
    name: 13,
  },
  {
    _id: "14",
    name: 14,
  },
  {
    _id: "15",
    name: 15,
  },
  {
    _id: "16",
    name: 16,
  },
  {
    _id: "17",
    name: 17,
  },
  {
    _id: "18",
    name: 18,
  },
  {
    _id: "19",
    name: 19,
  },
  {
    _id: "20",
    name: 20,
  },
];
export const data = [
  {
    name: "Yes",
    _id: true,
  },
  {
    name: "No",
    _id: false,
  },
];

export const statusActive = [
  {
    name: "Yes",
    _id: 1,
  },
  {
    name: "No",
    _id: 0,
  },
];

export const defaultSubscription = {
  subCategoryId: "",
  booksPackageId: "",
  bookList: [],
  subscriptionPlan: [
    {
      durationId: "",
      months: 0,
      price: 0,
      isRecommended: false,
    },
  ],
};

export const defaultStudyMaterial = {
  subCategoryId: "",
  booksPackageId: "",
  bookListSM: [],
  packagebooksSellType: 0,
  books: [
    {
      bannerImage: "",
      price: 0,
      sellPrice: 0,
      name: "",
      booksList: [""],
      bookId: "",
    },
  ],
  comboArray: [
    {
      bannerImage: null,
      price: 0,
      sellPrice: 0,
      name: "",
      booksList: [],
    },
  ],
};

export const lectureTypeList = [
  {
    _id: 1,
    name: "Live lecture",
  },
  {
    _id: 2,
    name: "Recorded lecture",
  },
  {
    _id: 3,
    name: "Test series",
  },
  {
    _id: 4,
    name: "Book",
  },
  {
    _id: 5,
    name: "Youtube Live",
  },
];

export const categoryList = [
  {
    _id: 1,
    name: "Batch Support",
  },
  // {
  //   _id: 2,
  //   name: "General Support",
  // },
  {
    _id: 3,
    name: "Infra Support",
  },
];

export const SMCategoryList = [
  {
    _id: 1,
    name: "ONLINE CLASS",
  },
  {
    _id: 2,
    name: "STUDY MATERIAL",
  },
];

export const ShipmentStatusList = [
  {
    _id: 0,
    name: "Pending",
  },
  {
    _id: 1,
    name: "Partial Dispatched",
  },
  {
    _id: 2,
    name: "Complete Dispatched",
  },
  {
    _id: 3,
    name: "Returned",
  },
  {
    _id: 4,
    name: "Delievered",
  },
];

export const trackingStatusList = [
  {
    _id: 0,
    name: "Pending",
  },
  {
    _id: 1,
    name: "Partial Dispatched",
  },
  {
    _id: 2,
    name: "Complete Dispatched",
  },
  {
    _id: 3,
    name: "Returned",
  },
  {
    _id: 4,
    name: "Delievered",
  },
];

export const BookStatusList = [
  {
    _id: 1,
    name: "All",
  },
  {
    _id: 2,
    name: "Available",
  },
  {
    _id: 3,
    name: "Out Of Stock",
  },
];

export const monthData = [
  {
    _id: 1,
    value: 1,
    name: "January",
  },
  {
    _id: 2,
    value: 2,
    name: "February",
  },
  {
    _id: 3,
    value: 3,
    name: "March",
  },
  {
    _id: 4,
    value: 4,
    name: "April",
  },
  {
    _id: 5,
    value: 5,
    name: "May",
  },
  {
    _id: 6,
    value: 6,
    name: "June",
  },
  {
    _id: 7,
    value: 7,
    name: "July",
  },
  {
    _id: 8,
    value: 8,
    name: "August",
  },
  {
    _id: 9,
    value: 9,
    name: "September",
  },
  {
    _id: 10,
    value: 10,
    name: "October",
  },
  {
    _id: 11,
    value: 11,
    name: "November",
  },
  {
    _id: 12,
    value: 12,
    name: "December",
  },
];

export const yearData = [
  {
    _id: 2025,
    value: 2025,
    name: "2025",
  },
  {
    _id: 2024,
    value: 2024,
    name: "2024",
  },
  {
    _id: 2023,
    value: 2023,
    name: "2023",
  },
  {
    _id: 2022,
    value: 2022,
    name: "2022",
  },
  {
    _id: 2021,
    value: 2021,
    name: "2021",
  },
  {
    _id: 2020,
    value: 2020,
    name: "2020",
  },
];

export const defaultDuration = "00:00";
export const defaultDurationTime = "00:00:00";

export const getTableDataSlots = (count) => {
  const slotsArray = [
    { value: 10, label: 10 },
    { value: 25, label: 25 },
    { value: 50, label: 50 },
    { value: 100, label: 100 },
    { value: 200, label: 200 },
    { value: count, label: "All" },
  ];

  return slotsArray;
};

export const technicalSupportTypeList = [
  {
    _id: 0,
    name: "All",
  },
  {
    _id: 1,
    name: "App Related",
  },
  {
    _id: 2,
    name: "Course Related",
  },
];

export const priorityOrderData = [
  {
    _id: 0,
    name: "Low",
  },
  {
    _id: 1,
    name: "Medium",
  },
  {
    _id: 2,
    name: "High",
  },
];

export const notificationTypeData = [
  {
    _id: 1,
    name: "Class Schedule",
  },
];

export const taskTypeList = [
  {
    _id: 1,
    name: "PAID APP LIVE",
    value: "PAID_APP_LIVE",
  },
  {
    _id: 2,
    name: "YOUTUBE LIVE",
    value: "YOUTUBE_LIVE",
  },
  {
    _id: 3,
    name: "FREE APP LIVE",
    value: "FREE_APP_LIVE",
  },
  {
    _id: 4,
    name: "OFFLINE",
    value: "OFFLINE",
  },
  {
    _id: 5,
    name: "TEST SERIES DISCUSSION",
    value: "TEST_SERIES_DISCUSSION",
  },
  {
    _id: 6,
    name: "BOOK REVIEW",
    value: "BOOK_REVIEW",
  },
  {
    _id: 7,
    name: "ANNOUNCEMENT VIDEO",
    value: "ANNOUNCEMENT_VIDEO",
  },
  {
    _id: 8,
    name: "WEBINAR",
    value: "WEBINAR",
  },
  {
    _id: 9,
    name: "NOTIFICATION",
    value: "NOTIFICATION",
  },
  {
    _id: 10,
    name: "CONCEPT VIDEO",
    value: "CONCEPT_VIDEO",
  },
  {
    _id: 11,
    name: "OFF TOPIC VIDEO",
    value: "OFF_TOPIC",
  },
  {
    _id: 12,
    name: "INTERVIEW",
    value: "INTERVIEW",
  },
  {
    _id: 13,
    name: "PAID APP RECORDED",
    value: "PAID_APP_RECORDED",
  },
  {
    _id: 14,
    name: "QUESTIONS MAKING",
    value: "QUESTIONS_MAKING",
  },
  {
    _id: 15,
    name: "PYQ SORTING",
    value: "PYQ_SORTING",
  },
  {
    _id: 16,
    name: "PYQ PAPER ANALYSIS",
    value: "PYQ_PAPER_ANALYSIS",
  },
];

export const taskStatusList = [
  {
    _id: 1,
    name: "PENDING",
  },
  {
    _id: 2,
    name: "COMPLETE",
  },
  {
    _id: 3,
    name: "MISCELLANEOUS",
  },
  {
    _id: 4,
    name: "ON LEAVE",
  },
  {
    _id: 5,
    name: "NOT COMPLETED",
  },
];

export const SOP_PPT_List = [
  {
    _id: 1,
    name: "SOP",
  },
  {
    _id: 2,
    name: "PPT FOR PEN DISPLAY",
  },
  {
    _id: 3,
    name: "PPT FOR SMART BOARD",
  },
];

export const tokenTypeList = [
  {
    _id: 1,
    name: "DATA ENTRY",
    value: "DATA_ENTRY",
  },
  {
    _id: 2,
    name: "FACULTY",
    value: "FACULTY",
  },
  {
    _id: 3,
    name: "QUERY INFORMATIVE",
    value: "QUERY_INFORMATIVE",
  },
  {
    _id: 4,
    name: "QUERY SYLLABUS",
    value: "QUERY_SYLLABUS",
  },
  {
    _id: 5,
    name: "QUERY ENQUIRY",
    value: "QUERY_ENQUIRY",
  },
  {
    _id: 6,
    name: "TECHNICAL IOS",
    value: "TECHNICAL_IOS",
  },
  {
    _id: 7,
    name: "TECHNICAL ANDROID",
    value: "TECHNICAL_ANDROID",
  },
  {
    _id: 8,
    name: "TECHNICAL EXE",
    value: "TECHNICAL_EXE",
  },
  {
    _id: 9,
    name: "OTHER",
    value: "OTHER",
  },
];

export const companyNameList = [
  {
    _id: 1,
    name: "IFAS EDUTECH",
    value: "IFAS_EDUTECH",
  },
  {
    _id: 2,
    name: "IFAS OFFLINE PUNE",
    value: "IFAS_OFFLINE_PUNE",
  },
  {
    _id: 3,
    name: "IFAS OFFLINE JODHPUR",
    value: "IFAS_OFFLINE_JODHPUR",
  },
  {
    _id: 4,
    name: "IFAS PUBLICATIONS",
    value: "IFAS_PUBLICATIONS",
  },
];

export const regionList = [
  {
    _id: 1,
    name: "ap-south-1",
  },
  {
    _id: 2,
    name: "us-east-1",
  },
];

export const envList = [
  {
    _id: 1,
    name: "IFAS",
  },
  {
    _id: 2,
    name: "DHISTER",
  },
  {
    _id: 3,
    name: "LURNIGO",
  },
  {
    _id: 4,
    name: "RASHMII",
  },
];

export const instanceList = [
  {
    _id: 1,
    name: "BBB RTMP SERVER",
    value: "i-0de7211d7f307f8ca",
  },
];

export const examTypeQuestionBank = [
  {
    _id: 1,
    name: "MCQ",
  },
  {
    _id: 2,
    name: "MSQ",
  },
  {
    _id: 3,
    name: "NAT",
  },
  {
    _id: 4,
    name: "Assertion",
  },
];

export const priorityOrderQuestion = [
  {
    _id: 1,
    name: "Low",
  },
  {
    _id: 2,
    name: "Medium",
  },
  {
    _id: 3,
    name: "High",
  },
];

export const monthDataWithNotSelected = [
  {
    _id: "",
    value: "",
    name: "Not Selected",
  },
  {
    _id: 1,
    value: 1,
    name: "January",
  },
  {
    _id: 2,
    value: 2,
    name: "February",
  },
  {
    _id: 3,
    value: 3,
    name: "March",
  },
  {
    _id: 4,
    value: 4,
    name: "April",
  },
  {
    _id: 5,
    value: 5,
    name: "May",
  },
  {
    _id: 6,
    value: 6,
    name: "June",
  },
  {
    _id: 7,
    value: 7,
    name: "July",
  },
  {
    _id: 8,
    value: 8,
    name: "August",
  },
  {
    _id: 9,
    value: 9,
    name: "September",
  },
  {
    _id: 10,
    value: 10,
    name: "October",
  },
  {
    _id: 11,
    value: 11,
    name: "November",
  },
  {
    _id: 12,
    value: 12,
    name: "December",
  },
];

export const holidays = [
  "2025-01-01",
  "2025-01-26",
  "2025-03-14",
  "2025-08-09",
  "2025-08-15",
  "2025-09-06",
  "2025-10-02",
  "2025-10-21",
  "2025-10-22",
  "2025-12-25",
];

export const sourceData = [
  {
    _id: 3,
    name: "Website",
  },
  {
    _id: 1,
    name: "Classroom",
  },
  {
    _id: 4,
    name: "Webinar",
  },
  {
    _id: 8,
    name: "Social Media",
  },
  {
    _id: 6,
    name: "Ad Marketing",
  },
  {
    _id: 13,
    name: "App Registration",
  },
];
export const RoleList = [
  {
    _id: 14,
    name: "SOCIAL MEDIA",
  },
  {
    _id: 2,
    name: "CATEGORY",
  },
];

export const youtubeTypeList = [
  {
    _id: 1,
    name: "Videos",
  },
  {
    _id: 2,
    name: "Live",
  },
  {
    _id: 3,
    name: "Shorts",
  },
];

export const ResultOtherDetails = [
  {
    _id: 1,
    name: "JRF",
  },
  {
    _id: 2,
    name: "NET LS",
  },
  {
    _id: 3,
    name: "PHD",
  },
];

export const transactionTypeList = [
  { _id: 1, name: "Edutech", value: false },
  { _id: 2, name: "Publication", value: true },
  { _id: 3, name: "Shopify", value: false },
];

export const categoryListData = [
  {
    _id: 1,
    name: "UNRESERVED",
  },
  {
    _id: 2,
    name: "EWS",
  },
  {
    _id: 3,
    name: "OBS",
  },
  {
    _id: 4,
    name: "PWD",
  },
];

export const ScoreCardList = [
  {
    _id: 1,
    name: "Image",
  },
  {
    _id: 2,
    name: "PDF",
  },
];

export const LectureNum = [
  { name: 1 },
  { name: 2 },
  { name: 3 },
  { name: 4 },
  { name: 5 },
  { name: 6 },
  { name: 7 },
];
export const reportTypeList = [
  {
    _id: 1,
    name: "Edutech Transaction",
    subkey: "",
  },
  {
    _id: 2,
    name: "Edutech Extended",
    subkey: "extendedOption",
  },
  {
    _id: 3,
    name: "Edutech Upgrade",
    subkey: "upgradePackageOption",
  },
  {
    _id: 4,
    name: "Edutech Study Material",
    subkey: "studyMaterial",
  },
  {
    _id: 5,
    name: "Edutech Transaction + Extended + Upgrade",
    subkey: "allTransactionData",
  },
  {
    _id: 6,
    name: "Publication Transaction",
    subkey: "publication",
  },
  {
    _id: 7,
    name: "Edutech in Publication ",
    subkey: "paymentInPublication",
  },
];

export const TypeList = [
  {
    _id: 1,
    name: "Transaction",
  },
  {
    _id: 2,
    name: "Extended",
  },
  {
    _id: 3,
    name: "Upgrade",
  },
];

export const ReportsTabList = [
  {
    _id: 1,
    name: "Overview",
  },
  {
    _id: 2,
    name: "Download",
  },
];

export const statusActiveDeactiveDeleted = [
  {
    _id: 1,
    name: "Active",
  },
  {
    _id: 9,
    name: "Deleted",
  },
];

export const deviceAddStatusList = [
  { _id: 1, name: "Available" },
  { _id: 6, name: "Office Use" },
  { _id: 7, name: "Studio Use" },
];

export const deviceStatusList = [
  { _id: 1, name: "Available" },
  { _id: 2, name: "Assigned" },
  { _id: 3, name: "Damaged" },
  { _id: 4, name: "Repair" },
  { _id: 5, name: "Dead" },
  { _id: 6, name: "Office Use" },
  { _id: 7, name: "Studio Use" },
];

export const paymentOptions = [
  { _id: 0, name: "DEFAULT (Without Coupon Code)" },
  { _id: 1, name: "IFAS COUPON CODE" },
  { _id: 2, name: "STUDENT" },
  { _id: 3, name: "EDUTECH COUNSELLOR" },
  { _id: 4, name: "PAMPHLET" },
  { _id: 5, name: "SHOPIFY" },
  { _id: 6, name: "PUBLICATION COUNSELLOR" },
];

export const mediaList = [
  {
    _id: "facebook_page",
    name: "FB Page",
  },
  {
    _id: "facebook_group",
    name: "FB Group",
  },
  {
    _id: "instagram",
    name: "Instagram",
  },
  {
    _id: "youtube",
    name: "YouTube",
  },
  {
    _id: "telegram_channel",
    name: "Telegram Channel",
  },
  {
    _id: "telegram_group",
    name: "Telegram Group",
  },
];

export const resultGivenByList = [
  {
    _id: 1,
    name: "Default",
  },
  {
    _id: 2,
    name: "Counsellor",
  },
];

export const taskStatus = [
  {
    _id: 1,
    name: "In Progress",
  },
  {
    _id: 2,
    name: "Pending",
  },
  {
    _id: 3,
    name: "Done",
  },
];
export const originSourceList = [
  {
    _id: 1,
    name: "Youtube",
  },
  {
    _id: 2,
    name: "Whatsapp",
  },
  {
    _id: 3,
    name: "Instagram",
  },
  {
    _id: 4,
    name: "Facebook",
  },
  {
    _id: 5,
    name: "Telegram",
  },
  {
    _id: 6,
    name: "Friend",
  },
  {
    _id: 7,
    name: "Teachers",
  },
  {
    _id: 8,
    name: "Other",
  },
];

export const genderData = [
  {
    _id: 1,
    name: "MALE",
  },
  {
    _id: 2,
    name: "FEMALE",
  },
  {
    _id: 3,
    name: "OTHER",
  },
];

export const employeeTypeData = [
  {
    _id: 1,
    name: "Professional",
  },
  {
    _id: 2,
    name: "Employee",
  },
];

export const educationalData = [
  {
    _id: 1,
    name: "10th",
  },
  {
    _id: 2,
    name: "12th",
  },
  {
    _id: 3,
    name: "Graduation",
  },
  {
    _id: 4,
    name: "Post Graduation",
  },
  {
    _id: 5,
    name: "PHD",
  },
  {
    _id: 6,
    name: "Diploma",
  },
];

export const gstRateList = [
  {
    id: 1,
    name: 0,
  },
  {
    id: 2,
    name: 5,
  },
  {
    id: 3,
    name: 12,
  },
  {
    id: 4,
    name: 18,
  },
  {
    id: 5,
    name: 28,
  },
];
