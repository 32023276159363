import React, { Suspense } from "react";
import Sidebar from "../../Module/sidebar";
import Loading from "../../Component/Loading";
import { Route, Routes } from "react-router";
import {
  PaidAdsEmpSideBarData,
  PaidAdsSideBarData,
} from "../../Module/paidAdsSidebarData";
import { useSelector } from "react-redux";
import CommonRoutes from "../Common/commonRoute";
const PaidAds = React.lazy(() => import("./paidAds"));
const LeadDashboard = React.lazy(() =>
  import("../CategoryPanel/leadDashboard")
);
const LeadCount = React.lazy(() => import("../CategoryPanel/leadCount"));
const LeadAnalysis = React.lazy(() => import("../CategoryPanel/leadAnalysis"));

const TargetStatus = React.lazy(() => import("../CategoryPanel/targetStatus"));
const MovementsOfMeeting = React.lazy(() =>
  import("../CategoryPanel/MovementsOfMeeting/movementsOfMeeting")
);
const EditMovementsOfMeeting = React.lazy(() =>
  import("../CategoryPanel/MovementsOfMeeting/editMovementsOfMeeting")
);
const PaidAdsDashboard = () => {
  const { loginType } = useSelector((state) => ({
    designation: state.Auth?.userInfo?.designation,
    loginType: state.Auth?.loginType,
  }));
  console.log("loginType", loginType);
  return (
    <div className="AppBox">
      {/* <Header /> */}
      <Sidebar
        data={loginType === 2 ? PaidAdsEmpSideBarData : PaidAdsSideBarData}
      />
      <div className="dashboard">
        {
          <CommonRoutes loginType={loginType} defaultPage={<PaidAds />}>
            <Route path="/" element={<PaidAds />} />
            <Route path="paidAds" element={<PaidAds />} />
            <Route path="leadDashboard" element={<LeadDashboard />} />
            <Route path="LeadCount" element={<LeadCount />} />
            <Route path="leadAnalysis" element={<LeadAnalysis />} />
            <Route path="targetStatus" element={<TargetStatus />} />
            <Route path="movementsOfMeeting" element={<MovementsOfMeeting />} />
            <Route
              path="editMovementsOfMeeting"
              element={<EditMovementsOfMeeting />}
            />
          </CommonRoutes>
        }
      </div>
    </div>
  );
};

export default PaidAdsDashboard;
