import "../../App.css";
import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import Loading from "../../Component/Loading";
import Sidebar from "../../Module/sidebar";
import {
  YoutubeEmpSidebarData,
  YoutubeSidebarData,
} from "../../Module/youtubeSidebarData";
import { useSelector } from "react-redux";
import CommonRoutes from "../Common/commonRoute";

const CreateTarget = lazy(() => import("./YoutubeTarget/createTargetYoutube"));
const TargetStatus = lazy(() => import("./YoutubeTarget/targetStatusYoutube"));

function YoutubeDashboard() {
  const { loginType } = useSelector((state) => ({
    designation: state.Auth?.userInfo?.designation,
    loginType: state.Auth?.loginType,
  }));
  return (
    <div className="AppBox">
      <Sidebar
        data={loginType === 2 ? YoutubeEmpSidebarData : YoutubeSidebarData}
      />
      <div className="dashboard">
        {
          <CommonRoutes loginType={loginType} defaultPage={<CreateTarget />}>
            <Route path="/" element={<CreateTarget />} />
            <Route path="createTargetYoutube" element={<CreateTarget />} />
            <Route path="targetStatusYoutube" element={<TargetStatus />} />
          </CommonRoutes>
        }
      </div>
    </div>
  );
}
export default YoutubeDashboard;
