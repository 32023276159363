import React, { Suspense, lazy } from "react";
import Sidebar from "../../Module/sidebar";
import Loading from "../../Component/Loading";
import { Route, Routes } from "react-router";
import { TeacherEmpTaskSideBarData, TeacherTaskSideBarData } from "../../Module/TeacherTask/teacherTaskSidebarData";
import TeacherPerformance from "../CXOPanel/teacherPerformance";
import { useSelector } from "react-redux";

const AddTeacherTask = lazy(() => import("../CategoryPanel/addTeacherTask"));
const EditDeleteTeacherTask = lazy(() =>
  import("../CategoryPanel/editDeleteTeacherTask")
);
const YoutubeDuplicateUrl = lazy(() =>
  import("../VpOperationsPanel/youtubeDuplicateUrl")
);
const MovementsOfMeeting = React.lazy(() => import("../CategoryPanel/MovementsOfMeeting/movementsOfMeeting"));
const EditMovementsOfMeeting = React.lazy(() => import("../CategoryPanel/MovementsOfMeeting/editMovementsOfMeeting"));

const TeacherTaskDashboard = () => {
  const {loginType } = useSelector((state) => ({
    designation: state.Auth?.userInfo?.designation,
    loginType: state.Auth?.loginType,

  }));
  console.log("loginType",loginType);
  return (
    <div className="AppBox">
      <Sidebar data={loginType ===2 ?TeacherEmpTaskSideBarData : TeacherTaskSideBarData} />
      <div className="dashboard">
        {
          <Suspense
            fallback={
              <div className="border p-1">
                <Loading />
              </div>
            }
          >
            <Routes>
              <Route path="/" element={<AddTeacherTask />} />
              <Route path="addTeacherTask" element={<AddTeacherTask />} />
              <Route
                path="editDeleteTeacherTask"
                element={<EditDeleteTeacherTask />}
              />
              <Route
                path="youtubeDuplicateUrl"
                element={<YoutubeDuplicateUrl />}
              />
              <Route
                path="teacherPerformance"
                element={<TeacherPerformance />}
              />
              <Route path="movementsOfMeeting" element={<MovementsOfMeeting />} />
              <Route path="editMovementsOfMeeting" element={<EditMovementsOfMeeting />} />
            </Routes>
          </Suspense>
        }
      </div>
    </div>
  );
};

export default TeacherTaskDashboard;
