import "../../App.css";
import React, { Suspense } from "react";
import Sidebar from "../../Module/sidebar";
import { Route, Routes } from "react-router-dom";
import Loading from "../../Component/Loading";
import {
  DataEntryPanelEmpSidebarData,
  DataEntryPanelSidebarData,
} from "../../Module/dataEntryPanelSidebarData";
import Chat from "../TeacherPanel/chat";
import { useSelector } from "react-redux";
import CommonRoutes from "../Common/commonRoute";

const AddLiveVideoNew = React.lazy(() => import("./addLiveVideoNew"));
const AddPremierVideo = React.lazy(() => import("./addPremierVideo"));
const AddFreeUserVideo = React.lazy(() => import("./addFreeUserVideo"));

const CreateTopic = React.lazy(() => import("./createTopic"));
const EditdeleteTopic = React.lazy(() => import("./editDeleteTopic"));

const CreateSubTopic = React.lazy(() => import("./createSubTopic"));
const CreateLecture = React.lazy(() => import("./createLecture"));
const EditDeleteSubTopic = React.lazy(() => import("./editDeleteSubTopic"));
const EditDeleteLecture = React.lazy(() => import("./editDeleteLecture"));

const EditDeleteLivePremierVideo = React.lazy(() =>
  import("./editDeleteLivePremierVideo")
);
const EditDeleteFreeUserVideo = React.lazy(() =>
  import("./editDeleteFreeUserVideo")
);

const EditDeletePremierVideo = React.lazy(() =>
  import("./editDeletePremierVideo")
);

const EditDeleteLiveVideoNew = React.lazy(() =>
  import("./editDeleteLiveVideoNew")
);

const AddLiveRecordedVideo = React.lazy(() => import("./addLiveRecordedVideo"));
const EditDeleteLiveRecordedVideo = React.lazy(() =>
  import("./editDeleteLiveRecordedVideo")
);

const AddCourses = React.lazy(() => import("./addCourses"));
const EditDeleteCourses = React.lazy(() => import("./editDeleteCourses"));

const AddImportRecordedVideo = React.lazy(() =>
  import("./addImportRecordedVideo")
);
const EditDeleteImportRecordedVideo = React.lazy(() =>
  import("./editDeleteImportRecordedVideo")
);

const CopyRecordedLecture = React.lazy(() => import("./copyRecordedLecture"));
const ReplaceLecture = React.lazy(() => import("./replaceLecture"));
const EditDeleteCopyRecordedLecture = React.lazy(() =>
  import("./editDeleteCopyRecordedLecture")
);

const CreateNotification = React.lazy(() => import("./createNotification"));
const EditDeleteNotification = React.lazy(() =>
  import("./editDeleteNotification")
);

const CreateAssignTeacher = React.lazy(() => import("./createAssignTeacher"));
const EditDeleteAssignTeacher = React.lazy(() =>
  import("./editDeleteAssignTeacher")
);

const CreateExamSet = React.lazy(() => import("./createExamSet"));
const EditDeleteExamSet = React.lazy(() => import("./editDeleteExamSet"));
const CopyExamSet = React.lazy(() => import("./copyExamSet"));
const AssignQuestionToExamSet = React.lazy(() =>
  import("./assignQuestionToExamSet")
);

const CreateQuestion = React.lazy(() =>
  import("./CreateQuestion/createQuestion")
);

const AddQuestionsNo = React.lazy(() =>
  import("./CreateQuestion/addQuestionsNo")
);
const AddQuestionsYes = React.lazy(() =>
  import("./CreateQuestion/addQuestionsYes")
);
const QuestionEditor = React.lazy(() =>
  import("./CreateQuestion/questionEditor")
);
const QuestionBankEditor = React.lazy(() =>
  import("./CreateQuestion/questionBankEditor")
);
const QuestionsOrder = React.lazy(() => import("./setQuestionOrder"));

const EditQuestion = React.lazy(() => import("./CreateQuestion/editQuestion"));
const EditDeleteQuestion = React.lazy(() => import("./editDeleteQuestion"));
const EditDeleteQuestionBank = React.lazy(() =>
  import("./editDeleteQuestionBank")
);

const AddTeachingHours = React.lazy(() => import("./addTeachingHours"));
const EditDeleteTeachingHours = React.lazy(() =>
  import("./editDeleteTeachingHours")
);

const TechnicalSupport = React.lazy(() =>
  import("../TechnicalSupportPanel/technicalSupport")
);

const TeacherSupport = React.lazy(() =>
  import("../TechnicalSupportPanel/teacherSupport")
);

const CreateRaiseIssue = React.lazy(() => import("./createRaiseIssue"));
const EditDeleteRaiseIssue = React.lazy(() => import("./editDeleteRaiseIssue"));

const LiveClassSchedule = React.lazy(() => import("./liveClassSchedule"));
const TeacherTaskPlan = React.lazy(() => import("./teacherTaskPlan"));
const TeacherTaskPlanComplete = React.lazy(() =>
  import("./teacherTaskPlanComplete")
);
const TeacherTaskPlanPending = React.lazy(() =>
  import("./teacherTaskPlanPending")
);
const TeacherTaskPlanNotCompleted = React.lazy(() =>
  import("./teacherTaskPlanNotCompleted")
);
const RegistrationDetails = React.lazy(() => import("./registrationDetails"));

const CreateJobType = React.lazy(() =>
  import("../DataEntryPanel/createJobType")
);
const EditDeleteJobType = React.lazy(() =>
  import("../DataEntryPanel/editDeleteJobType")
);
const AddCareer = React.lazy(() => import("./addCareer"));
const EditDeleteCareer = React.lazy(() => import("./editDeleteCareer"));

const CopyPackage = React.lazy(() => import("./CopyPackage/copyPackage"));
const EditCopyPackage = React.lazy(() =>
  import("./CopyPackage/editCopyPackage")
);
const SyllabusDashboard = React.lazy(() => import("./syllabusDashboard"));
const ImportQuestions = React.lazy(() =>
  import("./CreateQuestion/importQuestions")
);
const MovementsOfMeeting = React.lazy(() =>
  import("../CategoryPanel/MovementsOfMeeting/movementsOfMeeting")
);
const EditMovementsOfMeeting = React.lazy(() =>
  import("../CategoryPanel/MovementsOfMeeting/editMovementsOfMeeting")
);

function DateEntryPanelDashboard() {
  const { loginType } = useSelector((state) => ({
    designation: state.Auth?.userInfo?.designation,
    loginType: state.Auth?.loginType,
  }));
  console.log("DateEntryPanelDashboard", loginType);
  return (
    <div className="AppBox">
      {/* <Header /> */}
      <Sidebar
        data={
          loginType === 2
            ? DataEntryPanelEmpSidebarData
            : DataEntryPanelSidebarData
        }
      />
      <div className="dashboard">
        {
          <CommonRoutes loginType={loginType} defaultPage={<CreateTopic />}>
            <Route path="/" element={<CreateTopic />} />

            <Route path="createTopic" element={<CreateTopic />} />
            <Route path="editdeleteTopic" element={<EditdeleteTopic />} />

            <Route path="createSubTopic" element={<CreateSubTopic />} />
            <Route path="editDeleteLecture" element={<EditDeleteLecture />} />
            <Route path="createLecture" element={<CreateLecture />} />
            <Route path="editDeleteSubTopic" element={<EditDeleteSubTopic />} />
            <Route path="addLiveVideoNew" element={<AddLiveVideoNew />} />
            <Route path="addPremierVideo" element={<AddPremierVideo />} />
            <Route path="addFreeUserVideo" element={<AddFreeUserVideo />} />
            <Route
              path="editDeleteLivePremierVideo"
              element={<EditDeleteLivePremierVideo />}
            />
            <Route
              path="editDeletePremierVideo"
              element={<EditDeletePremierVideo />}
            />
            <Route
              path="editDeleteFreeUserVideo"
              element={<EditDeleteFreeUserVideo />}
            />

            <Route
              path="addLiveRecordedVideo"
              element={<AddLiveRecordedVideo />}
            />
            <Route
              path="editDeleteLiveRecordedVideo"
              element={<EditDeleteLiveRecordedVideo />}
            />

            <Route path="addCourses" element={<AddCourses />} />
            <Route path="editDeleteCourses" element={<EditDeleteCourses />} />
            <Route path="syllabusDashboard" element={<SyllabusDashboard />} />

            <Route
              path="addImportRecordedVideo"
              element={<AddImportRecordedVideo />}
            />
            <Route
              path="editDeleteImportRecordedVideo"
              element={<EditDeleteImportRecordedVideo />}
            />

            <Route
              path="copyRecordedLecture"
              element={<CopyRecordedLecture />}
            />
            <Route
              path="editDeleteCopyRecordedLecture"
              element={<EditDeleteCopyRecordedLecture />}
            />

            <Route path="createNotification" element={<CreateNotification />} />
            <Route
              path="editDeleteNotification"
              element={<EditDeleteNotification />}
            />

            <Route
              path="createAssignTeacher"
              element={<CreateAssignTeacher />}
            />
            <Route
              path="editDeleteAssignTeacher"
              element={<EditDeleteAssignTeacher />}
            />

            <Route path="createExamSet" element={<CreateExamSet />} />
            <Route path="editDeleteExamSet" element={<EditDeleteExamSet />} />
            <Route path="copyExamSet" element={<CopyExamSet />} />
            <Route
              path="assignQuestionToExamSet"
              element={<AssignQuestionToExamSet />}
            />

            <Route path="createQuestion" element={<CreateQuestion />} />

            <Route path="addQuestionYes" element={<AddQuestionsYes />} />
            <Route path="addQuestionNo" element={<AddQuestionsNo />} />
            <Route path="questionEditor" element={<QuestionEditor />} />
            <Route path="questionBankEditor" element={<QuestionBankEditor />} />
            <Route path="editDeleteQuestion" element={<EditDeleteQuestion />} />
            <Route
              path="editDeleteQuestionBank"
              element={<EditDeleteQuestionBank />}
            />
            <Route path="editQuestion" element={<EditQuestion />} />
            <Route path="questionOrder" element={<QuestionsOrder />} />

            <Route path="addTeachingHours" element={<AddTeachingHours />} />
            <Route
              path="editDeleteTeachingHours"
              element={<EditDeleteTeachingHours />}
            />

            <Route path="technicalSupport" element={<TechnicalSupport />} />
            <Route path="teacherSupport" element={<TeacherSupport />} />

            <Route path="createRaiseIssue" element={<CreateRaiseIssue />} />
            <Route
              path="editDeleteRaiseIssue"
              element={<EditDeleteRaiseIssue />}
            />
            <Route
              path="editDeleteLiveVideoNew"
              element={<EditDeleteLiveVideoNew />}
            />
            <Route path="replaceLecture" element={<ReplaceLecture />} />
            <Route path="chat" element={<Chat />} />
            <Route path="liveclassschedule" element={<LiveClassSchedule />} />
            <Route path="teacherTaskPlan" element={<TeacherTaskPlan />} />
            <Route
              path="teacherTaskPlanComplete"
              element={<TeacherTaskPlanComplete />}
            />
            <Route
              path="teacherTaskPlanPending"
              element={<TeacherTaskPlanPending />}
            />
            <Route
              path="teacherTaskPlanNotCompleted"
              element={<TeacherTaskPlanNotCompleted />}
            />
            <Route path="createJobType" element={<CreateJobType />} />
            <Route path="editDeleteJobType" element={<EditDeleteJobType />} />
            <Route path="addcareer" element={<AddCareer />} />
            <Route path="editdeletecareer" element={<EditDeleteCareer />} />
            <Route
              path="registrationDetails"
              element={<RegistrationDetails />}
            />
            <Route path="copyPackage" element={<CopyPackage />} />
            <Route path="editCopyPackage" element={<EditCopyPackage />} />
            <Route path="importQuestions" element={<ImportQuestions />} />
            <Route path="movementsOfMeeting" element={<MovementsOfMeeting />} />
            <Route
              path="editMovementsOfMeeting"
              element={<EditMovementsOfMeeting />}
            />
          </CommonRoutes>
        }
      </div>
    </div>
  );
}

export default DateEntryPanelDashboard;
