import "../../App.css";
import React, { Suspense } from "react";
import Sidebar from "../../Module/sidebar";
import { Route, Routes } from "react-router-dom";
import Loading from "../../Component/Loading";
import {
  upSellingEmpPanelSidebarData,
  upSellingPanelSidebarData,
} from "../../Module/upSellingPanelSidebarData";
import { useSelector } from "react-redux";
import CommonRoutes from "../Common/commonRoute";

const UpcomingExpiry = React.lazy(() => import("./upcomingExpiry"));
const ExpiredAccount = React.lazy(() => import("./expiredAccount"));
const MovementsOfMeeting = React.lazy(() =>
  import("../CategoryPanel/MovementsOfMeeting/movementsOfMeeting")
);
const EditMovementsOfMeeting = React.lazy(() =>
  import("../CategoryPanel/MovementsOfMeeting/editMovementsOfMeeting")
);

function UpSellingDashboard() {
  const { loginType } = useSelector((state) => ({
    designation: state.Auth?.userInfo?.designation,
    loginType: state.Auth?.loginType,
  }));
  console.log("loginType", loginType);
  return (
    <div className="AppBox">
      {/* <Header /> */}
      <Sidebar
        data={
          loginType === 2
            ? upSellingEmpPanelSidebarData
            : upSellingPanelSidebarData
        }
      />
      <div className="dashboard">
        {
          <CommonRoutes loginType={loginType} defaultPage={<UpcomingExpiry />}>
            <Route path="/" element={<UpcomingExpiry />} />
            <Route path="upcomingExpiry" element={<UpcomingExpiry />} />
            <Route path="expiredAccount" element={<ExpiredAccount />} />
            <Route path="movementsOfMeeting" element={<MovementsOfMeeting />} />
            <Route
              path="editMovementsOfMeeting"
              element={<EditMovementsOfMeeting />}
            />
          </CommonRoutes>
        }
      </div>
    </div>
  );
}

export default UpSellingDashboard;
