export const TeacherTaskSideBarData = [
  {
    title: "TEACHER TASK PLAN",
    subNav: [
      {
        title: "ADD TEACHER TASK",
        path: "addTeacherTask",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editDeleteTeacherTask",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "YOUTUBE DUPLICATE URL",
    link: "youtubeDuplicateUrl",
  },
  {
    title: "TEACHER PERFORMANCE",
    link: "teacherPerformance",
  },
];

export const TeacherEmpTaskSideBarData = [
  {
    title: "TEACHER TASK PLAN",
    subNav: [
      {
        title: "ADD TEACHER TASK",
        path: "addTeacherTask",
        cName: "sub-nav",
      },
      {
        title: "EDIT / DELETE",
        path: "editDeleteTeacherTask",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "YOUTUBE DUPLICATE URL",
    link: "youtubeDuplicateUrl",
  },
  {
    title: "TEACHER PERFORMANCE",
    link: "teacherPerformance",
  },
  {
    title: "MOVEMENTS OF MEETING",
    subNav: [
      {
        title: "MOVEMENTS OF MEETING",
        path: "movementsOfMeeting",
        cName: "sub-nav",
      },
      {
        title: "EDIT/DELETE",
        path: "editMovementsOfMeeting",
        cName: "sub-nav",
      },
    ],
  },
];

