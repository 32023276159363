import "../../App.css";
import React, { lazy } from "react";
import Sidebar from "../../Module/sidebar";
import { Route } from "react-router-dom";
import {
  FinanceEmpSidebarData,
  FinanceSidebarData,
} from "../../Module/financeSidebarData";
import { useSelector } from "react-redux";
import CommonRoutes from "../Common/commonRoute";

//revenue details
const TransactionDetails = lazy(() => import("../CXOPanel/transactionDetails"));
const TransactionEdutech = lazy(() => import("../CXOPanel/transactionEdutech"));
const ActiveUser = lazy(() => import("../CXOPanel/activeUser"));
const TransactionEdutechStudyMaterial = lazy(() =>
  import("../CXOPanel/transactionEdutechStudyMaterial")
);
const TransactionPublication = lazy(() => import("./transactionPublication"));
const PaymentPublication = lazy(() => import("../CXOPanel/paymentPublication"));

//revenue analysis
const SourceAnalysis = lazy(() => import("../CXOPanel/sourceWiseAnalysis"));
const StateAnalysis = lazy(() => import("../CXOPanel/stateWiseAnalysis"));
const RevenueProjection = lazy(() => import("../CXOPanel/revenueProjection"));
const RevenueAnalysis = lazy(() => import("../CXOPanel/cxoDashboard"));
const AllExamWiseRevenue = lazy(() => import("../CXOPanel/allExamWiseRevenue"));
const ExamWiseRevenue = lazy(() => import("../CXOPanel/examWiseRevenue"));

//admission - edutech
const BatchwiseCount = lazy(() => import("./batchwiseCount"));
const DatewiseCount = lazy(() => import("./datewiseCount"));
const RevenueDetails = lazy(() => import("./revenueDetails"));

const AssuredRevenue = lazy(() => import("./assuredRevenue"));
const ReferEarn = lazy(() => import("./ReferEarn/referEarn"));
const ReferPay = lazy(() => import("./ReferEarn/referralPayment"));
const ReferEarnDetail = lazy(() => import("./ReferEarn/referEarnDetail"));

//invoice management
const AddInvoice = lazy(() => import("./addInvoice"));
const InvoiceList = lazy(() => import("./invoiceList"));

const RefundList = lazy(() => import("./refundList"));

const ClassroomStudents = lazy(() => import("./classroomStudents"));

const UserInvoice = lazy(() => import("./userInvoice"));

const AddDataToReports = React.lazy(() =>
  import("../ConfigurationData/addDataToReports")
);
const DownloadReports = React.lazy(() => import("../CXOPanel/downloadReports"));
const IncentiveRevenue = lazy(() => import("./incentiveRevenue"));
const TargetStatusFinance = lazy(() => import("./targetStatusFinance"));
const MovementsOfMeeting = React.lazy(() =>
  import("../CategoryPanel/MovementsOfMeeting/movementsOfMeeting")
);
const EditMovementsOfMeeting = React.lazy(() =>
  import("../CategoryPanel/MovementsOfMeeting/editMovementsOfMeeting")
);
export const InfluencerPayment = lazy(() =>
  import("../InfluencerPanel/Payment/InfluencerPaymentCommon")
);
function FinanceDashboard() {
  const { loginType } = useSelector((state) => ({
    designation: state.Auth?.userInfo?.designation,
    loginType: state.Auth?.loginType,
  }));
  console.log("loginType", loginType);
  return (
    <div className="AppBox">
      <Sidebar
        data={loginType === 2 ? FinanceEmpSidebarData : FinanceSidebarData}
      />
      <div className="dashboard">
        {
          <CommonRoutes
            loginType={loginType}
            defaultPage={<TransactionEdutech />}
          >
            <Route path="transactionEdutech" element={<TransactionEdutech />} />
            <Route
              path="transactionEdutechStudyMaterial"
              element={<TransactionEdutechStudyMaterial />}
            />
            <Route path="BatchwiseCount" element={<BatchwiseCount />} />
            <Route path="DatewiseCount" element={<DatewiseCount />} />
            <Route path="RevenueDetails" element={<RevenueDetails />} />
            <Route
              path="transactionPublication"
              element={<TransactionPublication />}
            />
            <Route path="assuredRevenue" element={<AssuredRevenue />} />
            <Route path="addInvoice" element={<AddInvoice />} />
            <Route path="referEarn" element={<ReferEarn />} />
            <Route path="referEarnDetail" element={<ReferEarnDetail />} />
            <Route path="referralPay" element={<ReferPay />} />
            <Route path="pendingForProcess" element={<InvoiceList />} />
            <Route path="pendingForPayment" element={<InvoiceList />} />
            <Route path="paidInvoice" element={<InvoiceList />} />
            <Route path="refundList" element={<RefundList />} />
            <Route
              path="financeClassroomStudent"
              element={<ClassroomStudents />}
            />
            <Route path="paymentPublication" element={<PaymentPublication />} />
            <Route path="transactionDetails" element={<TransactionDetails />} />
            <Route path="sourceWiseAnalysis" element={<SourceAnalysis />} />
            <Route path="stateWiseAnalysis" element={<StateAnalysis />} />
            <Route path="RevenueProjection" element={<RevenueProjection />} />
            <Route path="revenueAnalysis" element={<RevenueAnalysis />} />
            <Route path="allExamWiseRevenue" element={<AllExamWiseRevenue />} />
            <Route path="examWiseRevenue" element={<ExamWiseRevenue />} />
            <Route path="userInvoice" element={<UserInvoice />} />
            <Route path="addDataToReports" element={<AddDataToReports />} />
            <Route path="downloadReports" element={<DownloadReports />} />
            <Route path="incentiveRevenue" element={<IncentiveRevenue />} />
            <Route path="movementsOfMeeting" element={<MovementsOfMeeting />} />
            <Route
              path="targetStatusFinance"
              element={<TargetStatusFinance />}
            />
            <Route
              path="editMovementsOfMeeting"
              element={<EditMovementsOfMeeting />}
            />
            <Route path="activeUser" element={<ActiveUser />} />
            <Route
              path="influencerPayment"
              element={<InfluencerPayment type={1} />}
            />
          </CommonRoutes>
        }
      </div>
    </div>
  );
}

export default FinanceDashboard;
