import "../../App.css";
import React, { lazy } from "react";
import Sidebar from "../../Module/sidebar";
import { Route } from "react-router-dom";
import {
  CXOEmpPanelSidebarData,
  CXOPanelSidebarData,
} from "../../Module/CXOPanelSidebarData";
import TeacherLectureFeedback from "./teacherLectureFeedback";
import CxoDashboard from "./cxoDashboard";
import RevenueProjection from "./revenueProjection";
import TechnicalSupportNew from "../TechnicalSupportPanel/technicalSupportNew";
import BatchaAnalisis from "./BatchaAnalisis";
import PayRollCtc from "../SuperAdminPanel/payRollCtc";
import { useSelector } from "react-redux";
import CommonRoutes from "../Common/commonRoute";

const RegisteredUser = React.lazy(() => import("./registeredUser"));
const CxoBatchwiseCount = React.lazy(() => import("./cxoBatchWiseCount"));
const DatewiseCount = React.lazy(() => import("./cxoDateWiseCount"));
const RevenueDetails = React.lazy(() => import("../Finance/revenueDetails"));
const StudentCount = React.lazy(() => import("./studentCount"));
const WebsiteLeadCount = React.lazy(() => import("./websiteLeadCount"));
const ReferenceManagerCount = React.lazy(() =>
  import("./referenceManagerCount")
);
const ReferenceTeamLeadCount = React.lazy(() =>
  import("./referenceTeamLeadCount")
);
const ReferenceLeadsCount = React.lazy(() => import("./referenceLeadsCount"));
const TransactionEdutech = React.lazy(() => import("./transactionEdutech"));
const TransactionEdutechOnlineCourse = React.lazy(() =>
  import("./transactionEdutechOnlineCourse")
);
const TransactionEdutechStudyMaterial = React.lazy(() =>
  import("./transactionEdutechStudyMaterial")
);
const AdmissionDetails = React.lazy(() =>
  import("../Finance/admissionDetails")
);
const TransactionPublication = React.lazy(() =>
  import("../Finance/transactionPublication")
);
const ClassroomStudents = React.lazy(() => import("./classroomStudents"));
const TeacherFeedback = React.lazy(() => import("./teacherFeedback"));
const ExpertSupport = React.lazy(() => import("./expertSupport"));
const AssuredRevenue = React.lazy(() => import("../Finance/assuredRevenue"));
const SecondInstallmentPaid = React.lazy(() =>
  import("../Finance/secondInstallmentPaid")
);
const SecondInstallmentDue = React.lazy(() =>
  import("../Finance/secondInstallmentDue")
);
const TeacherRanking = React.lazy(() => import("./teacherRanking"));
const StudentCouponAdd = React.lazy(() =>
  import("./studentCouponCode/studentCouponAdd")
);
const StudentCouponEdit = React.lazy(() =>
  import("./studentCouponCode/studentCouponEdit")
);
const SourceAnalysis = React.lazy(() => import("./sourceWiseAnalysis"));
const StateAnalysis = React.lazy(() => import("./stateWiseAnalysis"));
const LeadOverview = React.lazy(() => import("./leadOverview"));
const DailyMeeting = React.lazy(() => import("./dailyMeeting"));
const TeamLeadAssignedDateAnalysis = React.lazy(() =>
  import("./teamLeadAssignedDateAnalysis")
);
const CounsellorLeadOverview = React.lazy(() =>
  import("./counsellorLeadOverview")
);
const LeadAssignedDateAnalysis = React.lazy(() =>
  import("./leadAssignedDateAnalysis")
);
const LeadAssignedStatusOverview = React.lazy(() =>
  import("./leadAssignedStatusOverview")
);
const CompleteLeadDetail = React.lazy(() => import("./completeLeadDetail"));
const AddWPConfiguration = React.lazy(() =>
  import("./whatsappConfiguration/createWPConfiguration")
);
const EditWPConfiguration = React.lazy(() =>
  import("./whatsappConfiguration/editWPConfiguration")
);
const PaymentPublication = React.lazy(() => import("./paymentPublication"));
const OldPackageExist = React.lazy(() => import("./oldPackageExists"));
const MessageDeliveredStatus = React.lazy(() =>
  import("./messageDeliveredStatus")
);
const TransactionDetails = React.lazy(() => import("./transactionDetails"));
const LeadAnalysis = React.lazy(() => import("./leadAnalysis"));
const CxoPayRoll = React.lazy(() => import("./cxoPayRoll"));
const BdePerformance = React.lazy(() => import("./bdePerformance"));
const CategoryPerformance = React.lazy(() => import("./categoryPerformance"));
const SocialMediaPerformance = React.lazy(() =>
  import("./socialMediaPerformance")
);
const SalesPerformance = React.lazy(() => import("./salesPerformance"));
const OverviewChannel = React.lazy(() => import("./overviewChannel"));
const TeacherPerformance = React.lazy(() => import("./teacherPerformance"));
const AllExamWiseRevenue = React.lazy(() => import("./allExamWiseRevenue"));
const ExamWiseRevenue = React.lazy(() => import("./examWiseRevenue"));
const RegisterCount = React.lazy(() => import("./registerCount"));
const ReferenceGenerated = React.lazy(() => import("./referenceGenerated"));

const BoardMeetingEdutech = React.lazy(() => import("./boardMeetingEdutech"));
const BoardMeetingPublication = React.lazy(() =>
  import("./boardMeetingPublication")
);
const BoardMeetingLeads = React.lazy(() => import("./boardMeetingLeads"));
const TeacherTaskPlanComplete = React.lazy(() =>
  import("../DataEntryPanel/teacherTaskPlanComplete")
);
const TeacherTaskPlanPending = React.lazy(() =>
  import("../DataEntryPanel/teacherTaskPlanPending")
);

const TeacherTaskPlanNotCompleted = React.lazy(() =>
  import("../DataEntryPanel/teacherTaskPlanNotCompleted")
);
const DownloadReports = React.lazy(() => import("./downloadReports"));
const YoutubeTargetPerformance = React.lazy(() =>
  import("./youtubeTargetPerformance")
);
const GetInfluencer = React.lazy(() => import("./influencer/getInfluencer"));
const StudentResult = lazy(() => import("../CategoryPanel/studentResult"));
const EmployeeDetails = lazy(() => import("./employeeDetails"));
const ResultCompilation = lazy(() =>
  import("../CategoryPanel/resultCompilation")
);

const MovementsOfMeeting = React.lazy(() =>
  import("../CategoryPanel/MovementsOfMeeting/movementsOfMeeting")
);
const EditMovementsOfMeeting = React.lazy(() =>
  import("../CategoryPanel/MovementsOfMeeting/editMovementsOfMeeting")
);
const ActiveUser = lazy(() => import("./activeUser"));
const InfluencerPayment = lazy(() =>
  import("../InfluencerPanel/Payment/InfluencerPaymentCommon")
);

function CXOPanelDashboard() {
  const { loginType } = useSelector((state) => ({
    designation: state.Auth?.userInfo?.designation,
    loginType: state.Auth?.loginType,
  }));
  console.log("loginType", loginType);
  return (
    <div className="AppBox">
      <Sidebar
        data={loginType === 2 ? CXOEmpPanelSidebarData : CXOPanelSidebarData}
      />
      <div className="dashboard">
        {
          <CommonRoutes
            loginType={loginType}
            defaultPage={<TransactionDetails />}
          >
            <Route path="registeredUser" element={<RegisteredUser />} />
            <Route path="cxobatchwiseCount" element={<CxoBatchwiseCount />} />
            <Route path="admissionDetails" element={<AdmissionDetails />} />
            <Route path="datewiseCount" element={<DatewiseCount />} />
            <Route path="revenueDetails" element={<RevenueDetails />} />
            <Route path="allSourceLead" element={<StudentCount />} />
            <Route path="websiteLeadCount" element={<WebsiteLeadCount />} />
            <Route
              path="referenceManagerCount"
              element={<ReferenceManagerCount />}
            />
            <Route
              path="referenceTeamLeadCount"
              element={<ReferenceTeamLeadCount />}
            />
            <Route
              path="referenceLeadsCount"
              element={<ReferenceLeadsCount />}
            />
            <Route path="transactionEdutech" element={<TransactionEdutech />} />
            <Route
              path="transactionEdutechOnlineCourse"
              element={<TransactionEdutechOnlineCourse />}
            />
            <Route
              path="transactionEdutechStudyMaterial"
              element={<TransactionEdutechStudyMaterial />}
            />
            <Route path="paymentPublication" element={<PaymentPublication />} />
            <Route
              path="transactionPublication"
              element={<TransactionPublication />}
            />
            <Route path="classroomStudents" element={<ClassroomStudents />} />
            <Route path="teacherFeedback" element={<TeacherFeedback />} />
            <Route path="expertSupport" element={<ExpertSupport />} />
            <Route
              path="teacherLectureFeedback"
              element={<TeacherLectureFeedback />}
            />
            <Route path="assuredRevenue" element={<AssuredRevenue />} />
            <Route
              path="secondInstallmentPaid"
              element={<SecondInstallmentPaid />}
            />
            <Route
              path="secondInstallmentDue"
              element={<SecondInstallmentDue />}
            />
            <Route path="teacherRank" element={<TeacherRanking />} />
            <Route path="studentCouponAdd" element={<StudentCouponAdd />} />
            <Route path="studentCouponEdit" element={<StudentCouponEdit />} />
            <Route path="sourceWiseAnalysis" element={<SourceAnalysis />} />
            <Route path="stateWiseAnalysis" element={<StateAnalysis />} />
            <Route path="leadOverview" element={<LeadOverview />} />
            <Route path="dailyMeeting" element={<DailyMeeting />} />
            <Route
              path="teamLeadAssignedDateAnalysis"
              element={<TeamLeadAssignedDateAnalysis />}
            />
            <Route
              path="counsellorLeadOverview"
              element={<CounsellorLeadOverview />}
            />
            <Route
              path="leadAssignedDateAnalysis"
              element={<LeadAssignedDateAnalysis />}
            />
            <Route
              path="leadAssignedStatusOverview"
              element={<LeadAssignedStatusOverview />}
            />
            <Route path="completeLeadDetail" element={<CompleteLeadDetail />} />
            <Route path="addWPConfig" element={<AddWPConfiguration />} />
            <Route path="editWPConfig" element={<EditWPConfiguration />} />
            <Route path="oldPackageExist" element={<OldPackageExist />} />
            <Route
              path="messageDeliveredStatus"
              element={<MessageDeliveredStatus />}
            />
            <Route path="transactionDetails" element={<TransactionDetails />} />
            <Route path="cxoDashboard" element={<CxoDashboard />} />
            <Route path="RevenueProjection" element={<RevenueProjection />} />
            <Route path="leadAnalysis" element={<LeadAnalysis />} />
            <Route path="cxoPayroll" element={<CxoPayRoll />} />
            <Route path="bdePerformance" element={<BdePerformance />} />
            <Route
              path="categoryPerformance"
              element={<CategoryPerformance />}
            />
            <Route
              path="socialMediaPerformance"
              element={<SocialMediaPerformance />}
            />
            <Route path="salesPerformance" element={<SalesPerformance />} />
            <Route path="overviewChannel" element={<OverviewChannel />} />
            <Route path="teacherPerformance" element={<TeacherPerformance />} />
            <Route path="activeUser" element={<ActiveUser />} />
            <Route path="allExamWiseRevenue" element={<AllExamWiseRevenue />} />
            <Route path="examWiseRevenue" element={<ExamWiseRevenue />} />
            <Route path="registerCount" element={<RegisterCount />} />
            <Route path="referenceGenerated" element={<ReferenceGenerated />} />
            <Route
              path="boardMeetingEdutech"
              element={<BoardMeetingEdutech />}
            />
            <Route
              path="boardMeetingPublication"
              element={<BoardMeetingPublication />}
            />
            <Route path="boardMeetingLeads" element={<BoardMeetingLeads />} />
            <Route
              path="teacherTaskPlanComplete"
              element={<TeacherTaskPlanComplete />}
            />
            <Route
              path="teacherTaskPlanPending"
              element={<TeacherTaskPlanPending />}
            />
            <Route
              path="teacherTaskPlanNotCompleted"
              element={<TeacherTaskPlanNotCompleted />}
            />
            <Route path="downloadReports" element={<DownloadReports />} />
            <Route
              path="youtubeTargetPerformance"
              element={<YoutubeTargetPerformance />}
            />
            <Route path="getInfluencer" element={<GetInfluencer />} />
            <Route path="resultCompilation" element={<ResultCompilation />} />
            <Route path="studentResult" element={<StudentResult />} />
            <Route path="technicalSupport" element={<TechnicalSupportNew />} />
            <Route path="payRollCtc" element={<PayRollCtc />} />
            <Route path="UpcomingBatchAnalysis" element={<BatchaAnalisis />} />
            <Route path="employeeDetails" element={<EmployeeDetails />} />
            <Route path="movementsOfMeeting" element={<MovementsOfMeeting />} />
            <Route
              path="editMovementsOfMeeting"
              element={<EditMovementsOfMeeting />}
            />
            <Route
              path="influencerPayment"
              element={<InfluencerPayment type={2} />}
            />
          </CommonRoutes>
        }
      </div>
    </div>
  );
}

export default CXOPanelDashboard;
