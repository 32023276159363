export const PaidAdsSideBarData = [
  {
    title: "LEAD DASHBOARD",
    subNav: [
      {
        title: "LEAD DASHBOARD",
        path: "leadDashboard",
        cName: "sub-nav",
      },
      {
        title: "LEAD COUNT",
        path: "LeadCount",
        cName: "sub-nav",
      },
      {
        title: "LEAD ANALYSIS",
        path: "leadAnalysis",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "TARGET STATUS",
    link: "targetStatus",
  },
  {
    title: "PAID ADS",
    link: "paidAds",
  },
];

export const PaidAdsEmpSideBarData = [
  {
    title: "EMPLOYEE DETAILS",
    subNav: [
      {
        title: "Dashboard",
        path: "dashboard",
        cName: "sub-nav",
      },
      {
        title: "My Profile",
        path: "userProfile",
        cName: "sub-nav",
      },
      {
        title: "Policies",
        path: "policy",
        cName: "sub-nav",
      },
      {
        title: "KRA/KPI",
        path: "kraKpi",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "LEAD DASHBOARD",
    subNav: [
      {
        title: "LEAD DASHBOARD",
        path: "leadDashboard",
        cName: "sub-nav",
      },
      {
        title: "LEAD COUNT",
        path: "LeadCount",
        cName: "sub-nav",
      },
      {
        title: "LEAD ANALYSIS",
        path: "leadAnalysis",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "TARGET STATUS",
    link: "targetStatus",
  },
  {
    title: "PAID ADS",
    link: "paidAds",
  },
  {
    title: "MOVEMENTS OF MEETING",
    subNav: [
      {
        title: "MOVEMENTS OF MEETING",
        path: "movementsOfMeeting",
        cName: "sub-nav",
      },
      {
        title: "EDIT/DELETE",
        path: "editMovementsOfMeeting",
        cName: "sub-nav",
      },
    ],
  },
];
