export const ResultPanelSidebarData = [
  {
    title: "ADD RESULT",
    link: "resultCompilation",
  },
  {
    title: "STUDENT RESULT",
    link: "studentResult",
  },
  {
    title: "IMPORT RESULT",
    link: "importResult",
  },
];

export const ResultPanelEmpSidebarData = [
  {
    title: "EMPLOYEE DETAILS",
    subNav: [
      {
        title: "Dashboard",
        path: "dashboard",
        cName: "sub-nav",
      },
      {
        title: "My Profile",
        path: "userProfile",
        cName: "sub-nav",
      },
      {
        title: "Policies",
        path: "policy",
        cName: "sub-nav",
      },
      {
        title: "KRA/KPI",
        path: "kraKpi",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "ADD RESULT",
    link: "resultCompilation",
  },
  {
    title: "STUDENT RESULT",
    link: "studentResult",
  },
  {
    title: "IMPORT RESULT",
    link: "importResult",
  },
];
