import React from "react";
import { useState, useEffect } from "react";
import PageTitle from "../../Module/pageTitle";
import {
  getMessageList,
  addSupportMessage,
  updateCourseSupportResolve,
  uploadSupportMedia,
  getTeacherHelpDeskMessagesList,
  addHelpDeskMessage,
  updateResolveTeacherIssue,
  getRaiseIssueMessageList,
  getRaiseIssueTeacherMessageList,
  addTeacherIssue,
  addAdminIssue,
  closeRaiseIssue,
  uploadTeacherHelpdeskImage,
  uploadIssueImage,
  issueTypeList,
  issueRemark,
  issueDuration,
  getCourseSupportList,
} from "../../Action/courseAction";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import "./chat.css";
import moment from "moment";
import DescriptionIcon from "@mui/icons-material/Description";
import { showErrorSnackbar } from "../../Action/snackbarAction";
import { useNavigate } from "react-router-dom";
import ImageIcon from "@mui/icons-material/Image";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ChatImagePopup from "../../Component/chatImagePopup";
import {
  startsWithSpace,
  containsStartsWithSpecialCharsOrStartsWithNumberOrWhiteSpace,
  length_FiveHundred,
  length_Ten,
  validateNameWithoutSpace,
} from "../../Utils/validators";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import LabelField from "../../Component/labelField";
import CustomDropdown from "../../Component/customDropdown";
import { tokenTypeList } from "../../Utils/dataConstants";
import {
  containsStartsWithSpecialCharsOrStartsWithNumberOrWhiteSpaceErrMsg,
  validateFullNameErrMsg,
} from "../../Utils/errorMsg";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SupportCard from "../../Module/supportCard";
import { useSelector } from "react-redux";

const Chat = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [openImage, setOpenImage] = useState(false);
  const [imageFull, setImageFull] = useState();
  const [open, setOpen] = useState(false);
  const [openRemark, setOpenRemak] = useState(false);
  const [openTokenType, setOpenTokenType] = useState(false);
  const [messages, setMessages] = useState([]);
  const [userId, setUserID] = useState("");
  const [message, setMessage] = useState("");
  const [query, setQuery] = React.useState(0);
  const [tokenType, setTokenType] = useState("");
  const [resetTokenType, setResetTokenType] = useState(false);
  const [issueList, setIssueList] = useState([]);
  const [issueType, setIssueType] = useState("");
  const [remark, setRemark] = useState("");
  const [suppList, setSuppList] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(1);
  const [selectedSupportID, setSelectedSupportID] = useState();

  const { userInfo } = useSelector((state) => ({
    userInfo: state.Auth?.userInfo,
  }));

  const _id = location.state?.id.toString();
  const Name = location.state?.userId;
  const status = location.state?.status;
  const screenName = location?.state?.screeName;
  const queryNo = location?.state?.query;
  const parentCourse = location.state?.baseCourseId;
  const examName = location.state?.examTypeId;
  const subExamName = location.state?.subExamTypeId;
  const startDate = location.state?.startDate;
  const endDate = location.state?.endDate;
  const teacherName = location.state?.teacherId;
  const batchName = location.state?.courseId;
  const issue = location.state?.issue;
  const subjectName = location.state?.subjectId;
  useEffect(() => {
    start();
    if (screenName === "technicalSupport") setSelectedStatus(status);
  }, []);
  const scrollBottom = () => {
    let elem = document.getElementById("messegeBox");
    elem.scrollTop = elem.scrollHeight;
  };
  const start = (cId) => {
    setSelectedSupportID(cId);
    if (screenName === "helpdesk" || screenName === "infrasupport")
      dispatch(
        getTeacherHelpDeskMessagesList(
          _id,
          location?.state?.isTeacher,
          (res) => {
            setUserID(res[0]?.helpDeskId);
            setQuery(res[0]?.queryNo);
            setMessages(res);
            scrollBottom();
          },
          () => {}
        )
      );
    else if (
      screenName === "InfraIssue" ||
      screenName === "raiseIssue" ||
      screenName === "helpdesk"
    )
      dispatch(
        getRaiseIssueMessageList(
          _id,
          {
            adminId: userInfo?._id,
          },
          (res) => {
            setUserID(res[0]?.to?._id);
            setMessages(res);
            scrollBottom();
          },
          () => {}
        )
      );
    else if (screenName === "raiseIssueTeacher")
      dispatch(
        getRaiseIssueTeacherMessageList(
          _id,
          {
            adminId: userInfo?._id,
          },
          (res) => {
            setUserID(res[0]?.from?._id);
            setMessages(res);
            scrollBottom();
          },
          () => {}
        )
      );
    else if (screenName === "helpdesk" || screenName === "infrasupport")
      dispatch(
        getTeacherHelpDeskMessagesList(
          _id,
          true,
          (res) => {
            setUserID(res[0]?.helpDeskId);
            setQuery(res[0]?.queryNo);
            setMessages(res);
            scrollBottom();
          },
          () => {}
        )
      );
    else
      dispatch(
        getMessageList(
          cId ? cId : _id,
          {
            userId: userInfo?._id,
          },
          (res) => {
            console.log("ress", res);
            setUserID(res[0]?.from?._id);
            setMessages(res);
            scrollBottom();
          },
          () => {}
        )
      );
  };

  const onBack = () => {
    if (screenName === "technicalSupport") {
      navigate("../technicalSupport", {
        state: {
          baseCourseId: parentCourse,
          screeName: "chat",
          selectedStatus,
          examTypeId: examName,
          startDate,
          endDate,
          issue,
        },
      });
    } else if (screenName === "raiseIssue") {
      navigate("../editdeleteraiseissue", {
        state: {
          startDate,
          endDate,
          baseCourseId: parentCourse,
          teacherName,
          screeName: "chat",
          status,
        },
      });
    } else if (screenName === "InfraIssue") {
      navigate("../editdeleteissue", {
        state: {
          startDate,
          endDate,
          teacherName,
          screeName: "chat",
          status,
        },
      });
    } else if (screenName === "infrasupport") {
      navigate("../infraissue", {
        state: {
          startDate,
          endDate,
          screeName: "chat",
        },
      });
    } else if (screenName === "courseSupport") {
      navigate("../courseSupport", {
        state: {
          screeName: "chat",
          status,
          baseCourseId: parentCourse,
          examTypeId: examName,
          subExamTypeId: subExamName,
          courseId: batchName,
        },
      });
    }
  };
  const handleRemark = () => {
    setOpenRemak(true);
    dispatch(
      issueTypeList((res) => {
        setIssueList(res);
      })
    );
  };
  const handleCloseRemark = () => {
    setOpenRemak(false);
  };
  const changeRemark = (value) => {
    if (value?.length >= length_FiveHundred) {
      dispatch(
        showErrorSnackbar(
          `Max Length ${length_FiveHundred} reached for this field`
        )
      );
    } else if (
      containsStartsWithSpecialCharsOrStartsWithNumberOrWhiteSpace(value)
    ) {
      dispatch(
        showErrorSnackbar(
          containsStartsWithSpecialCharsOrStartsWithNumberOrWhiteSpaceErrMsg
        )
      );
    } else if (!validateNameWithoutSpace(value)) {
      dispatch(showErrorSnackbar(validateFullNameErrMsg));
    } else {
      setRemark(value);
    }
  };
  const handelSubmit = (e) => {
    e.preventDefault();
    const postData = {
      issueTypeId: issueType?._id,
      remark: remark,
    };
    dispatch(
      issueRemark(_id, postData, (res) => {
        setOpenRemak(false);
        document.querySelectorAll("textarea").forEach((el) => (el.value = ""));
        if (res?.status === "success") {
          dispatch(
            issueDuration({
              helpDeskId: _id,
              teacherId: Name.toString(),
            })
          );
          dispatch(
            updateResolveTeacherIssue(
              userId,
              () => {
                if (screenName === "infrasupport") {
                  navigate("../infraissue", {
                    state: {
                      startDate,
                      endDate,
                      screeName: "chat",
                    },
                  });
                } else if (screenName === "helpdesk") {
                  navigate("../helpdesk");
                }
                // navigate("../teachersupport");
              },
              () => {}
            )
          );
        }
      })
    );
  };
  const handleClose = () => {
    setOpenImage(false);
  };
  const handleCloseTokenType = () => {
    setOpenTokenType(false);
  };

  const handleOnClick = () => {
    setOpenImage(true);
  };

  const changeTokenType = (value) => {
    setTokenType(value);
    setResetTokenType(false);
  };

  const handleNewUserMessage = () => {
    if (screenName === "helpdesk" || screenName === "infrasupport") {
      const data = {
        adminId: userInfo?._id,
        queryNo: query,
        issueMessage: message,
        helpDeskId: userId,
      };
      dispatch(
        addHelpDeskMessage(
          data,
          location.state?.isTeacher,
          (res) => {
            if (res) {
              setMessage("");
              start();
            }
          },
          () => {}
        )
      );
    } else if (screenName === "raiseIssueTeacher") {
      const data = {
        adminId: userInfo?._id,
        raiseIssueId: _id,
        issueMessage: message,
        queryNo,
      };
      dispatch(
        addTeacherIssue(
          data,
          (res) => {
            if (res) {
              setMessage("");
              start();
            }
          },
          () => {}
        )
      );
    } else if (screenName === "raiseIssue" || screenName === "InfraIssue") {
      const data = {
        adminId: userInfo?._id,
        raiseIssueId: _id,
        issueMessage: message,
        queryNo,
      };
      dispatch(
        addAdminIssue(
          data,
          (res) => {
            if (res) {
              setMessage("");
              start();
            }
          },
          () => {}
        )
      );
    } else {
      const data = {
        adminId: userInfo?._id,
        message: message,
        messageType: 1,
        supportId: _id,
        to: userId ? userId : Name?._id,
      };
      dispatch(
        addSupportMessage(
          data,
          (res) => {
            if (res) {
              setMessage("");
              start();
            }
          },
          () => {}
        )
      );
    }
  };

  const handleChange = (e, val) => {
    setOpen(!open);
    const fileSizeKiloBytes = e.target.files[0].size / 1024;
    const fileSizeKiloBytesPdf = e.target.files[0].size / 1024;
    if (parseInt(fileSizeKiloBytes) > 15360 && val === 1) {
      dispatch(showErrorSnackbar("Image size should be less than 15 MB!!!"));
    } else if (parseInt(fileSizeKiloBytesPdf) > 10240 && val === 2) {
      dispatch(showErrorSnackbar("File size should be less than 10 MB!!!"));
    } else {
      if (e.target?.files?.length > 0) {
        uploadMedia(e.target.files[0], val);
      }
    }
  };

  const uploadMedia = async (val, type) => {
    if (screenName === "helpdesk" || screenName === "infrasupport") {
      dispatch(
        uploadTeacherHelpdeskImage(val, (res) => {
          const data = {
            adminId: userInfo?._id,
            queryNo: query,
            // issueMessage: message,
            helpDeskId: userId,
            image: res?.file,
          };
          dispatch(
            addHelpDeskMessage(
              data,
              location.state?.isTeacher,
              (res) => {
                if (res) {
                  setMessage("");
                  start();
                }
              },
              () => {}
            )
          );
        })
      );
    } else if (screenName === "raiseIssue" || screenName === "InfraIssue") {
      dispatch(
        uploadIssueImage(val, (imageResponse) => {
          const data = {
            adminId: userInfo?._id,
            raiseIssueId: _id,
            queryNo,
            image: imageResponse?.file,
          };
          dispatch(
            addAdminIssue(
              data,
              (res) => {
                if (res) {
                  setMessage("");
                  start();
                }
              },
              () => {}
            )
          );
        })
      );
    } else if (screenName === "raiseIssueTeacher") {
      dispatch(
        uploadIssueImage(val, (imageResponse) => {
          const data = {
            adminId: userInfo?._id,
            raiseIssueId: _id,
            queryNo,
            image: imageResponse?.file,
          };
          dispatch(
            addTeacherIssue(
              data,
              (res) => {
                if (res) {
                  setMessage("");
                  start();
                }
              },
              () => {}
            )
          );
        })
      );
    } else {
      dispatch(
        uploadSupportMedia(val, type, (res) => {
          if (res) {
            const data = {
              adminId: userInfo?._id,
              messageType: 2,
              supportId: _id,
              to: userId ? userId : Name?._id,
              mediaFile: res,
            };
            data["mediaFile"].type = type;
            dispatch(
              addSupportMessage(data, () => {
                setMessage("");
                start();
              })
            );
          }
        })
      );
    }
  };

  const openPdf = (link) => {
    window.open(link);
  };

  const handleResolveIssue = (IssueId) => {
    if (screenName === "helpdesk" || screenName === "infrasupport")
      handleRemark();
    else if (screenName === "raiseIssue" || screenName === "InfraIssue") {
      handleRemark();
      dispatch(
        closeRaiseIssue(
          `${IssueId}`,
          () => {
            // navigate("../editDeleteRaiseIssue");
            if (screenName === "raiseIssue") {
              navigate("../editdeleteraiseissue", {
                state: {
                  startDate,
                  endDate,
                  baseCourseId: parentCourse,
                  teacherName,
                  screeName: "chat",
                  status,
                },
              });
            } else if (screenName === "InfraIssue") {
              navigate("../editdeleteraiseissue", {
                state: {
                  startDate,
                  endDate,
                  teacherName,
                  screeName: "chat",
                  status,
                },
              });
            }
          },
          () => {}
        )
      );
    } else {
      if (screenName === "technicalSupport") {
        setOpenTokenType(true);
      } else {
        dispatch(
          updateCourseSupportResolve(
            {
              adminId: userInfo?._id,
            },
            IssueId,
            () => {
              if (screenName === "courseSupport") {
                navigate("../courseSupport", {
                  state: {
                    screeName: "chat",
                    status,
                  },
                });
              } else {
                navigate("../technicalSupport", {
                  state: {
                    baseCourseId: parentCourse,
                    screeName: "chat",
                    selectedStatus,
                    examTypeId: examName,
                    startDate,
                    endDate,
                    issue,
                  },
                });
              }
              if (screenName === "DoubtSolveReview") {
                navigate("../DoubtSolveReview");
              }
            },
            () => {}
          )
        );
      }
    }
  };

  const validations = () => {
    return message?.length == 0;
  };

  const saveAndUpdate = (IssueId) => {
    let postData = {
      issue: tokenType?.value,
      adminId: userInfo?._id,
    };
    dispatch(
      updateCourseSupportResolve(
        postData,
        IssueId,
        () => {
          navigate("../technicalSupport", {
            state: {
              baseCourseId: parentCourse,
              screeName: "chat",
              selectedStatus,
              examTypeId: examName,
              startDate,
              endDate,
              issue,
            },
          });
        },
        () => {}
      )
    );
  };

  useEffect(() => {
    if (screenName === "technicalSupport") {
      const reqBody = {
        skip: 0,
        startDate,
        endDate,
        status: selectedStatus,
        supportType: 1,
      };
      if (examName?._id) reqBody.examTypeId = examName?._id;
      if (parentCourse?._id) reqBody.baseCourseId = parentCourse?._id;
      dispatch(
        getCourseSupportList(reqBody, (res) => {
          if (res?.data?.data?.length > 0) setSuppList(res?.data?.data);
          else setSuppList([]);
        })
      );
    } else {
      const postdata = {
        skip: 0,
        supportType: 2,
        status: selectedStatus,
        baseCourseId: parentCourse?._id,
        examTypeId: examName?._id,
        // courseId: batchName?._id,
      };
      if (subExamName) {
        postdata["subExamTypeId"] = subExamName?._id;
      }
      if (subjectName) {
        postdata["subjectId"] = subjectName?._id;
      }
      dispatch(
        getCourseSupportList(postdata, (res) => {
          if (res?.data?.data?.length > 0) setSuppList(res?.data?.data);
          else setSuppList([]);
        })
      );
    }
  }, [selectedStatus]);

  const showData = (val) => {
    setSelectedStatus(val);
  };

  return (
    <div className="contentBox">
      <div className="row mx-0">
        <div className="col-sm-9 p-0 mb-2">
          <div className="p-lg-3 pb-lg-0">
            <div className="shadow-none rounded">
              <PageTitle name="Chat" />
            </div>
            <div className="userInfo d-flex msgheader bg-blue border-0 Title shadow-none p-0">
              <h5 className="text-white">
                {screenName === "technicalSupport" ||
                screenName === "raiseIssue" ||
                screenName === "InfraIssue" ||
                screenName === "infrasupport" ||
                screenName === "courseSupport" ? (
                  <button
                    type="submit"
                    className="nav-btn-bgcolor border-0 mx-4"
                    onClick={() => onBack()}
                  >
                    <ArrowBackIosIcon />
                  </button>
                ) : null}
                {screenName === "helpdesk" ||
                screenName === "raiseIssue" ||
                screenName === "raiseIssueTeacher" ||
                screenName === "infrasupport" ||
                screenName === "InfraIssue"
                  ? `${Name?.name}`
                  : `${Name?.firstName} ${Name?.lastName}`}
              </h5>
              <div>
                {status !== 3 ? (
                  <>
                    {screenName === "helpdesk" ||
                    screenName === "raiseIssue" ||
                    screenName === "infrasupport" ||
                    screenName === "raiseIssueTeacher" ||
                    screenName === "InfraIssue" ? (
                      <>
                        {!location?.state?.isTeacher ? (
                          <button
                            className="add_Question-btn btn btn btn-success btn-md me-2"
                            onClick={() => handleResolveIssue(_id)}
                          >
                            Close Issue
                          </button>
                        ) : null}
                      </>
                    ) : (
                      <button
                        className="add_Question-btn btn btn btn-success btn-md me-2"
                        onClick={() => handleResolveIssue(_id)}
                      >
                        Close Issue
                      </button>
                    )}
                    <button
                      type="submit"
                      className="btn btn-primary submitbtn rounded-pill me-2"
                      onClick={() => start()}
                    >
                      Refresh
                    </button>
                  </>
                ) : null}
              </div>
            </div>
            <div className="messegeBox p-2 border" id="messegeBox">
              {messages !== undefined
                ? messages.map((item) => {
                    const {
                      message,
                      createdAt,
                      issueMessage,
                      mediaFile,
                      messageFrom,
                      from,
                      image,
                    } = item;

                    <div>
                      {moment
                        .utc(item.createdAt)
                        .format("DD-MMM-YYYY, hh:mm A")}
                    </div>;
                    if (screenName === "helpdesk") {
                      if (messageFrom === (location?.state?.isTeacher ? 1 : 2))
                        return (
                          <>
                            <div className="requestMsg">
                              {image ? (
                                <div
                                  onClick={() => {
                                    handleOnClick();
                                    setImageFull(image);
                                  }}
                                >
                                  <img
                                    className="chatimg"
                                    src={image}
                                    alt={image}
                                  />
                                </div>
                              ) : null}
                              <div className="text-dark font-16">
                                {issueMessage}
                              </div>
                              <div className="font-11">
                                {" "}
                                {moment(createdAt).format(
                                  "DD-MMM-YYYY, hh:mm A"
                                )}{" "}
                              </div>
                            </div>
                          </>
                        );
                      else
                        return (
                          <>
                            <div className="responseMsg">
                              {image ? (
                                <div
                                  onClick={() => {
                                    handleOnClick();
                                    setImageFull(image);
                                  }}
                                >
                                  <img
                                    className="chatimg"
                                    src={image}
                                    alt={image}
                                  />
                                </div>
                              ) : null}
                              <div className="text-dark font-16">
                                {issueMessage}
                              </div>
                              <div className="font-11-req-msg">
                                {" "}
                                {moment(createdAt).format(
                                  "DD-MMM-YYYY, hh:mm A"
                                )}{" "}
                              </div>
                            </div>
                          </>
                        );
                    } else if (
                      screenName === "helpdesk" ||
                      screenName === "infrasupport"
                    ) {
                      if (messageFrom === 2)
                        return (
                          <>
                            <div className="requestMsg">
                              {image ? (
                                <div
                                  onClick={() => {
                                    handleOnClick();
                                    setImageFull(image);
                                  }}
                                >
                                  <img
                                    className="chatimg"
                                    src={image}
                                    alt={image}
                                  />
                                </div>
                              ) : null}
                              <div className="text-dark font-16">
                                {issueMessage}
                              </div>
                              <div className="font-11">
                                {" "}
                                {moment(createdAt).format(
                                  "DD-MMM-YYYY, hh:mm A"
                                )}{" "}
                              </div>
                            </div>
                          </>
                        );
                      else
                        return (
                          <>
                            <div className="responseMsg">
                              {image ? (
                                <div
                                  onClick={() => {
                                    handleOnClick();
                                    setImageFull(image);
                                  }}
                                >
                                  <img
                                    className="chatimg"
                                    src={image}
                                    alt={image}
                                  />
                                </div>
                              ) : null}
                              <div className="text-dark font-16">
                                {issueMessage}
                              </div>
                              <div className="font-11-req-msg">
                                {" "}
                                {moment(createdAt).format(
                                  "DD-MMM-YYYY, hh:mm A"
                                )}{" "}
                              </div>
                            </div>
                          </>
                        );
                    } else if (from?._id !== userId) {
                      if (
                        item.messageType === 1 ||
                        screenName === "raiseIssue" ||
                        screenName === "raiseIssueTeacher" ||
                        screenName === "InfraIssue"
                      ) {
                        return (
                          <>
                            <div className="requestMsg">
                              {image ? (
                                <div
                                  onClick={() => {
                                    handleOnClick();
                                    setImageFull(image);
                                  }}
                                >
                                  <img
                                    className="chatimg"
                                    src={image}
                                    alt={image}
                                  />
                                </div>
                              ) : null}

                              <div className="text-dark font-16">{message}</div>
                              <div className="font-11">
                                {" "}
                                {moment(createdAt).format(
                                  "DD-MMM-YYYY, hh:mm A"
                                )}{" "}
                              </div>
                            </div>
                          </>
                        );
                      } else {
                        const filename = mediaFile?.originalName;
                        return (
                          <>
                            <div className="requestMsg">
                              <div>
                                {filename && filename.includes("pdf") ? (
                                  <div
                                    className="cursor"
                                    onClick={() => openPdf(mediaFile?.file)}
                                  >
                                    <PictureAsPdfIcon />
                                    <span className="d-block">
                                      {mediaFile?.originalName}
                                    </span>
                                  </div>
                                ) : filename && filename.includes("mp3") ? (
                                  <>
                                    <div>
                                      <audio controls>
                                        <source
                                          src={mediaFile?.file}
                                          type="audio/ogg"
                                        />
                                      </audio>
                                    </div>
                                  </>
                                ) : (
                                  <div>
                                    <div
                                      onClick={() => {
                                        handleOnClick();
                                        setImageFull(mediaFile);
                                      }}
                                    >
                                      <img
                                        className="chatimg"
                                        src={mediaFile?.file}
                                        alt={mediaFile?.originalName}
                                      />
                                    </div>
                                  </div>
                                )}
                                <div className="font-11">
                                  {moment(createdAt).format(
                                    "DD-MMM-YYYY, hh:mm A"
                                  )}
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      }
                    } else {
                      if (
                        item.messageType === 1 ||
                        screenName === "raiseIssue" ||
                        screenName === "raiseIssueTeacher" ||
                        screenName === "InfraIssue"
                      ) {
                        return (
                          <>
                            <div className="responseMsg">
                              {image ? (
                                <div
                                  onClick={() => {
                                    handleOnClick();
                                    setImageFull(image);
                                  }}
                                >
                                  <img
                                    className="chatimg"
                                    src={image}
                                    alt={image}
                                  />
                                </div>
                              ) : null}
                              <div className="text-dark font-16">{message}</div>
                              <div className="font-11-req-msg">
                                {" "}
                                {moment(createdAt).format(
                                  "DD-MMM-YYYY, hh:mm A"
                                )}{" "}
                              </div>
                            </div>
                          </>
                        );
                      } else {
                        const filename = mediaFile?.originalName;

                        return (
                          <>
                            <div className="responseMsg">
                              <div>
                                {filename && filename.includes("pdf") ? (
                                  <div
                                    className="cursor"
                                    onClick={() => openPdf(mediaFile?.file)}
                                  >
                                    <PictureAsPdfIcon />
                                    <span className="d-block">
                                      {mediaFile?.originalName}
                                    </span>
                                  </div>
                                ) : filename && filename.includes("mp3") ? (
                                  <>
                                    <div>
                                      <audio
                                        controls
                                        controlsList="nodownload noplaybackrate"
                                      >
                                        <source
                                          src={mediaFile?.file}
                                          type="audio/ogg"
                                        />
                                      </audio>
                                    </div>
                                  </>
                                ) : (
                                  <div>
                                    <div
                                      onClick={() => {
                                        handleOnClick();
                                        setImageFull(mediaFile);
                                      }}
                                    >
                                      <img
                                        className="chatimg"
                                        src={mediaFile?.file}
                                        alt={mediaFile?.originalName}
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className="font-11-req-msg">
                                {moment(createdAt).format(
                                  "DD-MMM-YYYY, hh:mm A"
                                )}
                              </div>
                            </div>
                          </>
                        );
                      }
                    }
                  })
                : null}
            </div>
            {status !== 3 ? (
              <div className="chatBottom border px-1">
                <div className="removelabel w-100">
                  <textarea
                    rows="3"
                    className="form-control border border-0"
                    placeholder={"Enter Messege Here"}
                    onChange={(e) => {
                      if (startsWithSpace(e.target.value)) {
                        dispatch(showErrorSnackbar("space not allowed"));
                        return;
                      } else {
                        setMessage(e.target.value);
                      }
                    }}
                    maxLength={length_FiveHundred}
                    minLength={length_Ten}
                    value={message}
                  />
                </div>
                <span className="ms-2 mt-2 d-flex align-items-center">
                  <span className="p-3 position-absolute rounded d-flex justify-content-between">
                    <span className="text-center">
                      <p className="mediaicon">
                        <span className="mx-2 cursor">
                          <label htmlFor="upload-button" className="cursor">
                            <ImageIcon />
                          </label>
                          <input
                            type="file"
                            id="upload-button"
                            style={{ display: "none" }}
                            onChange={(e) => handleChange(e, 1)}
                            accept="image/png, image/gif, image/jpeg"
                          />
                        </span>
                      </p>
                    </span>
                    {screenName === "raiseIssue" ||
                    screenName === "raiseIssueTeacher" ||
                    screenName === "InfraIssue" ||
                    screenName === "helpdesk" ||
                    screenName === "infrasupport" ? null : (
                      <span className="text-center ms-3">
                        <p className="mediaicon">
                          <span className="mx-2 cursor">
                            <label htmlFor="upload-button1" className="cursor">
                              <DescriptionIcon />
                            </label>
                            <input
                              type="file"
                              id="upload-button1"
                              style={{ display: "none" }}
                              onChange={(e) => handleChange(e, 2)}
                              accept=".pdf"
                            />
                          </span>
                        </p>
                      </span>
                    )}
                  </span>
                  <div className="text-end me-2">
                    <button
                      type="submit"
                      className="btn btn-primary submitbtn rounded-pill"
                      onClick={handleNewUserMessage}
                      disabled={validations()}
                    >
                      Send
                    </button>
                  </div>
                </span>
              </div>
            ) : null}
          </div>
        </div>
        <div className="col-sm-3 p-0 border border-1 rounded mb-2 mt-3">
          <div className="">
            <div className="d-flex justify-content-center align-item-center py-1">
              <h3>Support Chat</h3>
            </div>
            <div className="px-2">
              <ul className="nav d-flex mb-3 w-100 py-2">
                <li className="flex-fill " onClick={() => showData(1)}>
                  <button
                    className={`border-0 w-100 rounded-start p-2  ${
                      selectedStatus === 1 ? "nav-btn-bgcolor" : ""
                    }`}
                  >
                    New Issue
                  </button>
                </li>
                <li className="flex-fill " onClick={() => showData(2)}>
                  <button
                    className={`border-0 w-100 p-2 ${
                      selectedStatus === 2 ? "nav-btn-bgcolor" : ""
                    }`}
                  >
                    In progress
                  </button>
                </li>
                <li className="flex-fill" onClick={() => showData(3)}>
                  <button
                    className={`border-0 w-100 rounded-end p-2 ${
                      selectedStatus === 3 ? "nav-btn-bgcolor" : ""
                    }`}
                  >
                    Resolved
                  </button>
                </li>
              </ul>
            </div>
            <div className="p-2 ">
              {suppList?.length !== 0 ? (
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade active show"
                    id="pills-new"
                    role="tabpanel"
                    aria-labelledby="pills-new-tab"
                    tabIndex="0"
                  >
                    {suppList?.map((item, index) => {
                      return (
                        <SupportCard
                          data={item}
                          key={index}
                          clickhandle={() => start(item?._id)}
                          selectedSupportID={selectedSupportID}
                        />
                      );
                    })}
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-inprogress"
                    role="tabpanel"
                    aria-labelledby="pills-inprogress-tab"
                    tabIndex="0"
                  >
                    {suppList?.map((item, index) => {
                      return (
                        <SupportCard
                          data={item}
                          key={index}
                          clickhandle={() => start(item?._id)}
                        />
                      );
                    })}
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-resolved"
                    role="tabpanel"
                    aria-labelledby="pills-resolved-tab"
                    tabIndex="0"
                  >
                    {suppList?.map((item, index) => {
                      return <SupportCard key={index} data={item} />;
                    })}
                  </div>
                </div>
              ) : (
                <div className=""> NoIssueFound</div>
              )}
            </div>
          </div>
        </div>
      </div>
      <ChatImagePopup
        open={openImage}
        handleClose={handleClose}
        imageFull={imageFull}
      />
      {openTokenType ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className="model"
          open={openTokenType}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openTokenType}>
            <div className="bookstockmodeldiv p-3">
              <div className="col mb-4 d-flex justify-content-between ">
                <p className="fw-bold">
                  <span style={{ color: "#3F51B5" }}> Task Token Update</span>
                </p>
                <ClearOutlinedIcon
                  className="colorPurple cursor"
                  onClick={handleCloseTokenType}
                />
              </div>
              <div className="form-check">
                <div className="d-flex mb-2 align-items-center">
                  <LabelField labelName={"Token Type"} />
                  <CustomDropdown
                    labelName="Select Token Type"
                    setInputText={(value) => changeTokenType(value)}
                    options={tokenTypeList}
                    reset={resetTokenType}
                    Isrequired={true}
                    defaultValue={tokenType?._id}
                  />
                </div>

                <div className="col-auto d-flex justify-content-center mt-5">
                  <button
                    onClick={() => saveAndUpdate(_id)}
                    type="submit"
                    className={"btn mb-3 submitbtn btn-primary"}
                  >
                    Save and Update
                  </button>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
      ) : null}
      {openRemark ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className="model"
          open={openRemark}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openRemark}>
            <div className="modeldiv p-3">
              <div className="col mb-4 d-flex justify-content-between ">
                <h6 className="colorPurple fw-bold">Remark</h6>
                <ClearOutlinedIcon
                  className="colorPurple cursor"
                  onClick={handleCloseRemark}
                />
              </div>
              <div className="row px-5 form-check">
                <form onSubmit={handelSubmit}>
                  <div>
                    <div className="d-flex align-items-center me-3 ">
                      <LabelField labelName={"Issue Type"} />
                      <CustomDropdown
                        setInputText={(value) => {
                          setIssueType(value);
                        }}
                        value={issueType}
                        options={issueList}
                        labelName="Select Issue Type"
                        Isrequired={true}
                        // reset={resetCategoryType}
                      />
                    </div>
                    <div className="d-flex align-items-center me-3">
                      <LabelField labelName={"Remark"} />
                      <textarea
                        className="form-control"
                        placeholder={"Enter Your Remark Here!"}
                        onChange={(e) => changeRemark(e.target.value)}
                        maxLength={length_FiveHundred}
                        minLength={length_Ten}
                        value={remark}
                      />
                      <span className="required ms-2 ">*</span>
                    </div>
                  </div>

                  <div className="d-flex justify-content-evenly mt-5">
                    <button
                      type="submit"
                      className="btn btn-primary px-5 cursor"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Fade>
        </Modal>
      ) : null}
    </div>
  );
};
export default Chat;
