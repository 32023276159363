import "../../App.css";
import React, { Suspense } from "react";
import Sidebar from "../../Module/sidebar";
import { Route, Routes } from "react-router-dom";
import Loading from "../../Component/Loading";
import {
  TechSupportEmpSidebarData,
  TechSupportSidebarData,
} from "../../Module/techSupportSidebarData";
import Chat from "../TeacherPanel/chat";
import UserDetails from "./userDetails";
import { useSelector } from "react-redux";
import CommonRoutes from "../Common/commonRoute";

const TechnicalSupport = React.lazy(() => import("./technicalSupportNew"));
const RegistrationDetails = React.lazy(() =>
  import("../CounsellorPanel/registrationDetails")
);
const DoubtSolveReview = React.lazy(() => import("./doubtSolveReview"));
const TeacherSupport = React.lazy(() => import("./teacherSupport"));
const MultiDeviceTokenClear = React.lazy(() =>
  import("./MultiDeviceTokenClear")
);
const LiveClassSchedule = React.lazy(() => import("./liveClassSchedule"));
const ExpertSupport = React.lazy(() => import("./expertSupport"));

const TransactionEdutech = React.lazy(() =>
  import("../CXOPanel/transactionEdutech")
);
const TransactionEdutechStudyMaterial = React.lazy(() =>
  import("../CXOPanel/transactionEdutechStudyMaterial")
);
const MovementsOfMeeting = React.lazy(() =>
  import("../CategoryPanel/MovementsOfMeeting/movementsOfMeeting")
);
const EditMovementsOfMeeting = React.lazy(() =>
  import("../CategoryPanel/MovementsOfMeeting/editMovementsOfMeeting")
);

function TechnicalSupportPanelDashboard() {
  const { loginType } = useSelector((state) => ({
    designation: state.Auth?.userInfo?.designation,
    loginType: state.Auth?.loginType,
  }));
  console.log("loginType", loginType);
  return (
    <div className="AppBox">
      <Sidebar
        data={
          loginType === 2 ? TechSupportEmpSidebarData : TechSupportSidebarData
        }
      />
      <div className="dashboard">
        {
          <CommonRoutes
            loginType={loginType}
            defaultPage={<TechnicalSupport />}
          >
            <Route path="/" element={<TechnicalSupport />} />
            <Route path="technicalSupport" element={<TechnicalSupport />} />
            <Route
              path="registrationDetails"
              element={<RegistrationDetails />}
            />
            <Route path="doubtSolveReview" element={<DoubtSolveReview />} />
            <Route path="teacherSupport" element={<TeacherSupport />} />
            <Route path="/Chat" element={<Chat />} />
            <Route
              path="MultiDeviceTokenClear"
              element={<MultiDeviceTokenClear />}
            />
            <Route path="liveclassschedule" element={<LiveClassSchedule />} />
            <Route path="userDetails" element={<UserDetails />} />
            <Route path="expertSupport" element={<ExpertSupport />} />
            <Route path="transactionEdutech" element={<TransactionEdutech />} />
            <Route
              path="transactionEdutechStudyMaterial"
              element={<TransactionEdutechStudyMaterial />}
            />
            <Route path="movementsOfMeeting" element={<MovementsOfMeeting />} />
            <Route
              path="editMovementsOfMeeting"
              element={<EditMovementsOfMeeting />}
            />
          </CommonRoutes>
        }
      </div>
    </div>
  );
}

export default TechnicalSupportPanelDashboard;
