export const ManagerSidebarData = [
  {
    title: "REGISTRATION DETAILS",
    link: "registrationdetails",
  },
  {
    title: "MULTI-DEVICE TOKEN CLEAR",
    link: "MultiDeviceTokenClear",
  },
];

export const ManagerEmpSidebarData = [
  {
    title: "EMPLOYEE DETAILS",
    subNav: [
      {
        title: "Dashboard",
        path: "dashboard",
        cName: "sub-nav",
      },
      {
        title: "My Profile",
        path: "userProfile",
        cName: "sub-nav",
      },
      {
        title: "Policies",
        path: "policy",
        cName: "sub-nav",
      },
      {
        title: "KRA/KPI",
        path: "kraKpi",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "REGISTRATION DETAILS",
    link: "registrationdetails",
  },
  {
    title: "MULTI-DEVICE TOKEN CLEAR",
    link: "MultiDeviceTokenClear",
  },
];
