export const InfraPanelSidebarData = [
  {
    title: "RAISE ISSUE",
    subNav: [
      {
        title: "Create",
        path: "createissue",
        cName: "sub-nav",
      },
      {
        title: "ISSUE LIST",
        path: "editdeleteissue",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "INFRA SUPPORT",
    link: "infraissue",
  },
  // {
  //   title: "EMPLOYEE ASSETS",
  //   link: "employeeAsset",
  // },
  {
    title: "EMPLOYEE ASSETS",
    subNav: [
      {
        title: "Create",
        path: "createEmployeeAsset",
        cName: "sub-nav",
      },
      {
        title: "LIST",
        path: "listEmployeeAsset",
        cName: "sub-nav",
      },
    ],
  },
];

export const InfraEmpPanelSidebarData = [
  {
    title: "EMPLOYEE DETAILS",
    subNav: [
      {
        title: "Dashboard",
        path: "dashboard",
        cName: "sub-nav",
      },
      {
        title: "My Profile",
        path: "userProfile",
        cName: "sub-nav",
      },
      {
        title: "Policies",
        path: "policy",
        cName: "sub-nav",
      },
      {
        title: "KRA/KPI",
        path: "kraKpi",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "RAISE ISSUE",
    subNav: [
      {
        title: "Create",
        path: "createissue",
        cName: "sub-nav",
      },
      {
        title: "ISSUE LIST",
        path: "editdeleteissue",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "INFRA SUPPORT",
    link: "infraissue",
  },
  // {
  //   title: "EMPLOYEE ASSETS",
  //   link: "employeeAsset",
  // },
  {
    title: "EMPLOYEE ASSETS",
    subNav: [
      {
        title: "Create",
        path: "createEmployeeAsset",
        cName: "sub-nav",
      },
      {
        title: "LIST",
        path: "listEmployeeAsset",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "MOVEMENTS OF MEETING",
    subNav: [
      {
        title: "MOVEMENTS OF MEETING",
        path: "movementsOfMeeting",
        cName: "sub-nav",
      },
      {
        title: "EDIT/DELETE",
        path: "editMovementsOfMeeting",
        cName: "sub-nav",
      },
    ],
  },
];
