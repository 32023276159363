/* eslint-disable react/prop-types */
import React, { useState } from "react";
import "../App.css";
import "../index.css";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import moment from "moment";
import { deleteStock } from "../Action/courseAction";
import { useDispatch } from "react-redux";

function OrderHistoryPopup(props) {
  const dispatch = useDispatch();
  const [name, setname] = useState("");
  const allowOnlyOneSpace = (str) => {
    return str.endsWith(" ") ? str.trim() + " " : str.trim();
  };
  const onChange = (e) => {
    setname(allowOnlyOneSpace(e.target.value));
  };

  const validations = () => {
    return name === "";
  };
  const Close = () => {
    props.handleClose();
  };

  const deleteorder = () => {
    dispatch(
      deleteStock({ deletedBy: name }, props?.data?._id, () => {
        props.handleClose();
      })
    );
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="modelChat"
      open={props.open}
      onClose={() => {
        props.handleClose();
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.open}>
        <div className="modeldivChat p-md-4 py-md-4 bgwhite">
          <div className="m-2  bgwhite">
            <div
              className={`col mb-4 d-flex  ${
                props.isDelete
                  ? "justify-content-between"
                  : "justify-content-end"
              }`}
            >
              {props.isDelete ? (
                <h4 className="fw-bold me-4 colorPurple">
                  Are you sure, you want to delete below stock details
                </h4>
              ) : null}
              <ClearOutlinedIcon
                className="colorPurple cursor"
                onClick={() => {
                  props.handleClose();
                }}
              />
            </div>
            <div>
              <table className="table">
                <tr>
                  <td className="fw-bold">Book Name:</td>
                  <td>{props?.bookName}</td>
                </tr>
                <tr>
                  <td className="fw-bold">Stock In Date:</td>
                  <td>{moment.utc(props?.data?.updatedAt).format("ll")}</td>
                </tr>
                <tr>
                  <td className="fw-bold">Added By:</td>
                  <td>{props?.data?.addedBy}</td>
                </tr>
                <tr>
                  <td className="fw-bold">Add Quantity:</td>
                  <td>{props?.data?.quantity}</td>
                </tr>
                <tr>
                  <td className="fw-bold">Printer Name:</td>
                  <td>{props?.data?.printerName}</td>
                </tr>
                <tr>
                  <td className="fw-bold">Total Amount:</td>
                  <td>{props?.data?.totalAmount}</td>
                </tr>
                {props.isDelete ? (
                  <tr>
                    <td className="fw-bold">Deleted By:</td>
                    <td>
                      <input
                        type="text"
                        placeholder={"Name"}
                        value={name}
                        className="form-control inputField m-0"
                        onChange={(e) => onChange(e)}
                      />
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td className="fw-bold">Invoice Image:</td>
                    <td>
                      <img
                        className="orderHistoryPopupimg"
                        src={props?.data?.invoiceImage}
                        alt={props?.data?.invoiceImage}
                      />
                    </td>
                  </tr>
                )}
              </table>
            </div>
            {props.isDelete ? (
              <div className="d-flex justify-content-between me-3">
                <button
                  type="button"
                  className="btn mb-3 btn-secondary py-1 px-5 rounded-pill"
                  onClick={Close}
                >
                  {" "}
                  Cancel{" "}
                </button>
                <button
                  type="button"
                  className={`btn mb-3 py-1 px-5 rounded-pill ${
                    validations() ? "btn-secondary" : "btn-danger"
                  }`}
                  disabled={validations()}
                  onClick={deleteorder}
                >
                  {" "}
                  Delete{" "}
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

export default OrderHistoryPopup;
