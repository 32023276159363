import React, { lazy } from "react";
import Sidebar from "../../Module/sidebar";
import { Route } from "react-router";
import { useSelector } from "react-redux";
import {
  DigitalMarketingEmpData,
  DigitalMarketingManagerData,
} from "../../Module/DigitalMarketingPanel/digitalMarketingSidebarData";
import CommonRoutes from "../Common/commonRoute";

const PaidAds = lazy(() => import("../PaidAdsPanel/paidAds"));
const SocialAds = lazy(() => import("../SocialMedialPanel/socialAds"));
const LeadCountDigital = lazy(() => import("./leadCountDigital"));
const YoutubePerformance = lazy(() =>
  import("./Youtube/youtubePerformanceDigital")
);
const OffTopicPerformance = lazy(() =>
  import("./Youtube/offTopicPerformanceDigital")
);
const TargetStatusDigital = lazy(() =>
  import("./DigitalTarget/targetStatusDigital")
);
const CreateDigitalTarget = lazy(() =>
  import("./DigitalTarget/createDigitalTarget")
);
const SocialMediaTeam = lazy(() =>
  import("../CXOPanel/socialMediaPerformance")
);
const YoutubeTarget = lazy(() =>
  import("../CXOPanel/youtubeTargetPerformance")
);
const DigitalLeadAnalysis = lazy(() => import("./leadAnalysisDigital"));
const MovementsOfMeeting = React.lazy(() =>
  import("../CategoryPanel/MovementsOfMeeting/movementsOfMeeting")
);
const EditMovementsOfMeeting = React.lazy(() =>
  import("../CategoryPanel/MovementsOfMeeting/editMovementsOfMeeting")
);
const FacebookGroup = lazy(() => import("./facebookGroup"));
const ShopifyPayment = lazy(() => import("./shopifyPayment"));

const DigitalMarketingDashboard = () => {
  const { loginType } = useSelector((state) => ({
    loginType: state.Auth?.loginType,
  }));
  return (
    <div className="AppBox">
      <Sidebar
        data={
          loginType === 2
            ? DigitalMarketingEmpData
            : DigitalMarketingManagerData
        }
      />
      <div className="dashboard">
        {
          <CommonRoutes loginType={loginType} defaultPage={<PaidAds />}>
            <Route path="paidAds" element={<PaidAds />} />
            <Route path="socialAds" element={<SocialAds />} />
            <Route path="leadCountDigital" element={<LeadCountDigital />} />
            <Route path="youtubePerformance" element={<YoutubePerformance />} />
            <Route
              path="offTopicPerformance"
              element={<OffTopicPerformance />}
            />
            <Route
              path="createDigitalTarget"
              element={<CreateDigitalTarget />}
            />
            <Route
              path="targetStatusDigital"
              element={<TargetStatusDigital />}
            />
            <Route path="socialMediaTeam" element={<SocialMediaTeam />} />
            <Route path="youtubeTarget" element={<YoutubeTarget />} />
            <Route
              path="digitalLeadAnalysis"
              element={<DigitalLeadAnalysis />}
            />
            <Route path="movementsOfMeeting" element={<MovementsOfMeeting />} />
            <Route
              path="editMovementsOfMeeting"
              element={<EditMovementsOfMeeting />}
            />
            <Route path="facebookGroup" element={<FacebookGroup />} />
            <Route path="shopifyPayment" element={<ShopifyPayment />} />
          </CommonRoutes>
        }
      </div>
    </div>
  );
};

export default DigitalMarketingDashboard;
