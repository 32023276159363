import "../../App.css";
import React, { lazy, Suspense } from "react";
import Sidebar from "../../Module/sidebar";
import Loading from "../../Component/Loading";
import { Route, Routes } from "react-router-dom";
import {
  CloEmpSidebarData,
  CloSidebarData,
} from "../../Module/cloPanelSidebarData";
import { useSelector } from "react-redux";
import CommonRoutes from "../Common/commonRoute";

const TechnicalSupport = lazy(() =>
  import("../VpOperationsPanel/technicalSupport")
);
const BatchWiseCount = lazy(() => import("../CXOPanel/cxoBatchWiseCount"));
const DateWiseCount = lazy(() => import("../Finance/datewiseCount"));
const RevenueDetails = lazy(() => import("../Finance/revenueDetails"));
const DoubtSolve = lazy(() => import("../VpOperationsPanel/doubtSolveReview"));
const EnrolledEdutech = lazy(() => import("../CXOPanel/transactionEdutech"));
const EnrolledStudyMaterial = lazy(() =>
  import("../VpOperationsPanel/transactionEdutechStudyMaterial")
);

const RegistrationDetails = lazy(() =>
  import("../TechnicalSupportPanel/registrationDetails")
);
const OldPackageExist = lazy(() => import("../CXOPanel/oldPackageExists"));
const YoutubeDuplicateUrl = lazy(() =>
  import("../VpOperationsPanel/youtubeDuplicateUrl")
);
const CreateCouponCode = lazy(() => import("../AdminPanel/createCouponCode"));
const CreateCouponCodeMultipleExam = lazy(() =>
  import("../AdminPanel/createCouponCodeMultipleExam")
);
const EditDeleteCouponCode = lazy(() =>
  import("../AdminPanel/editDeleteCouponCode")
);
const CreateCouponCodeExam = lazy(() =>
  import("../AdminPanel/createCouponCodeExam")
);
const CreateCouponCodeNew = lazy(() =>
  import("../AdminPanel/createCouponCodeNew")
);
const EditDeleteCouponCodeEdutech = lazy(() =>
  import("../AdminPanel/editDeleteCouponCodeEdutech")
);
const CreateCounsellorCouponNew = lazy(() =>
  import("../AdminPanel/createCounsellorCouponNew")
);
const EditDeleteCounsellorCouponNew = lazy(() =>
  import("../AdminPanel/editDeleteCounsellorCouponNew")
);
const CopyCounsellorCoupon = lazy(() =>
  import("../AdminPanel/copyCounsellorCoupon")
);
const BaseOfferDiscount = lazy(() =>
  import("../AdminPanel/BaseOffer/baseOfferDiscount")
);
const EditBaseOfferDiscount = React.lazy(() =>
  import("../AdminPanel/BaseOffer//editBaseOfferDiscount")
);
const CreateExtendGlobal = lazy(() =>
  import("../AdminPanel/createExtendGlobal")
);
const CreateUpgradeGlobal = lazy(() =>
  import("../AdminPanel/createUpgradeGlobal")
);
const EditDeleteUpgradeGlobal = lazy(() =>
  import("../AdminPanel/editDeleteUpgradeGlobal")
);
const EditDeleteExtendGlobal = lazy(() =>
  import("../AdminPanel/editDeleteExtendGlobal")
);
const StudentCouponAdd = lazy(() =>
  import("../CXOPanel/studentCouponCode/studentCouponAdd")
);
const StudentCouponEdit = lazy(() =>
  import("../CXOPanel/studentCouponCode/studentCouponEdit")
);
const CreatePackage = lazy(() => import("../CategoryPanel/createPackage"));
const EditDeletePackage = lazy(() =>
  import("../CategoryPanel/editDeletePackage")
);
const AddDispatchCharges = lazy(() =>
  import("../Publication/DispatchCharges/addDispatchCharges")
);
const EditDispatchCharges = lazy(() =>
  import("../Publication/DispatchCharges/editDispatchCharges")
);
const MovementsOfMeeting = React.lazy(() =>
  import("../CategoryPanel/MovementsOfMeeting/movementsOfMeeting")
);
const EditMovementsOfMeeting = React.lazy(() =>
  import("../CategoryPanel/MovementsOfMeeting/editMovementsOfMeeting")
);
const CloDashboard = () => {
  const { loginType } = useSelector((state) => ({
    designation: state.Auth?.userInfo?.designation,
    loginType: state.Auth?.loginType,
  }));
  console.log("loginType", loginType);
  return (
    <div className="AppBox">
      <Sidebar data={loginType === 2 ? CloEmpSidebarData : CloSidebarData} />
      <div className="dashboard">
        {
          <CommonRoutes
            loginType={loginType}
            defaultPage={<TechnicalSupport />}
          >
            <Route path="/technicalSupport" element={<TechnicalSupport />} />
            <Route path="/batchWiseCount" element={<BatchWiseCount />} />
            <Route path="/dateWiseCount" element={<DateWiseCount />} />
            <Route path="/revenueDetails" element={<RevenueDetails />} />
            <Route path="/doubtSolveReview" element={<DoubtSolve />} />
            <Route path="/enrolledEdutech" element={<EnrolledEdutech />} />
            <Route path="/enrolledEdutech" element={<EnrolledEdutech />} />
            <Route
              path="/enrolledStudyMaterial"
              element={<EnrolledStudyMaterial />}
            />
            <Route
              path="/registrationDetails"
              element={<RegistrationDetails />}
            />
            <Route path="/oldPackageExist" element={<OldPackageExist />} />
            <Route
              path="/youtubeDuplicateUrl"
              element={<YoutubeDuplicateUrl />}
            />
            <Route path="/createCouponCode" element={<CreateCouponCode />} />
            <Route
              path="/createCouponCodeMultipleExam"
              element={<CreateCouponCodeMultipleExam />}
            />
            <Route
              path="/editDeleteCouponCode"
              element={<EditDeleteCouponCode />}
            />
            <Route
              path="/createCouponCodeExam"
              element={<CreateCouponCodeExam />}
            />
            <Route
              path="/createCouponCodeNew"
              element={<CreateCouponCodeNew />}
            />
            <Route
              path="/editDeleteCouponCodeEdutech"
              element={<EditDeleteCouponCodeEdutech />}
            />
            <Route
              path="/createCounsellorCouponNew"
              element={<CreateCounsellorCouponNew />}
            />
            <Route
              path="/editDeleteCounsellorCouponNew"
              element={<EditDeleteCounsellorCouponNew />}
            />
            <Route
              path="/copyCounsellorCoupon"
              element={<CopyCounsellorCoupon />}
            />
            <Route path="/baseOfferDiscount" element={<BaseOfferDiscount />} />
            <Route
              path="editBaseOfferDiscount"
              element={<EditBaseOfferDiscount />}
            />
            <Route
              path="/createExtendGlobal"
              element={<CreateExtendGlobal />}
            />
            <Route
              path="/editDeleteExtendGlobal"
              element={<EditDeleteExtendGlobal />}
            />
            <Route
              path="/createUpgradeGlobal"
              element={<CreateUpgradeGlobal />}
            />
            <Route
              path="/editDeleteUpgradeGlobal"
              element={<EditDeleteUpgradeGlobal />}
            />
            <Route path="/studentCouponAdd" element={<StudentCouponAdd />} />
            <Route path="/studentCouponEdit" element={<StudentCouponEdit />} />
            <Route path="/createPackage" element={<CreatePackage />} />
            <Route path="/editDeletePackage" element={<EditDeletePackage />} />
            <Route
              path="/addDispatchCharges"
              element={<AddDispatchCharges />}
            />
            <Route
              path="/editDispatchCharges"
              element={<EditDispatchCharges />}
            />
            <Route path="movementsOfMeeting" element={<MovementsOfMeeting />} />
            <Route
              path="editMovementsOfMeeting"
              element={<EditMovementsOfMeeting />}
            />
          </CommonRoutes>
        }
      </div>
    </div>
  );
};

export default CloDashboard;
