export const EmployeePanelEmpSidebarData = [
    {
      title: "EMPLOYEE DETAILS",
      subNav: [
        {
          title: "Dashboard",
          path: "dashboard",
          cName: "sub-nav",
        },
        {
          title: "My Profile",
          path: "userProfile",
          cName: "sub-nav",
        },
        {
          title: "Policies",
          path: "policy",
          cName: "sub-nav",
        },
        {
          title: "KRA/KPI",
          path: "kraKpi",
          cName: "sub-nav",
        },
        {
          title: "My Assets",
          path: "assets",
          cName: "sub-nav",
        },
      ],
    },
    {
      title: "MOVEMENTS OF MEETING",
      subNav: [
        {
          title: "ADD",
          path: "movementsOfMeeting",
          cName: "sub-nav",
        },
        {
          title: "EDIT/DELETE",
          path: "editMovementsOfMeeting",
          cName: "sub-nav",
        },
      ],
    },
  ];
  