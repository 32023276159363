import React, { useState, useEffect, useRef } from "react";
import "../../../App.css";
import PageTitle from "../../../Module/pageTitle";
import { useDispatch, useSelector } from "react-redux";
import LabelField from "../../../Component/labelField";
import CustomDropdown from "../../../Component/customDropdown";
import {
  getStockManagementList,
  setParentCourse,
  getBookStockHistory,
  getStockHistoryList,
  getExamListNew,
  getParentCourseNew,
  getPubStockHistoryList,
} from "../../../Action/courseAction";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { BookStatusList } from "../../../Utils/dataConstants";
import AdminTable from "../../../Component/adminTable";
import OrderHistoryPopup from "../../../Component/orderHistoryPopup";
import DateTime from "../../../Component/dateTime";
import moment from "moment";
import SubmitButton from "../../../Component/SubmitButton";
import { getSubExam } from "../../../Action/adminPanelAction";
import DatePicker from "../../../Component/datePicker";

function PubBookHistory() {
  const dispatch = useDispatch();
  const tableRef = useRef(null);
  const [parentCourseVal, setparentCourseVal] = useState("");
  const [examName, setExamName] = useState("");
  const [subExamName, setSubExamName] = useState("");
  const [bookStatus, setBookStatus] = useState("");

  const [bookId, setBookId] = useState("");
  const [bookName, setBookName] = useState("");
  const [bookQuantity, setBookQuantity] = useState("");

  const [parentCourseList, setParentCourseList] = useState([]);
  const [examList, setExamList] = useState([]);
  const [subExamList, setsubExamList] = useState([]);
  const [stockList, setStockList] = useState([]);
  const [stockHistory, setStockHistory] = useState([]);

  const [resetParentCourse, setResetParentCourse] = useState(false);
  const [resetExam, setResetExam] = useState(false);
  const [resetSubExam, setResetSubExam] = useState(false);
  const [resetBookStatus, setResetBookStatus] = useState(false);
  const [Open, setOpen] = useState(false);
  const [OrderDetails, setOrderDetails] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [isDelete, setisDelete] = useState(false);
  const [isViewMode, setIsViewMode] = useState(false);

  useEffect(() => {
    dispatch(
      getExamListNew((res) => {
        setExamList(res);
      })
    );

    dispatch(
      getStockManagementList(
        {},
        (res) => {
          setStockList(res?.data);
        },
        () => {}
      )
    );
  }, []);

  const changeParentCourseVal = (value) => {
    setparentCourseVal(value);
    setResetParentCourse(false);
    setResetBookStatus(true);
    setStockList([]);
  };

  const onListChange = (value) => {
    setExamName(value);
    setResetExam(false);
    setsubExamList([]);
    setResetSubExam(true);
    setResetParentCourse(true);
    setParentCourseList([]);
    setResetBookStatus(true);
    setStockList([]);
    dispatch(
      getSubExam(
        {
          examTypeId: value._id,
        },
        (res) => {
          if (res.data.length > 0) setsubExamList(res.data);
          else
            dispatch(
              getParentCourseNew(`${value._id}`, (res) => {
                setParentCourseList(res);
              })
            );
        }
      )
    );
  };
  const onChangeSubExam = (value) => {
    setSubExamName(value);
    setResetSubExam(false);
    setParentCourseList([]);
    setResetParentCourse(true);
    setResetBookStatus(true);
    setStockList([]);
    dispatch(
      getParentCourseNew(`${examName._id}/${value._id}`, (res) => {
        setParentCourseList(res);
      })
    );
  };

  const onChangeBookStatus = (value) => {
    setBookStatus(value);
    setResetBookStatus(true);
    setStockList([]);
  };

  const handlesearch = (e) => {
    e.preventDefault();
    const postData = {
      baseCourseId: parentCourseVal._id,
      examTypeId: examName._id,
      booksStatus: bookStatus._id,
    };
    if (subExamName) {
      postData["subExamTypeId"] = subExamName._id;
    }

    dispatch(
      getStockManagementList(
        postData,
        (res) => {
          setStockList(res?.data);
        },
        () => {}
      )
    );
  };

  const handleView = (value) => {
    setBookId(value?._id);
    setBookName(value?.name);
    setBookQuantity(value?.quantity);
    dispatch(
      getBookStockHistory(value._id, (res) => {
        setStockHistory(res);
      })
    );
    setStartDate(moment.utc(new Date()).format());
    setEndDate(moment.utc(new Date()).format());
    setIsViewMode(true);
  };

  const handleCancelEditMode = () => {
    setIsViewMode(false);
  };

  const renderListMode = () => {
    return (
      <div className="formDiv ">
        <form className="row" onSubmit={handlesearch}>
          <div className="col-4 d-flex mb-2 align-items-center">
            <LabelField labelName={"Exam Name"} />
            <CustomDropdown
              labelName={"select Exam Name"}
              setInputText={(value) => onListChange(value)}
              value={examName}
              options={examList}
              reset={resetExam}
            />
          </div>
          {subExamList?.length > 0 ? (
            <div className="col-4 d-flex mb-2 align-items-center">
              <LabelField labelName={"Sub Exam Name"} />
              <CustomDropdown
                labelName="Select Sub Exam Name"
                setInputText={(value) => onChangeSubExam(value)}
                value={subExamName}
                options={subExamList}
                reset={resetSubExam}
              />
            </div>
          ) : null}
          <div className="col-4 d-flex mb-2 align-items-center">
            <LabelField labelName={"Exam Stream"} />
            <CustomDropdown
              labelName={"Select Exam Stream"}
              setInputText={(value) => changeParentCourseVal(value)}
              value={parentCourseVal}
              options={parentCourseList}
              reset={resetParentCourse}
            />
          </div>
          <div className="col-4 d-flex mb-2 align-items-center">
            <LabelField labelName={"Book Status"} />
            <CustomDropdown
              setInputText={(value) => onChangeBookStatus(value)}
              labelName={"select Book Status"}
              value={bookStatus}
              options={BookStatusList}
              reset={resetBookStatus}
            />
          </div>
          <div className="d-flex justify-content-center me-3">
            <button type="submit" className="btn btn-primary">
              Search
            </button>
          </div>
        </form>
        <div className=" d-flex flex-row-reverse mt-3">
          <DownloadTableExcel
            filename="Publication Transaction"
            sheet="users"
            currentTableRef={tableRef.current}
          >
            <button
              type="button"
              className={`btn rounded-pill me-1 font-12 ${
                stockList.length > 0 ? "btn-primary" : "btn-secondary"
              }`}
            >
              Export Report
            </button>
          </DownloadTableExcel>
        </div>
        <div ref={tableRef}>
          <AdminTable
            pagename={"stockManagement"}
            tableHead={[
              "S.N",
              "PARENT COURSE",
              "EXAM NAME",
              "SUB-EXAM NAME",
              "BOOK NAME",
              "QUANTITY",
              "STATUS",
              "ACTION",
              "",
            ]}
            tableData={stockList ? stockList : []}
            className={"add_Question-btn btn btn-outline-success btn-sm"}
            handleAddOnButton={(input) => handleView(input)}
            addOnButton={true}
            addOnButtonLabel={"View"}
          />
        </div>
      </div>
    );
  };

  const filterdata = (e) => {
    e.preventDefault();
    const postdata = {
      bookId: bookId,
      startDate: startDate,
      endDate: endDate,
    };
    dispatch(
      getPubStockHistoryList(postdata, (res) => {
        setStockHistory(res.data);
      })
    );
  };
  const renderEditMode = () => {
    return (
      <>
        <div className="formDiv">
          <div className="d-flex justify-content-between pt-3 px-3">
            <p className="fw-bold">
              Book Name :<span style={{ color: "#3F51B5" }}> {bookName}</span>
            </p>
            <p className="fw-bold">
              Available Stock :
              <span style={{ color: "#3F51B5" }}> {bookQuantity}</span>
            </p>
          </div>
          <div className="row d-flex px-2 mx-0">
            <form className="col-12 formWidth" onSubmit={filterdata}>
              <div className="col-auto">
                <div className="d-flex align-items-center justify-content-between flex-wrap">
                  <div className="d-flex align-items-center me-3">
                    <LabelField labelName={"Start Date"} />
                    <DatePicker
                      setInputText={(value) => {
                        setStartDate(value);
                      }}
                      value={moment.utc(startDate).format("yyyy-MM-DD")}
                      defaultValue={moment.utc(startDate).format("yyyy-MM-DD")}
                    />
                  </div>
                  <div className="d-flex align-items-center">
                    <LabelField labelName={"End Date"} />
                    <DatePicker
                      setInputText={(value) => {
                        setEndDate(value);
                      }}
                      value={moment.utc(endDate).format("yyyy-MM-DD")}
                      defaultValue={moment.utc(endDate).format("yyyy-MM-DD")}
                    />
                  </div>
                  <div className="col-auto d-flex justify-content-center">
                    <SubmitButton name="Search" />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <AdminTable
            pagename={"PubBookHistory"}
            tableHead={[
              "S.N",
              "ADDED BY",
              "DATE & TIME",
              "LAST BOOK COUNT",
              "CURRENT STOCK",
              "STOCK IN",
              "STOCK OUT",
              "STATUS",
              "SELLING SOURCE",
            ]}
            tableData={stockHistory ? stockHistory : []}
            className={"text-primary fw-bold me-2 cursor"}
          />
        </div>
      </>
    );
  };

  const handleClose = () => {
    setOpen(false);
    setisDelete(false);
  };

  return (
    <div className="contentBox">
      <PageTitle
        name={"BOOK HISTORY"}
        isGoBack={isViewMode}
        onClick={handleCancelEditMode}
      />
      <div className="col-12 formWidth">
        {!isViewMode ? renderListMode() : renderEditMode()}
      </div>
      <OrderHistoryPopup
        open={Open}
        handleClose={handleClose}
        data={OrderDetails}
        bookName={bookName}
        isDelete={isDelete}
      />
    </div>
  );
}

export default PubBookHistory;
