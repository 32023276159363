export const BdeSideBarData = [
  {
    title: "WEBINAR",
    link: "webinar",
  },
];

export const BdeSideBarEmpData = [
  {
    title: "EMPLOYEE DETAILS",
    subNav: [
      {
        title: "Dashboard",
        path: "dashboard",
        cName: "sub-nav",
      },
      {
        title: "My Profile",
        path: "userProfile",
        cName: "sub-nav",
      },
      {
        title: "Policies",
        path: "policy",
        cName: "sub-nav",
      },
      {
        title: "KRA/KPI",
        path: "kraKpi",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "WEBINAR",
    link: "webinar",
  },
];
